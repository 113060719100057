import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import MediaStack from './MediaStack';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { Checkbox } from 'primereact/checkbox';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog, setAssign, setRerenderList } from '../../../redux/Slices/Global/globalSlice';

function LayoutList() {
    const dispatch = useDispatch();
    const { layer, assign } = useSelector((state) => state.global);
    const screenDetails = useSelector((state) => state.layout.screenDetails);

    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [ids, setIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const { id } = useParams();
    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    const callMedia = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/layout/company`, {
                params: {
                    page: pageNum,
                    per_page: 10,
                },
            });
            const all = new Set([...data, ...response.data.data]);
            setData([...all]);
            setLastPage(response.data.last_page);
        } catch (error) {
            console.error("Error fetching Layouts:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setData([]);
        setPageNum(1);
    }, [searchTerm]);


    useEffect(() => {
        setData([]);
        setPageNum(1);
    }, [searchTerm]);


    useEffect(() => {
        setData([]);
        setPageNum(1);
    }, [searchTerm]);


    useEffect(() => {
        if (pageNum <= lastPage || lastPage == null) {
            callMedia();
        }
    }, [pageNum]);

    useEffect(() => {
        if (isUploaded) {
            setPageNum(1, () => callMedia());
            setIsUploaded(false);
        }
    }, [isUploaded]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    const onLayoutChange = (e) => {
        let selectedLayouts = [...ids];

        if (e.checked)
            selectedLayouts.push(e.value);
        else
            selectedLayouts.splice(selectedLayouts.indexOf(e.value), 1);

        setIds(selectedLayouts);
    }

    // useEffect(() => {
    //     if (ids.length == 0) {
    //         dispatch(setDisableBtn(true))
    //     } else {
    //         dispatch(setDisableBtn(false))
    //     }
    // }, [ids])

    useEffect(() => {
        if (assign) {
            dispatch(setDisableBtn(true));

            axiosInstance.post(`/campaign/content/assign`,
                {
                    "ids_list": ids,
                    "campaign_id": id,
                    "layer": layer,
                    "type": "layout",
                }
            ).then((result) => {
                setDisableBtn(false)
                dispatch(setRerenderList(true));
                dispatch(toggleDialog("addContent"))
                var errorsArr = [];
                errorsArr.map((error) => { showToast(error.severity, error.summary, error.detail) })

            }).catch((error) => {
                showToast('error', 'Assign Layout', error?.response?.data?.message);
            });
            dispatch(setDisableBtn(false));
            dispatch(setAssign(false))
        }
    }, [assign])

    return (
        <>

            {
                data.length == 0 && loading ?
                    <ProgressSpinner
                        style={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    data.length > 0 ?
                        <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                            <div className="d-flex my-3">
                                <div className="col-1"></div>
                                <div className="col-3 mx-2 text-light fs-6  ps-5">Name</div>
                            </div>
                            <div className="popular-movies-api d-flex flex-wrap ">
                                {
                                    data.map((item, index) => {
                                        return item.layout_duration > 0 ?
                                            index === data.length - 1 && pageNum <= lastPage ?
                                                (
                                                    <div className="col-12 d-flex align-items-center justify-content-between " ref={setLastElement} key={`${item.type}-${index}`}>
                                                        <label htmlFor={item.id} className="col-10 col-sm-11 d-flex ">
                                                            <div className="col-3 col-sm-2 mx-2 ">
                                                                <div className=" me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center"><i className="fas fa-table-layout fs-4  text-white"></i></div>
                                                            </div>
                                                            <div className="col-2  mx-1 fs-6 d-flex align-items-center text-truncate">
                                                                {item.title}
                                                            </div>
                                                        </label>
                                                        <div className="col-2 col-sm-1 pe-2">
                                                            <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onLayoutChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-12 d-flex align-items-center justify-content-between my-1 border-bottom pb-2" key={`${item.type}-${index}`} >
                                                        <label htmlFor={item.id} className="col-10 col-sm-11 d-flex ">
                                                            <div className="col-3 col-sm-2 mx-2 ">
                                                                <div className=" me-2 p-4 rounded-2 bg-secondary d-flex align-items-center justify-content-center playlist_icon_container">
                                                                    <i className="fas fa-window-maximize  fs-4 text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-3 mx-1 fs-6 d-flex align-items-center text-truncate">{item.title}</div>
                                                        </label>
                                                        <div className="col-2 col-sm-1 pe-2">
                                                            <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onLayoutChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                        </div>
                                                    </div>
                                                )
                                            :
                                            <></>
                                    })
                                }
                            </div>

                            {loading &&
                                <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />}
                        </div>
                        :
                        <h5 className="py-5 text-center w-100">There aren't any Layouts has been created yet!</h5>
            }
        </>
    );
}

export default LayoutList