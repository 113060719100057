import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../config/Axios';

const Map = ({ deviceId }) => {
    const [location, setLocation] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/device/${deviceId}/info`)
            .then((res) => {
                const deviceData = res.data.data;
                if (deviceData?.location) {
                    setLocation(deviceData.location);
                } else {
                    console.warn("No location data found in the response.");
                }
            })
            .catch((error) => {
                console.error("Error fetching device location:", error);
            });
    }, [deviceId]);

    const getMapSrc = () => {
        if (!location) {
            return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.803578676913!2d35.83077806287755!3d31.9988353336859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151b5fb85d7981af%3A0x631c30c0f8dc65e8!2z2LnZhdmR2KfZhg!5e0!3m2!1sar!2sjo!4v1734949367086!5m2!1sar!2sjo" ; 
        }
        const { city, country_name } = location;
        const query = encodeURIComponent(`${city}, ${country_name}`);
        return `https://maps.google.com/maps?q=${query}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
    };
    
    return (
        <div id="map-container-google-1" className="z-depth-1-half map-container" style={{ height: 300 }}>
            <iframe
                src={getMapSrc()}
                frameBorder={0}
                className="w-100 h-100"
                allowFullScreen
                title="Device Location"
            />
        </div>
    );
};



export default Map;
