import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { SplitButton, Dropdown } from 'react-bootstrap';
import { Tooltip } from "primereact/tooltip";
import { Card } from "react-bootstrap";

import { useCustomToast } from "../../../Contexts/ToastContext";
import axiosInstance from "../../../../config/Axios";

import AssignCampaignsDropDown from "../CampaignAssignment/AssignCampaignsDropDown";
import ShowScheduleCampaign from "../CampaignAssignment/ShowScheduleCampaign";
import DeleteConfirmation from "../../../SharedComponents/DeleteConfirmation";
import GroupHeader from "./GroupHeader";
import DeviceCard from "./DeviceCard";
import Update from "../Update";

import { useSelector, useDispatch } from 'react-redux';
import { setRerender, setLoading, setCompanyCampaigns } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog, toggleDeleteDialog, setDisableBtn, setRerenderSubscription } from '../../../../redux/Slices/Global/globalSlice';


function Group({ devicesGroup, setSelectedGroup }) {

    const dispatch = useDispatch();
    const { rerender, device, companyCampaigns } = useSelector((state) => state.device);
    const { openDialog, deleteDialog } = useSelector((state) => state.global);

    const { showToast } = useCustomToast();

    const didMountRef = useRef(false);

    // Assign to device dropdown option "campaigns"
    const [options, setoptions] = useState([]);
    const [columns, setColumns] = useState(devicesGroup);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, [columns])

    useEffect(() => {
        setColumns(devicesGroup);
    }, [devicesGroup]);


    //Fetch company campaigns  
    useEffect(() => {
        axiosInstance.get(`/company/campaigns`)
            .then((result) => {
                const campaigns = {};
                const options = result.data.data.map((campaign) => {
                    campaigns[campaign.id] = campaign.campaign_duration;
                    return {
                        name: campaign.name,
                        code: campaign.id,
                        duration: campaign.campaign_duration,
                    };
                });

                setoptions(options);
                dispatch(setCompanyCampaigns(campaigns));
            })
            .catch((error) => {
                console.error('Error fetching campaigns:', error);
                dispatch(setLoading(false));
            });
    }, []);

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: { ...sourceColumn, items: sourceItems, },
                [destination.droppableId]: { ...destColumn, items: destItems, },
            });

            updateDeviceGroup(result.destination.droppableId, result.draggableId)

        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns, [source.droppableId]: { ...column, items: copiedItems, },
            });
        }
    };

    const updateDeviceGroup = (groupID, deviceID) => {
        axiosInstance.put(
            `/device/update/group`,
            {
                "group_id": groupID == "undefined" ? "" : groupID,
                "device_id": deviceID
            }
        ).then((result) => {
            showToast('success', 'Group Updating', result?.data?.data?.msg);
        }).catch((errors) => {
            if (errors?.response?.data?.errors)
                Object.values(errors?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Group Updating', error[0]);
                });
        });
    }

    const playPause = (groupID, action) => {

        axiosInstance.post(
            `/groups/play/pause`,
            {
                "group_id": groupID,
                "action": action,
            }
        ).then((result) => {
            showToast('success', 'Play-Pause Action', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('error', 'Play-Pause Action', error.response?.data?.message);
        });
    }

    const assignSchedule = (groupID) => {
        setSelectedGroup(groupID);
        dispatch(toggleDialog("assignDialog"))
    }

    const deleteDevice = () => {
        axiosInstance.delete(`/device/${device.id}`)
            .then(response => {
                dispatch(setLoading(true));
                dispatch(toggleDeleteDialog("device"));
                dispatch(setRerenderSubscription(true))
                dispatch(setRerender(true));
                showToast('success', 'Delete Device', response?.data?.data?.msg);
            });
        dispatch(setDisableBtn(false))
    }

    const flushHandler = (groupID) => {
        axiosInstance.get(`/groups/${groupID}/screen`)
            .then((res) => {
                showToast('success', 'Group', res?.data?.data?.msg);
            }).catch(error => {
                showToast('error', 'Group', error?.response?.data?.message);
            });
    }

    const restartHandler = (groupID) => {
        axiosInstance.get(`/groups/${groupID}/restart`)
            .then((res) => {
                showToast('success', 'Group', res?.data?.data?.msg);

            })
    }


    const muteHandler = (groupID, action) => {
        axiosInstance.post(`/groups/${groupID}/mute`, { "action": action, })
            .then((res) => {
                showToast('success', 'Group', res?.data?.data?.msg);

            })
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center w-100"  >
                <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)} className="w-100" >
                    {
                        Object.entries(columns).map(([columnId, column], index) => {
                            return (
                                <div className="d-flex flex-column align-items-start " key={columnId}>
                                    {/* Group name */}
                                    {
                                        column.name == "default" ?
                                            <div className="d-flex w_20  px-3 pt-2 bg-white group_title justify-content-between align-items-center">
                                                <h2 className="mb-0 fs-5 fw-bold ">{column.name}</h2>
                                                <div>
                                                    {/* Play  */}
                                                    <Tooltip target={`.play-${columnId}`} showDelay={100} className="fs-8" />
                                                    <button className={`btn btn-sm btn-icon play-${columnId}`} data-pr-position="bottom" data-pr-tooltip={`play `}
                                                        disabled={column.items.length == 0 ? true : false}
                                                        onClick={() => playPause(column.group_id, "play")}>
                                                        <i className='bx bx-play-circle text-light' ></i>
                                                    </button>
                                                    <box-icon name='image-add' ></box-icon>

                                                    {/* Pause  */}
                                                    <Tooltip target={`.pause-${columnId}`} showDelay={100} className="fs-8" />
                                                    <button className={`btn btn-sm btn-icon pause-${columnId}`} data-pr-position="bottom" data-pr-tooltip={`pause `}
                                                        disabled={column.items.length == 0 ? true : false}
                                                        onClick={() => playPause(column.group_id, "pause")}>
                                                        <i className='bx bx-pause-circle text-light' ></i>
                                                    </button>

                                                    <Dropdown style={{ display: 'inline-block' }}>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: 'none', border: 'none', padding: 0, boxShadow: 'none', }} >
                                                            <span className="ml-4 text-light" style={{ fontSize: '24px' }}>⋮</span>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ border: '1px solid #ddd', padding: '4px 0' }}>
                                                            {/*assign schedule campaign  */}
                                                            <Dropdown.Item onClick={() => assignSchedule(column.group_id)}>
                                                                <i className='bx bx-time-five text-light' ></i>Assign schedule to Group
                                                            </Dropdown.Item>

                                                            {/*flush group*/}
                                                            <Dropdown.Item onClick={() => flushHandler(column.group_id)} >
                                                                <i className="bx bx-help-circle me-2 text-light"></i>Flush
                                                            </Dropdown.Item>

                                                            {/*Restart group  */}
                                                            <Dropdown.Item onClick={() => restartHandler(column.group_id)}>
                                                                <i className="fas fa-power-off me-2 text-light"></i>Restart
                                                            </Dropdown.Item>

                                                            {/*mute group  */}
                                                            <Dropdown.Item onClick={() => muteHandler(column.group_id, "mute")}>
                                                                <i className="fas fa-volume-off me-2 text-light"></i>Mute
                                                            </Dropdown.Item>

                                                            {/*unmute group  */}
                                                            <Dropdown.Item onClick={() => muteHandler(column.group_id, "unmute")}>
                                                                <i className="fas fa-volume-high me-2 text-light"></i>UnMute
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>




                                                </div>
                                            </div>
                                            :
                                            <GroupHeader
                                                deviceCount={column.items.length}
                                                assignSchedule={assignSchedule}
                                                groupName={column.name}
                                                columnId={columnId}
                                                groupID={column.group_id}
                                                playPause={playPause} />
                                    }

                                    <Droppable droppableId={columnId} key={columnId} group_id={column.group_id} >
                                        {
                                            (provided, snapshot) => {
                                                return (
                                                    <Card {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="p-3 bg-white w-100 mb-3 group_card"
                                                    >
                                                        {
                                                            column.items.length != 0 ?
                                                                column.items.map((device, index) => {
                                                                    return (
                                                                        <Draggable key={`${device.id}`} draggableId={`${device.id}`} index={index} >
                                                                            {(provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={{
                                                                                            userSelect: "none",
                                                                                            ...provided.draggableProps.style,
                                                                                        }}
                                                                                        className=" px-0 px-sm-2  ">

                                                                                        <DeviceCard
                                                                                            options={options}
                                                                                            data={device}


                                                                                        />

                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })

                                                                :
                                                                <div>No Devices add to this group</div>
                                                        }
                                                        {provided.placeholder}
                                                    </Card>
                                                );
                                            }}
                                    </Droppable>
                                </div>
                            );
                        })}
                </DragDropContext>
            </div>

            {
                deleteDialog.device &&
                <DeleteConfirmation
                    deleteHandler={deleteDevice}
                    itemName={device.name ?? device.serial_name}
                    dialogKey="device"
                />
            }

            {
                openDialog.updateDevice
                && <Update
                    device={device} />
            }

            {
                openDialog.assignCampaignsDropDown &&
                <AssignCampaignsDropDown
                    deviceID={device.id}
                    options={options} />
            }

            {
                openDialog.assignScheduleCampaigns &&
                <ShowScheduleCampaign
                    device={device}
                    deviceID={device.id}
                    
                    options={options}
                />
            }
        </>
    )
}

export default Group