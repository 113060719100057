import React from 'react'
import { Spinner } from 'react-bootstrap'

import { useProfileContext } from '../../../Contexts/ProfileContext';

import { useSelector } from 'react-redux';

function MediaUsageCard() {
  const { subLoaders, subscriptionInfo } = useSelector((state) => state.global);
  const { mediaUsageInfo } = useProfileContext();

  return (
    <>
      <div className="card p-3 mt-4 h-50  position-relative">
        {
          subscriptionInfo.isFinished &&
          <div className='position-absolute w-100 h-100 top-0 left-0 bg-white-opacity'></div>
        }
        {
          subLoaders.companyDetails ?
            <div className="d-flex justify-content-center align-items-center">
              <Spinner variant="primary" as="span" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
            </div>
            :
            <>
              <div className='d-flex align-items-center'>
                <span className='fs-4 fw-bold'>{(mediaUsageInfo.mediaUsage / (1024 * 1024 * 1024)).toFixed(3)} GB</span>
                <sub className='fs-8 text-light'> &nbsp; / {mediaUsageInfo.allowedTotal}GB</sub>
              </div>
              <div className='fs-6 fw-bold mb-3' >Media Storage</div>
              <div className='my-3'>
                <div className="progress">
                  <div className="progress-bar" role="progressbar" style={{ width: `${((mediaUsageInfo.mediaUsage / (1024 * 1024 * 1024)) / mediaUsageInfo.allowedTotal) * 100}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>

              <button disabled="disabled " className='fs-12 btn btn-secondary my-4 px-3 mx-auto'>ADD MORE STORAGE</button>
            </>
        }
      </div>
    </>
  )
}

export default MediaUsageCard