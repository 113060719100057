import React, { useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import axiosInstance from '../../../../../config/Axios';

import AreaControls from "../Custom/AreaControls";
import DynamicContainer from "../Custom/DynamicContainer";
import DynamicContainerPreview from "./Warning_preview/DynamicContainerPreview";

import { useCustomToast } from '../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, setDisableBtn } from '../../../../../redux/Slices/Global/globalSlice';
import { setReloadTrigger } from '../../../../../redux/Slices/layoutSlice';
import { ProgressSpinner } from 'primereact/progressspinner';

const colors = [
    "#339ecccc",
    "#2f99c6cc",
    "#2a93c0cc",
    "#268ebacc",
    "#2288b4cc",
    "#1f83adcc",
    "#1b7da7cc",
    "#1877a0cc",
    "#157199cc",
    "#126b91cc",
    "#0f658acc",
    "#0d5f82cc",
    "#0b597acc",
    "#095272cc",
    "#074c69cc",
    "#054561cc",
    "#043f58cc",
    "#02384fcc",
    "#013146cc",
    "#012b3dcc",
];

const defaultValues = {
    layoutName: "Custom Layout",
    areas: [
        { id: 1, start_margin: 0, top_margin: 0, width: 100, height: 100, name: "Screen 1" },
    ],
};

function EditLayout() {
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();
    const { id } = useParams();
    const navigate = useNavigate();



    const selectedLayout = useSelector((state) => state.layout.selectedLayout);
    const screens_setting = useSelector((state) => state.layout.screens_setting);

    const { control, handleSubmit } = useForm({ defaultValues: { layoutName: selectedLayout.title, }, });
    const [layoutName, setLayoutName] = useState({ isEditing: false, name: selectedLayout.title });

    const [oldLayout, setOldLayout] = useState(selectedLayout.screens_setting);
    const [overlappingAreas, setOverlappingAreas] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isConflectScreens, setIsConflectScreens] = useState(false);
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});
    const [newLayout, setNewLayout] = useState([]);

    const [areas, setAreas] = useState([]);
    const [oldAspectRatio, setOldAspectRatio] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`/aspect-ratio/${selectedLayout.aspect_ratio_id}/show`)
            .then((res) => {
                const data = res.data.data;
                setOldAspectRatio(data)
                setCurrentAspectRatio(data)
                setLoading(false)

            })
    }, []);

    const newAreas = screens_setting.map((area, index) => ({
        ...area,
        id: area.id || index + 1,
    }));

    useEffect(() => {
        setAreas(newAreas)
        setLayoutName({ isEditing: false, name: `${selectedLayout.title}` });
    }, [])

    const handleAddArea = () => {
        const newArea = {
            id: areas.length + 1,
            name: `Screen ${areas.length + 1}`,
            start_margin: 0,
            top_margin: 0,
            width: 100,
            height: 100,
        };
        setAreas([...areas, newArea]);
    };


    const onTitleSubmit = (data) => {
        setLayoutName({ isEditing: false, name: data.layoutName });
    };

    const handleEditTemplate = () => {
        const sanitizedAreas = areas.map(({ isEditing, ...rest }) => rest);
        if (areas.length < oldLayout.length) {
            setOldLayout(selectedLayout.screens_setting)
            setNewLayout(sanitizedAreas)
            setIsConflectScreens(true)
        } else {
            setIsConflectScreens(false)
            setNewLayout(sanitizedAreas)
            handleChange();
        }
    };

    const handleChange = () => {
        const toSethScreenSetting = areas.map(({ id, name, width, height, start_margin, top_margin }, index) => ({
            id: index,
            name,
            width,
            height,
            start_margin,
            top_margin
        }));

        const sanitizedAreas = areas
            .filter(({ id }) => typeof id === 'string')
            .map(({ id, name, width, height, start_margin, top_margin }) => ({
                id,
                name,
                width,
                height,
                start_margin,
                top_margin
            }));

        axiosInstance.post(`/layout/${id}/update`, {
            title: layoutName.name,
            screens_setting: toSethScreenSetting,
            type: "CUSTOM",
            status: "ACTIVE",
            aspect_ratio_id: currentAspectRatio.id,
            updated_campaigns: sanitizedAreas
        })
            .then(result => {
                dispatch(toggleDialog("EditLayoutPopUp"));
                showToast('success', "Layout Creation", "Layout Updated successfully!");
                dispatch(setReloadTrigger(true));
                dispatch(setDisableBtn(false));
            })
            .catch(error => {
                console.error("API call failed:", error);
                showToast('error', "Layout Creation", "Failed to update layout!");
            });
    };


    return (
        <>
            <div className="flex flex-col items-center  mt-1 w-100">
                {isConflectScreens ? (
                    <>
                        <div className="w-[70%] flex flex-col mt-5 text-white font-medium mb-3 alert bg-orange-300 p-5" role="alert">
                            <div className="flex justify-center justify-items-center text-center ">
                                {/* Preview Area */}
                                <DynamicContainerPreview
                                    areas={oldLayout}
                                    colors={colors}
                                    currentAspectRatio={oldAspectRatio}
                                />
                                <div className="mt-3 relative w-auto pt-[12%] pl-3 pr-3 h-36 rounded-md justify-items-center justify-self-center">

                                    <i class="fa-solid fa-angles-right"></i>
                                </div>
                                <DynamicContainerPreview
                                    areas={newLayout}
                                    colors={colors}
                                    currentAspectRatio={currentAspectRatio}
                                />

                            </div>

                            <div className="flex mt-3 justify-center">
                                <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                                <div className="ml-3">
                                    The selected layout has fewer zones than your current setup.
                                    <br />
                                    Content in some zones may be lost. Are you sure you want to proceed?
                                </div>
                            </div>
                            <div className="flex mt-3 justify-center  ">
                                <Button label="Confirm" className={"pt-1 pb-1 me-2  "} disabled={isSaveDisabled} onClick={() => {
                                    handleChange()
                                }} />
                                <Button label="Cancel" className={"pt-1 pb-1 me-2   bg-gray-500 border-orange-300 text-white rounded hover:bg-gray-600"} onClick={() => {
                                    setAreas(newAreas)
                                    setIsConflectScreens(false)
                                }} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {loading ?
                            <div className='w-100 h-100 d-flex align-items-center' >
                                <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                            </div>
                            :
                            <>
                                <div className="overflow-hidden w-100 mb-3 pl-5 pr-5 flex flex-col lg:flex-row gap-10">

                                    {/* Preview Area */}
                                    <DynamicContainer
                                        areas={areas}
                                        colors={colors}
                                        currentAspectRatio={currentAspectRatio}
                                        setCurrentAspectRatio={setCurrentAspectRatio}
                                    />

                                    {/* Controls */}

                                    <div className="  flex flex-col w-full  ">
                                        <div className="mt-5 flex flex-col w-100 lg:w-1/2">
                                            <label className="text-sm font-medium mb-1">Title</label>
                                            <div className="d-flex mb-3 ">
                                                {layoutName.isEditing ? (
                                                    <>
                                                        <form className="flex mt-1 w-100" onSubmit={handleSubmit(onTitleSubmit)}  >
                                                            <div className="flex flex-col w-full">
                                                                <Controller name="layoutName" control={control} rules={{
                                                                    required: "Title is required",
                                                                    minLength: { value: 3, message: "Title must be at least 3 characters" },
                                                                }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <InputText
                                                                                {...field}
                                                                                placeholder="My custom layout"
                                                                                className={`p-2 border rounded-lg  ${fieldState.error ? "border-red-500" : "border-gray-300"
                                                                                    }`}
                                                                            />
                                                                            {fieldState.error && (
                                                                                <span className="text-red-500 text-sm">{fieldState.error.message}</span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                            <Button label="" icon="fa-solid fa-check" className="p-0 me-2 bg-transparent text-black border-transparent " type="submit" />
                                                        </form>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="">{layoutName.name}</span>
                                                        <Button
                                                            label=""
                                                            icon="fa-solid fa-pen"
                                                            className="p-0 me-2 bg-white text-black border-white"
                                                            onClick={() =>
                                                                setLayoutName((prev) => ({ ...prev, isEditing: true }))
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-100 lg:w-1/2">
                                            <AreaControls
                                                areas={areas}
                                                setAreas={setAreas}
                                                isSaveDisabled={isSaveDisabled}
                                                overlappingAreas={overlappingAreas}
                                                setOverlappingAreas={setOverlappingAreas}
                                                setIsSaveDisabled={setIsSaveDisabled}
                                            />
                                            {overlappingAreas.length > 0 && (
                                                <div className=" flex mt-3 text-white font-medium mb-3 alert bg-orange-300" role="alert">
                                                    <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                                                    <div className="ml-3">
                                                        These zones are being overlapped by some other zone:{" "}
                                                        {overlappingAreas.join(", ")}.
                                                        <br />
                                                        Please adjust the zones to avoid overlapping.
                                                    </div>
                                                </div>
                                            )}
                                            <Button label="Add Area" icon="fas fa-plus" className={"mt-3 pt-1 pb-1 me-2 text-[15px] bg-blue text-white"} onClick={() => handleAddArea()} />
                                            {/* Action Buttons */}
                                            <div className="mt-3 flex justify-end gap-3">
                                                <Button label="Save" className={"pt-1 pb-1 me-2"} disabled={isSaveDisabled} onClick={() => handleEditTemplate()} />
                                                <Button label="Cancel" className={"pt-1 pb-1 me-2 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => setAreas(newAreas)} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }


                    </>
                )}

            </div>
        </>
    )
}

export default EditLayout