import React from 'react'

import { Dialog } from 'primereact/dialog';

import { useProfileContext } from '../../../Contexts/ProfileContext';

import FeaturesList from './FeaturesList';
import PaymentForm from './PaymentForm';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function PaymentDialog() {
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.global.openDialog);
  const { paymentData, selectedPackage, selectedPricing, numberOfScreens, action, setSelectedPricing } = useProfileContext();

  const closeDialog = () => {
    dispatch(toggleDialog("paymentDialog"));
  }

  const onPeriodChange = (period) => {
    let _pricing = selectedPackage?.prices.find(pricing => pricing.period == period)
    setSelectedPricing(_pricing)
  }

  return (
    <Dialog visible={openDialog.paymentDialog}
      breakpoints={{ '960px': '95vw' }}
      style={{ width: action == "addScreens" ? "30%" : "55%" }}
      modal
      className="p-fluid custom-dialog position-relative"
      onHide={() => closeDialog()}
    >
      {/* Close button  */}
      <button className='position-absolute close_icon text-primary' onClick={() => closeDialog()} >
        <i className="fa-regular fa-circle-xmark text-primary"></i>
      </button>

      <div className="d-flex ">
        <div className={`${action == "addScreens" ? "col-12" : "col-6"}  d-flex flex-column p-5`}  >
          <span className='fs-5 fw-bold mb-2'>Payment Details</span>

          <div className='d-flex flex-wrap mb-3'>
            <div className="col-6">
              <div className="fw-light fs-6"><span className='fw-bold fs-6'>Plan: </span>  {selectedPackage?.title}</div>
              <div className="fw-light fs-6"><span className='fw-bold fs-6'>Period: </span>  {(paymentData?.period?.replace(/_/g, ' '))?.toLowerCase()}</div>
              <div className="fw-light fs-6"><span className='fw-bold fs-6'>Number of Screens: </span> {numberOfScreens}</div>
            </div>
            <div className="col-6">
              <div className="fw-light fs-6"><span className='fw-bold fs-6'>Subtotal: </span>  $ {paymentData?.subtotal} </div>
              <div className="fw-light fs-6"><span className='fw-bold fs-6'>Tax: </span>$ {paymentData?.tax}</div>
              <div className="fw-light fs-6"><span className='fw-bold fs-6'>Total: </span>$ {paymentData?.total}</div>
            </div>

          </div>

          <PaymentForm closeDialog={closeDialog} />
        </div>

        {
          action != "addScreens" && (
            <div className="col-6 d-flex flex-column p-5 bg_gray">
              <span className='fs-5 fw-bold mb-2'>What will you get:</span>
              <FeaturesList selectedPackage={selectedPackage} />
            </div>
          )
        }

      </div >
    </Dialog>
  )
}

export default PaymentDialog