const globalActions = {
  // General State Updates
  setDisableBtn: (state, action) => {
    state.disableBtn = action.payload;
  },
  setIsLoading: (state, action) => {
    state.isLoading = action.payload;
  },
  setRerender: (state, action) => {
    state.rerender = action.payload;
  },
  setPackages: (state, action) => {
    state.packages = action.payload;
  },
  setStepsBack: (state, action) => {
    state.stepsBack = action.payload;
  },
  setCountries: (state, action) => {
    state.countries = action.payload;
  },
  setRerenderList: (state, action) => {
    state.rerenderList = action.payload;
  },

  // Subscription Information
  setRerenderSubscription: (state, action) => {
    state.rerenderSubscription = action.payload;
  },
  setCompanyDetails: (state, action) => {
    state.companyDetails = action.payload;
  },
  setSubscriptionInfo: (state, action) => {
    state.subscriptionInfo = action.payload;
  },

  // Auth State
  setUserInfo: (state, action) => {
    state.userInfo = action.payload;
  },
  setIsAuthenticated: (state, action) => {
    state.isAuthenticated = action.payload;
  },

  // Shared Component State
  setLayerOptions: (state, action) => {
    state.layerOptions = action.payload;
  },
  setSelectedApp: (state, action) => {    
    state.selectedApp = action.payload;
  },
  setAssign: (state, action) => {
    state.assign = action.payload;
  },
  setLayer: (state, action) => {
    state.layer = action.payload;
  },
  setType: (state, action) => {
    state.type = action.payload;
  },
  setIsPrayerCampaign: (state, action) => {
    state.isPrayerCampaign = action.payload;
  },
  setHideTabs: (state, action) => {
    state.hideTabs = action.payload;
  },
  setCampaignDetails: (state, action) => {
    state.campaignDetails = action.payload;
  },
  setIsPublished: (state, action) => {
    state.isPublished = action.payload;
  },
  setSelectedMediaInfo: (state, action) => {
    state.selectedMediaInfo = action.payload;
  },
  setDeletedItemId: (state, action) => {
    state.deletedItemId = action.payload;
  },
  setProfileImage: (state, action) => {
    state.profileImage = action.payload;
  },

  setDeleteDialog: (state, action) => {
    state.deleteDialog = action.payload;
  },
  setOpenDialog: (state, action) => {
    state.openDialog = action.payload;
  },

  // Global Dialogs
  toggleDialog: (state, action) => {
    //   state.openDialog[action.payload.key] = action.payload.value;
    // dispatch(toggleDialog(key));
    const key = action.payload;
    state.openDialog[key] = !state.openDialog[key];
    document.body.style.overflowY = state.openDialog[key] ? "hidden" : "scroll";
  },

  toggleLoader: (state, action) => {
    //     dispatch(toggleLoader(key));
    const key = action.payload;
    state.subLoaders[key] = !state.subLoaders[key];
  },

  toggleDeleteDialog: (state, action) => {
    // dispatch(toggleDeleteDialog(key));
    const key = action.payload;
    state.deleteDialog[key] = !state.deleteDialog[key];
  },
};

export default globalActions;
