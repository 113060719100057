import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { getFormErrorMessage } from '../../../config/global';

import { ProgressSpinner } from "primereact/progressspinner";
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';

import FirebaseChannelsListener from '../../Services/FirebaseChannelsListener';
import DeviceLastLoginListener from '../../Services/DeviceLastLoginListener';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDevice, setOnlineDevices } from '../../../redux/Slices/deviceSlice';
import { setAssign, setDisableBtn, setRerenderList, toggleDialog } from '../../../redux/Slices/Global/globalSlice';

const ChannelsList = () => {
    // to assign channel , its mandatory to have the device online and support satellite

    const { selectedDevice, onlineDevices } = useSelector((state) => state.device);
    const screenDetails = useSelector((state) => state.layout.screenDetails);

    const dispatch = useDispatch();
    const { layer, assign, type } = useSelector((state) => state.global);

    const { showToast } = useCustomToast();

    const { formState: { errors }, control, handleSubmit, reset } = useForm();

    const [channelsList, setChannelsList] = useState([]);
    const [devicesList, setDevicesList] = useState([]);

    const [showDevices, setShowDevices] = useState(false);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    // Check if campaign assigned to devices don't support satellite
    const getData = () => {
        const campaign_id = window.location.pathname.includes("layout") ? screenDetails.id : id;
        setLoading(true);
        axiosInstance.get(`/campaign/device/${campaign_id}/check/satellite`)
            .then((res) => {
                let data = res?.data?.data;
                if (data?.total_devices === 0) {
                    let devices = data?.devices_support_satellite;

                    setDevicesList(data?.devices_support_satellite);
                    if (selectedDevice.id == "" && devices?.length) {
                        dispatch(setSelectedDevice({
                            serial_name: devices[0].serial_name,
                            name: devices[0].name,
                            id: devices?.[0].id,
                            online: 1,
                        }))
                        fetchChannels(devices[0].id);
                    }

                    setShowDevices(true);
                }
                setLoading(false);
            })
    };

    // Send notification to device to update Channel on firebase
    const fetchChannels = (id = null) => {
        setTimeout(() => {
            axiosInstance.get(`/device/${id ?? selectedDevice.id}/channels`)
        }, 1000);
    }

    const onSubmit = (data) => {
        dispatch(setDisableBtn(true));

        let formData = {
            "layer": window.location.pathname.includes("layout") ? "MAIN" : layer,
            "type": "channel",
        }

        // Find selected option
        const selectedOption = channelsList.find(channel => channel.chanNub === data.channels);

        if (window.location.pathname.includes("campaign")) {
            formData = {
                ...formData,
                campaign_id: id,
                channel_info: {
                    channel_name: selectedOption?.serviceName,
                    channel_id: selectedOption?.chanNub
                }
            }
            var url = `/campaign/content/assign`;

        } else if (window.location.pathname.includes("layout")) {
            formData = {
                ...formData,
                campaign_id: screenDetails.id,
                "channel_info": {
                    "channel_name": selectedOption?.serviceName,
                    "channel_id": selectedOption?.chanNub
                }
            }
            var url = `/campaign/content/assign`;
        } else {
            var url = `/playlist/channel/assign`;
            formData = {
                ...formData,
                playlist_id: id,
                channel_name: selectedOption?.serviceName,
                channel_id: selectedOption?.chanNub
            }
        }

        axiosInstance.post(url, formData)
            .then((res) => {
                dispatch(toggleDialog("addContent"))
                dispatch(setRerenderList(true));
            }).catch(error => {
                dispatch(setDisableBtn(false));
                showToast('error', `Assign Media to ${type}`, error?.response?.data?.message);
            })

        dispatch(setDisableBtn(false));
        dispatch(setAssign(false));
    }

    const deviceHandler = (id) => {
        devicesList.map(item => {
            if (item.id == id) {
                dispatch(setSelectedDevice({
                    id: id,
                    name: item.name,
                    online: 0,
                    serial_name: item.serial_name
                }))
            }
        })
    }

    useEffect(() => {
        reset({ "device": selectedDevice.id })
        setDevicesList([])
        getData();
    }, []);

    useEffect(() => {
        if (selectedDevice.id !== "" && !onlineDevices[selectedDevice.serial_name]) {
            const updatedOnlineDevices = {
                ...onlineDevices,
                [selectedDevice.serial_name]: 0,
            };

            dispatch(setOnlineDevices(updatedOnlineDevices));
        }
    }, [selectedDevice.id]);

    //  Send notification to device to send channels
    useEffect(() => {
        if (!_.isEmpty(selectedDevice?.id))
            fetchChannels();
    }, [selectedDevice.id]);

    useEffect(() => {
        if (assign) {
            handleSubmit(onSubmit)()
            dispatch(setAssign(false))
        }
    }, [assign]);

    useEffect(() => {
        if (channelsList.length != 0) {
            axiosInstance.post(`/device/channels/${selectedDevice.id}`, { "channels": channelsList })
        }
    }, [channelsList]);

    return (
        <div className='w-100 flex justify-center items-center h-full'>
            {
                loading ?
                    <ProgressSpinner
                        style={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    showDevices ?
                        devicesList.length == 0 ?
                            <Message
                                className="fw-bold fs-3 p-4 w-75"
                                severity="warn"
                                text="There are no devices that support satellites!"
                            />
                            :
                            <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                                <form className="d-flex flex-column align-items-center">
                                    {/* Devices input */}
                                    <div className={`col-8 my-5 px-4`}>
                                        <div className="field">
                                            <label className=" form-label" htmlFor="basic-default-company"> Devices Support satellite </label>
                                            <span className="p-float-label">
                                                <Controller name="device" control={control}
                                                    rules={{ required: 'Device is required.' }}
                                                    render={({ field, fieldState }) =>
                                                    (
                                                        <Dropdown id={field.name} {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e.value); deviceHandler(e.value)
                                                            }}
                                                            options={devicesList}
                                                            value={selectedDevice.id}
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            inputRef={field.ref}
                                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                            placeholder="Select Device"
                                                        />
                                                    )}
                                                />
                                                {getFormErrorMessage('device', errors)}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Channels input */}
                                    <div className={`col-8 mb-5 px-4 flex justify-center`}>
                                        {
                                            onlineDevices[selectedDevice.serial_name]
                                                ?
                                                (channelsList?.length ?
                                                    <div className="field w-100">
                                                        <label className="form-label" htmlFor="basic-default-company">Channels </label>
                                                        <span className="p-float-label">
                                                            <Controller name="channels" control={control}
                                                                rules={{ required: 'Channel is required.' }}
                                                                render={({ field, fieldState }) => {
                                                                    return (
                                                                        <Dropdown id={field.name} {...field}
                                                                            options={channelsList}
                                                                            optionLabel="serviceName"
                                                                            optionValue="chanNub"
                                                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                                            placeholder="Select Device"
                                                                            inputRef={field.ref}
                                                                            filter
                                                                            filterBy="serviceName"
                                                                        />
                                                                    )
                                                                }} />
                                                            {getFormErrorMessage('channels', errors)}
                                                        </span>
                                                    </div>
                                                    :
                                                    <Message
                                                        severity="warn"
                                                        className='text-center'
                                                        text={`Waiting for device to update channels!`}
                                                    />
                                                )

                                                :
                                                <Message
                                                    severity="error"
                                                    className='text-center'
                                                    text={`The selected device "${selectedDevice.name}" is offline, please turn the device on! `}
                                                />
                                        }
                                    </div>
                                </form>

                                {
                                    loading ?
                                        <ProgressSpinner
                                            style={{ width: '60px', height: '60px', opacity: .1 }}
                                            fill="var(--surface-ground)"
                                            animationDuration="1s"
                                            strokeWidth="3"
                                        />
                                        : <></>
                                }
                            </div>
                        :
                        <Message
                            className="fw-bold fs-3 p-4 w-75"
                            severity="warn"
                            text="This campaign has been assigned to devices that do not support satellite. And assigning a channel to it will cause issues!"
                        />


            }

            <FirebaseChannelsListener
                setChannelsList={setChannelsList}
                path={"Devices/" + selectedDevice.serial_name + "/DeviceSource"} />


            <DeviceLastLoginListener
                path={"Devices/" + selectedDevice.serial_name + "/lastLogin"}
                serialName={selectedDevice.serial_name} />

        </div>
    );
}

export default ChannelsList