import React, { useState, useEffect } from "react";

import { Spinner } from 'react-bootstrap';

import axiosInstance from "../../../../config/Axios";

const LayoutPreview = ({ template, screens, onClick }) => {
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});

    const [loading, setLoading] = useState(false);
    const [aspectWidth, setAspectWidth] = useState();

    const [aspectHeight, setAspectHeight] = useState();

    useEffect(() => {
        setLoading(true)
        setCurrentAspectRatio(template.aspect_ratio)
    }, []);

    useEffect(() => {
        if (currentAspectRatio) {
            setAspectWidth(currentAspectRatio.width)
            setAspectHeight(currentAspectRatio.height)
            setLoading(false)

        }
    }, [currentAspectRatio]);


    // 4 the scaled dimensions
    const calculateScaledDimensions = () => {
        if (aspectWidth === 0 || aspectHeight === 0) return { width: 0, height: 0 };

        const widthRatio = 196 / aspectWidth;
        const heightRatio = 125 / aspectHeight;

        const scaleFactor = Math.min(widthRatio, heightRatio);

        const scaledWidth = aspectWidth * scaleFactor;
        const scaledHeight = aspectHeight * scaleFactor;

        return { width: scaledWidth, height: scaledHeight };
    };

    const { width: scaledWidth, height: scaledHeight } = calculateScaledDimensions();


    return (
        // <div className="position-relative w-[100%] h-[125px] " style={{ border: "1px solid #ccc" }} onClick={onClick}>
        //     {screens.map((screen, index) => (
        //         <div

        //             key={screen.id}
        //             className="position-absolute border text-[10px] text-center flex items-center justify-center"
        //             style={{
        //                 left: `${screen.start_margin}%`,
        //                 top: `${screen.top_margin}%`,
        //                 width: `${screen.width}%`,
        //                 height: `${screen.height}%`,
        //             }}
        //         >
        //             <div className=" ">{index + 1}</div>
        //         </div>
        //     ))
        //     }
        // </div >
        <>{
            loading ?
                <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                    <Spinner as="span" variant="info" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                </div>
                :

                <div className={`w-[100%] mb-4  mt-3 h-[125px]`}>
                    <div className={`flex relative justify-center w-[full] h-100`}>
                        <div
                            className={`p-4 relative border border-gray-300 rounded-md bg-white  `}
                            style={{
                                width: scaledWidth,
                                height: scaledHeight,
                            }}
                            onClick={onClick}
                        >
                            {screens.map((area, index) => {
                                return (
                                    <div
                                        key={area.id}
                                        className=" flex justify-center text-[black] items-center absolute border border-black text-black text-center text-sm"
                                        style={{
                                            left: `${area.start_margin}%`,
                                            top: `${area.top_margin}%`,
                                            width: `${area.width}%`,
                                            height: `${area.height}%`,
                                        }}
                                    >
                                        {index + 1}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div >
        }</>
    );
};

export default LayoutPreview;