import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useCustomToast } from '../../Contexts/ToastContext';

import DeleteConfirmation from '../../SharedComponents/DeleteConfirmation';
import { NetworkManager } from '../../../config/NetworkManager';
import { useTitle } from '../../../config/global';
import AddUser from './AddUser';
import avatar from '../../../assets/img/unknown_person.jpg';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog, toggleDeleteDialog, setDisableBtn } from '../../../redux/Slices/Global/globalSlice';

let emptyUser = {
	name: null,
	email: null,
	phone: null
};

const UserDatatable = () => {
	useTitle('Users');
	const dispatch = useDispatch();
	const { openDialog, deleteDialog, userInfo } = useSelector((state) => state.global);

	const { totalRecords, setReload, loading, lazyParams, setLazyParams, data, onPage, onSort, onFilter, } = useDataTableContext();
	const { showToast } = useCustomToast();

	const Service = new NetworkManager();

	const [user, setUser] = useState(null);

	useEffect(() => {
		setLazyParams(prev => ({
			...prev,
			modelName: "users",
			filters: {
				'name': { value: '', matchMode: 'contains' },
				'email': { value: '', matchMode: 'contains' },
				'phone': { value: '', matchMode: 'contains' },
				'status': { value: '', matchMode: 'contains' },
			}
		}))
	}, [])

	const confirmDeleteUser = (data) => {
		setUser(data);
		dispatch(toggleDeleteDialog("user"));
	}

	const deleteUser = () => {
		Service.deleteRow('users', user);
		dispatch(setDisableBtn(false))
		setReload(true);
		dispatch(toggleDeleteDialog("user"));
		showToast('success', 'Delete User', 'User Deleted Successfully!');
	}

	const openNew = () => {
		setUser(emptyUser);
		dispatch(toggleDialog("addUser"))
	}

	const actionBodyTemplate = (rowData) => {
		return (userInfo.type != "OWNER") ?
			<> </>
			:
			<>
				{
					rowData.email == localStorage.getItem("email") ? "" :
						(<div className="d-inline-block text-nowrap">
							{/* Delete playlist */}
							<Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} className="fs-8" />
							<button className={`btn btn-sm btn-icon delete-record delete-button-${rowData.id}`}
								data-pr-position="bottom"
								data-pr-tooltip="Delete"
								onClick={() => confirmDeleteUser(rowData)} >
								<i className="bx bx-trash"></i>
							</button>
						</div>)
				}
			</>

	}

	const statusBodyTemplate = (rowData) => {
		return (
			<div className=''>
				<span className={` badge ${rowData.status == "ACTIVE" ? "bg-label-success" : "bg-label-danger"} `}>{rowData.status.toLowerCase()}</span>
			</div>
		);
	}

	const dateBodyTemplate = (rowData) => {
		return (rowData.created_at);
	}

	const imageBodyTemplate = (rowData) => {
		return (
			<>
				<div className="d-flex justify-content-start align-items-center user-name">
					<div className="avatar-wrapper">
						<div className="avatar avatar-sm me-3">
							<img src={`${rowData.image ? rowData.image : avatar}`} className="rounded" />
						</div>
					</div>
					<div className="d-flex flex-column">
						<span className="fw-semibold">{rowData.name}</span>
					</div>
				</div>
			</>);
	}

	return (
		<div className="card">
			<div className="card-header d-flex align-items-center justify-content-end">
				<h5 className="card-title m-0 me-auto">Team Members</h5>

				<button type="button" className="d-flex align-items-center btn btn-primary me-sm-3 me-1" onClick={openNew}>
					<i className="tf-icons bx bx-user me-2"></i> New Member
				</button>
			</div>

			<div className='table-responsive text-nowrap'>
				<DataTable value={data.users} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
					paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
					totalRecords={totalRecords} onPage={onPage}
					onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
					onFilter={onFilter} filters={lazyParams.filters} loading={loading} className="table"
				>
					<Column field="name" header="Name" body={imageBodyTemplate} sortable filter />
					<Column field="email" header="Email" sortable filter />
					<Column field="phone" header="Phone Number" filter sortable />
					<Column header="status" filterField="status"
						body={statusBodyTemplate}
						showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '14rem' }}
						filter sortable />
					<Column header="Created Date" filterField="created_at" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate}
						sortable />
					<Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
				</DataTable>

				{
					deleteDialog.user &&
					<DeleteConfirmation
						deleteHandler={deleteUser}
						itemName={user.name ?? "playlist"}
						dialogKey="user"
					/>
				}

				{openDialog.addUser && <AddUser />}
			</div>
		</div>
	);
}
export default UserDatatable;
