import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Spinner } from "react-bootstrap";
import { Dialog } from "primereact/dialog";

import { useCustomToast } from "../../../Contexts/ToastContext";
import { onDragEnd } from "../../../../config/global";
import axiosInstance from "../../../../config/Axios";

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';
import { setRerender, setLoading } from '../../../../redux/Slices/deviceSlice';

const droppableColumns = {
    Columns: {
        name: "All Groups",
        items: [],
    }
};

function GroupsOrderingDialog({ groups }) {
    const dispatch = useDispatch();
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    droppableColumns.Columns.items = Object.values(groups);
    const [columns, setColumns] = useState(droppableColumns);

    const { showToast } = useCustomToast();

    const order = () => {
        dispatch(setDisableBtn(true));
        let groupIds = Object.entries(columns.Columns.items).map(item => item[1].group_id);

        axiosInstance.put(`/groups/order`, { "ids": groupIds }
        ).then((result) => {
            dispatch(setLoading(true))
            dispatch(setRerender(true))
            dispatch(toggleDialog("orderDialog"))
        }).catch((error) => {
            showToast('error', 'Group Order', error?.response?.data?.message);
        });
        dispatch(setDisableBtn(false));
    }

    return (
        <Dialog visible={openDialog.orderDialog}
            style={{ width: '35vw' }}
            header="Update Group Order"
            modal className="p-fluid"
            breakpoints={{ '960px': '95vw' }}
            onHide={() => dispatch(toggleDialog("orderDialog"))}>

            <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)} className="w-100" >
                {
                    Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div className="d-flex flex-column align-items-center" key={columnId}>
                                <Droppable droppableId={columnId} key={columnId}>
                                    {
                                        (provided, snapshot) => {
                                            return (
                                                <div {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    className="p-3 droppable_container "
                                                >
                                                    {
                                                        column.items.map((item, index) => {
                                                            return (
                                                                <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index} >
                                                                    {(provided, snapshot) => {
                                                                        return (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                style={{
                                                                                    backgroundColor: '#f5f5f9',
                                                                                    userSelect: "none",
                                                                                    ...provided.draggableProps.style,
                                                                                }}
                                                                                className='d-flex flex-row flex-nowrap align-items-center p-3 pe-3  px-0 px-sm-2 my-2 rounded-2 border-1'>
                                                                                <i className="fas fa-network-wired mx-3"></i>
                                                                                <span>{item.name}</span>

                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Draggable>
                                                            );
                                                        })
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            );
                                        }}
                                </Droppable>
                            </div>
                        );
                    })}
            </DragDropContext>

            <div className="col-12 text-center mt-5 d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <button type="reset"
                    className="btn btn-label-secondary me-3 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    disabled={disableBtn ? true : false}
                    onClick={() => dispatch(toggleDialog("orderDialog"))}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary me-2" disabled={disableBtn ? true : false} onClick={() => order()}>
                    {disableBtn ?
                        <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        :
                        "Order"
                    }
                </button>
            </div>
        </Dialog>
    )
}

export default GroupsOrderingDialog