import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import { useCustomToast } from '../../Contexts/ToastContext';
 
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useAppsContext } from '../../Contexts/AppsContext';
import { ColorsInputs, PositionInput } from '../../SharedComponents/RSS/Inputs';
import { BottomOption } from '../../SharedComponents/RSS/Positions/Bar';

import { getFormErrorMessage } from '../../../config/global';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import axiosInstance from '../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import {setDisableBtn ,toggleDialog ,   toggleLoader} from '../../../redux/Slices/Global/globalSlice';

function CreateCustomRssDialog () {
    const { formState: { errors }, handleSubmit, control, reset } = useForm({ shouldUnregister: true, mode: 'onChange' });
    const { openDialog, subLoaders , disableBtn} = useSelector((state) => state.global);
    const dispatch = useDispatch()
    
    const { resetStates, rssSettings, formDataHandler, isCreation, selectedRss } = useAppsContext();
    const { setReload } = useDataTableContext();
    const { showToast } = useCustomToast();

    useEffect(() => {
        if (!isCreation) {
            reset({
                name: selectedRss?.file_name,
                position: selectedRss?.rss_settings?.position
            })
        } else {
            reset({ position: rssSettings?.position })
        }

        return () => { resetStates() }
    }, [])

    const createHandler = (data) => {
        dispatch(setDisableBtn(true));

        let url = isCreation ? `/rss/create` : `/rss/custom/${selectedRss?.id}/update`
        
        const formData = new FormData();
        formData.append("name", data?.name);

        formDataHandler(formData, data);

        axiosInstance.post(url, formData)
            .then((result) => {
                dispatch(toggleDialog("customRssCreation"))
                dispatch(toggleLoader("reload"));
                setReload(true);
                showToast('success', "Broadcast Creation", "Broadcast created successfully!");
                dispatch(setDisableBtn(false));
            })
            .catch((error) => {
                dispatch(setDisableBtn(false));
            })
    }

    return (
        <Dialog visible={ openDialog.customRssCreation }
            style={ { width: '30%' } }
            breakpoints={ { '960px': '95vw' } }
            header={ `Custom RSS ${ isCreation ? "Creation" : "Editing" }` }
            modal className="p-fluid"
            onHide={ () => dispatch(toggleDialog("customRssCreation") )}
        >
            <form onSubmit={ handleSubmit(createHandler) } className="d-flex flex-wrap">
                <div className="col-12 d-flex flex-wrap p-4 ">
                    <div className='mb-2 col-12 d-flex flex-column'>
                        <label htmlFor="" className={ `form-label text-capitalize mb-2 ` } >Rss Name</label>
                        <Controller name="name" control={ control }
                            rules={ { required: 'RSS feed name is required.' } }
                            render={ ({ field, fieldState }) => (
                                <InputText
                                    type="text"
                                    value={ field.value }
                                    id={ field.name }
                                    { ...field }
                                    inputRef={ field.ref }
                                    className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                />
                            ) } />

                        { getFormErrorMessage("name", errors) }
                    </div>

                    <PositionInput control={ control } errors={ errors } />

                    <ColorsInputs />

                    {
                        ["BOTTOM", "TOP"].includes(rssSettings?.position) ?
                            <BottomOption control={ control } />
                            :
                            <></>
                    }
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" disabled={ disableBtn ? true : false }
                        data-bs-dismiss="modal" aria-label="Close" onClick={ () => dispatch(toggleDialog("customRssCreation")) }>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary  me-1" disabled={ disableBtn || subLoaders.createLoading } >
                        {
                            disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                isCreation ? "Create" : "Update"
                        }
                    </button>
                </div>
            </form>

        </Dialog >
    )
}

export default CreateCustomRssDialog