import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import axiosInstance from "../config/Axios";

import { useSelector, useDispatch } from 'react-redux';
import {setCompanyDetails ,setLayerOptions, setRerenderSubscription, setSubscriptionInfo, toggleLoader, setUserInfo} from '../redux/Slices/Global/globalSlice';

const CompanyDetailsRouter = () => {

    const dispatch = useDispatch();
    const { rerenderSubscription, isAuthenticated } = useSelector((state) => state.global);
    

    useEffect(() => {
 
            fetchData();
    }, [ ]);

    useEffect(() => {
        if (rerenderSubscription) {
            fetchData();
        }
    }, [rerenderSubscription]);

    const fetchData = () => {
        if (isAuthenticated) {
            // loadingHandler("companyDetails");
            dispatch(toggleLoader("companyDetails"));
            dispatch(setLayerOptions([]));
            axiosInstance.get(`/company/details`).then((result) => {
                let res = result?.data?.data;

                dispatch(setCompanyDetails(res));
                dispatch(setUserInfo(res?.user));
                // loadingHandler("companyDetails");
                dispatch(toggleLoader("companyDetails"));

                let packageName = res?.package?.title.toLowerCase() == "professional";
                let didEnded= new Date() > new Date(res?.subscription?.end_date);
                let repeated = res?.subscription?.period == "ONE_YEAR";
                let areFree =  res?.package?.is_free == 1;

                dispatch(setSubscriptionInfo({
                     isProPackage: packageName,
                    isFinished: didEnded,
                    isYearly: repeated,
                    isFree: areFree,
                }));

                //Set allowed Layers for campaign and playlist setup
                dispatch(setLayerOptions(
                    res?.package?.features?.multiple_layer.map((layer) => ({
                        name: layer.charAt(0).toUpperCase() + layer.slice(1).toLowerCase(),
                        code: layer.toUpperCase(),
                    }))
                ))
                dispatch(setRerenderSubscription(false));
            })
        }
    };

    return <Outlet />;

}

export { CompanyDetailsRouter }