
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Checkbox } from 'primereact/checkbox';

import { useCustomToast } from '../../Contexts/ToastContext';
import LazyImage from '../ShowMedia/LazyImage'

import { ProgressSpinner } from 'primereact/progressspinner';
import axiosInstance from '../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog, setAssign, setRerenderList } from '../../../redux/Slices/Global/globalSlice';
import { Message } from 'primereact/message';

function LinksList() {
    const dispatch = useDispatch();
    const { assign, layer, campaignDetails } = useSelector((state) => state.global);
    const screenDetails = useSelector((state) => state.layout.screenDetails);

    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [ids, setIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const { id } = useParams();

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    const callMedia = async (searchTerm = "") => {
        setLoading(true);

        let url = `/media?page=${pageNum}&per_page=12&section=links&layer=${layer}&is_interactive=${campaignDetails.is_interactive ? 1 : 0}`;

        if (searchTerm.trim() !== "") {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }

        try {
            const response = await axiosInstance.get(url);
            const all = new Set([...data, ...response.data.data]);
            setData([...all]);
            setLastPage(response.data.pagination.total_pages);
        } catch (error) {
            console.error("Error fetching media:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        setPageNum(1);
        setData([]);
        callMedia(searchTerm);
        // setSearchTerm('')
    }, [searchTerm]);

    useEffect(() => {
        const searchTemp = searchTerm;
        setSearchTerm(searchTemp)
        // callMedia(searchTerm);

    }, [pageNum]);

    useEffect(() => {
        if (pageNum <= lastPage || lastPage == null) {
            // callMedia();
            const searchTemp = searchTerm;
            setSearchTerm(searchTemp)
        }
    }, [pageNum]);

    useEffect(() => {
        if (isUploaded) {
            const searchTemp = searchTerm;
            // setPageNum(1, () => callMedia());
            setPageNum(1, () => setSearchTerm(searchTemp));

            setIsUploaded(false);
        }
    }, [isUploaded]);

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);


    // useEffect(() => {
    //     if (ids.length == 0) {
    //         dispatch(setDisableBtn(true))
    //     } else {
    //         dispatch(setDisableBtn(false))
    //     }
    // }, [ids])

    useEffect(() => {
        if (assign) {
            dispatch(setDisableBtn(true))

            if (window.location.pathname.includes("playlist")) {
                assignHandler(
                    "/playlist/media/assign",
                    "Playlist",
                    {
                        "playlist_id": id,
                        "layer": layer,
                        "list_media_ids": ids
                    }
                )
            } else {
                assignHandler(
                    "/campaign/content/assign",
                    "Campaign",
                    {
                        "campaign_id": window.location.pathname.includes("layout") ? screenDetails.id : id,
                        "layer": layer,
                        "ids_list": ids,
                        "type": "link",
                    },
                )
            }
        }
    }, [assign])

    const assignHandler = (url, type, data) => {
        axiosInstance.post(url, data)
            .then((result) => {
                dispatch(setDisableBtn(false))
                if (result?.data?.data?.error != "") {
                    showToast('error', `Assign Media to ${type}`, result?.data?.data?.error);
                }
                else {
                    showToast('success', `Assign Media to ${type}`, result?.data?.data?.msg);
                    dispatch(toggleDialog("addContent"))
                }
                dispatch(setRerenderList(true))
            }).catch((error) => {
                dispatch(setDisableBtn(false));
                if (error?.response?.data?.errors)
                    Object.values(error?.response?.data?.errors).forEach(error => {
                        showToast('error', `Assign Media to ${type}`, error[0]);
                    });
            });
        dispatch(setAssign(false))
    }

    const onMediaChange = (e) => {
        let selectedMedia = [...ids];

        if (e.checked)
            selectedMedia.push(e.value);
        else
            selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

        setIds(selectedMedia);
    }

    return (
        <>

            {
                loading && data.length == 0 ?
                    <ProgressSpinner
                        style={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s" />
                    :
                    data.length == 0 && !loading ?
                        <div className='w-100 flex justify-center items-center h-full'>
                            <Message className="fw-bold fs-3 p-4 w-75  mx-auto" severity="warn" text="There were no compatible links uploaded for this campaign's layer!" />
                        </div>
                        :
                        <>
                            <div className="d-flex justify-content-start my-3 ml-5">
                                <input
                                    type="text"
                                    className="form-control w-100"
                                    placeholder="Search Links..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <button
                                    className="btn btn-primary mx-2 me-3"
                                    onClick={() => setPageNum(1)}
                                >
                                    Search
                                </button>
                            </div>

                            <div className="d-flex flex-column scroll_container scroll_div px-3 w-100">
                                <div className="row my-3">
                                    <div className="col-2"></div>
                                    <div className="col-3 mx-2 text-light fs-6  ps-5"> name</div>
                                    <div className="col-3 fs-6 text-center text-light d-none d-sm-block" >type</div>
                                </div>
                                <div className="d-flex flex-wrap  mb-4">
                                    {
                                        data.length > 0 &&
                                        data.map((item, index) => {
                                            let imgEl = null;

                                            if (item.type != "HDMI") {
                                                imgEl = <LazyImage media={item} popupType={"media_preview"} index={index} styleClass={"me-2 image_container  w-75"} />;
                                            } else {
                                                imgEl = <div className={`p-4 d-flex bg-secondary align-items-center justify-content-center playlist_icon_container w-75`}>
                                                    <span className=" fs-6 text-white"> HDMI</span>
                                                </div>
                                            }

                                            return index === data.length - 1 && pageNum <= lastPage ?
                                                <div className=" w-100 d-flex align-items-center justify-content-between my-1 pb-2" ref={setLastElement} key={`${item.type}-${index}`} >
                                                    <label htmlFor={item.id} className="col-11 d-flex ">
                                                        <div className="col-2 mx-2 "> {imgEl}  </div>
                                                        <div className="col-9 col-md-3 col-sm-6 mx-1 fs-6 d-flex align-items-center "> {item.file_name} </div>
                                                        <div className="col-4 d-flex align-items-center justify-content-center text-capitalize d-none d-sm-block fs-6 text-light fs-6">
                                                            {item.type}
                                                        </div>
                                                    </label>
                                                    <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onMediaChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                </div>
                                                :
                                                <div className={`w-100 d-flex align-items-center justify-content-between my-1 me-3 pb-2 ${index + 1 === data.length ? "" : "border-bottom"} `} key={`${item.type}-${index}`} >
                                                    <label htmlFor={item.id} className="col-11 d-flex ">
                                                        <div className={`col-2 mx-2 `}> {imgEl}</div>
                                                        <div className="col-9 col-sm-6 col-md-3   mx-1 fs-6 d-flex align-items-center text-truncate"> {item.file_name} </div>
                                                        <div className="col-3 d-flex align-items-center justify-content-center d-none d-sm-flex text-capitalize fs-6 text-light fs-6 fst-italic  ">
                                                            <span>{item.type == "HDMI" ? item.type : item.type.toLowerCase()}</span>
                                                        </div>
                                                    </label>
                                                    <div className="col-2 col-sm-1 me-3">
                                                        <Checkbox inputId={item.id} name="media" className='me-3' value={item.id} onChange={onMediaChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                                                    </div>
                                                </div>
                                        })
                                    }
                                    {
                                        loading &&
                                        <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                                    }
                                </div>
                            </div>
                        </>
            }
        </>
    );
}

export default LinksList