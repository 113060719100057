import React from 'react'
import { useForm } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function AddUser() {
    const dispatch = useDispatch();
    const openDialog = useSelector((state) => state.global.openDialog);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { setReload } = useDataTableContext();
    const { showToast } = useCustomToast();


    const onSubmit = (data) => {
        axiosInstance.post("/users/create", data)
            .then((result) => {
                dispatch(toggleDialog("addUser"))
                setReload(true);
                showToast('success', 'Create User', 'User Created Successfully');
            })
            .catch((error) => {
                showToast('error', 'Create User', error?.response?.data?.message);
            });
    };

    return (
        <Dialog
            visible={openDialog.addUser}
            style={{ width: '600px' }}
            breakpoints={{ '960px': '95vw' }}
            header="Member Details"
            modal className="p-fluid"
            onHide={() => dispatch(toggleDialog("addUser"))}
        >
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="px-4 my-5">
                    <div className=" ">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>

                        <input
                            {...register("email", {
                                required: "Email Address is required",
                                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                            })}
                            aria-invalid={errors.email ? "true" : "false"}
                            className={errors.email ? 'form-control is-invalid' : 'form-control'}
                            placeholder="Email"
                        />

                        {errors.email && <p className='fv-plugins-message-container invalid-feedback'>{errors.email?.message}</p>}
                        {errors?.email?.type === "pattern" && (
                            <p className='fv-plugins-message-container invalid-feedback'>Invalid Email Address</p>
                        )}
                    </div>

                </div>
                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(toggleDialog("addUser"))}>Cancel</button>
                    <button type="submit" className="btn btn-primary me-1">Invite</button>
                </div>
            </form>
        </Dialog>)
}

export default AddUser