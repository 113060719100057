import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";

import { Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';

import { useCustomToast } from '../../../../Contexts/ToastContext';
import { useDataTableContext } from '../../../../Contexts/DataTableContext';

import axiosInstance from '../../../../../config/Axios';

import LayoutPreview from '../LayoutPreview';
import TemplateCustomizationPopUp from '../Custom/TemplateCustomizationPopUp';


import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog, toggleLoader } from '../../../../../redux/Slices/Global/globalSlice';
import { setDefaultTemplate, setSelectedTemplate, setSelectedLayout, setScreens_setting, setScreen, template, setScreenDetails } from '../../../../../redux/Slices/layoutSlice';

const TemplatesLayouts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();
    const { setReload } = useDataTableContext();

    const { defaultTemplate, screens_setting, selectedTemplate } = useSelector((state) => state.layout);
    const { openDialog, subLoaders, disableBtn } = useSelector((state) => state.global);




    const handleTemplateSelect = (template) => {
        dispatch(toggleLoader("createLoading"))
        dispatch(setDisableBtn(true));
        axiosInstance.post(`/layout/${template.id}/select`)
            .then((result) => {
                const layout_Id = result.data.data[0].pivot.layout_id;
                dispatch(toggleLoader("createLoading"))
                dispatch(toggleDialog("CreateLayoutPopUp"))
                navigate(`/apps/layouts/${layout_Id}/show`);
                showToast('success', "Layout Creation", "Layout created successfully!");
                dispatch(setDisableBtn(false));
            })
            .catch((error) => {
                dispatch(toggleLoader("createLoading"))

                dispatch(setDisableBtn(false));
                showToast('error', 'Layout Creation', error);

            })
    }

    const handleTemplateCustomize = (template) => {
        // dispatch(setDisableBtn(true));
        dispatch(setSelectedTemplate(template));
        dispatch(toggleDialog("TemplateCustomizationPopUp"))
    }

    return (
        <>
            {
                subLoaders.createLoading ?
                    <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                        <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    </div>
                    :
                    <Row className="g-3 pe-5 pl-5 ">
                        {defaultTemplate.map((template, index) => (
                            <Col key={index} xs={12} sm={6} md={4} lg={3}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title><div className="text-[13px] text-center">{template.title}</div></Card.Title>
                                        <Card.Text><div className="ml-[10px] mr-[10px]">
                                            <LayoutPreview template={template} screens={template.screens_setting} onClick={() => {
                                                setSelectedTemplate(template)
                                                handleTemplateSelect(template)
                                            }} />
                                        </div></Card.Text>
                                    </Card.Body>

                                    <div className="w-100 pl-3 prl-3 pb-3 flex justify-center ">
                                        <button
                                            className="w-50 px-4 py-1 border-1 border-blue-700 text-[15px]  rounded-lg hover:bg-purple-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                                            onClick={() => handleTemplateSelect(template)}
                                        >
                                            Select
                                        </button>
                                        <button
                                            className="w-50 px-4 py-1 border-1 ml-2 border-blue-700 text-[15px]  rounded-lg hover:bg-purple-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                                            onClick={() => handleTemplateCustomize(template)}
                                        >
                                            Customize
                                        </button>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
            }
            {openDialog.TemplateCustomizationPopUp && <TemplateCustomizationPopUp />}

        </>
    );
};

export default TemplatesLayouts;