import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from "react-hook-form";

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { getFormErrorMessage } from '../../../config/global';
import { useCustomToast } from '../../Contexts/ToastContext';
import { useAppsContext } from '../../Contexts/AppsContext';
import axiosInstance from '../../../config/Axios';

import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { chooseOptions } from '../../../config/constants';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function FeedForm() {
    const { formState: { errors }, handleSubmit, control, reset } = useForm({ shouldUnregister: true, mode: 'onChange' });

    const disableBtn = useSelector((state) => state.global.disableBtn);
    const dispatch = useDispatch()

    const { selectedRss, setIsCreation, isCreation, selectedFeed } = useAppsContext();
    const { setRerender, setPageNum } = useScrollLoadingContext();
    const { showToast } = useCustomToast();

    const [image, setImage] = useState({});
    const fileUploadRef = useRef(null);

    useEffect(() => {
        reset({ title: selectedFeed?.title, description: selectedFeed?.description })
    }, [selectedFeed])

    const feedHandler = (data) => {
        dispatch(setDisableBtn(true));

        let url = isCreation ? `/rss/${selectedRss?.id}/add/feed` : `/rss/${selectedFeed?.id}/update/feed`

        const formData = new FormData();
        formData.append("title", data?.title);

        if (data?.description)
            formData.append("description", data?.description);

        if (!isEmpty(image))
            formData.append("image", image);

        axiosInstance.post(url, formData)
            .then((result) => {
                reset({ title: "", description: "" })
                onCancelUpload()
                showToast('success', "Feed Addition", result?.data?.data?.msg);
                dispatch(setDisableBtn(false));
                setPageNum(1)
                setRerender(true)
            })
            .catch((error) => {
                dispatch(setDisableBtn(false));
                showToast('error', "Feed Addition", error?.response?.data?.message);
            })
    }

    const onCancelUpload = () => {
        setImage({})
        fileUploadRef.current.clear();
    };

    const closeDialogHandler = () => {
        if (isCreation)
            dispatch(toggleDialog("addCustomFeed"))
        else {
            reset({ title: "", description: "" })
            setIsCreation(true)
        }
    }


    return (
        <form onSubmit={handleSubmit(feedHandler)} className=" d-flex flex-column justify-content-center">
            <div className="col-12 d-flex flex-column justify-content-center border-top border-gray p-3">
                <h5 className='col-12 mb-3'>News Content</h5>
                {/* Title Input */}
                <div className={`col-12 mb-3 d-flex flex-column`} >
                    <label htmlFor="title" className={`mb-2 form-label text-capitalize`} >Title</label>
                    <Controller name="title" control={control}
                        rules={{ required: "Title field is required!" }}
                        render={({ field, fieldState }) => (
                            <InputText
                                id={field.name}
                                {...field}
                                autoFocus
                                inputRef={field.ref}
                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                            />
                        )} />
                    {getFormErrorMessage("title", errors)}
                </div>

                {/* Description Input */}
                <div className={`col-12 mb-3 d-flex flex-column`} >
                    <label htmlFor="description" className={`mb-2 form-label text-capitalize`} >Description</label>
                    <Controller name="description" control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState }) => (
                            <InputTextarea
                                id={field.name}
                                {...field}
                                inputRef={field.ref}
                                rows={7}
                                cols={30}
                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`} />
                        )} />
                </div>

                {/* Image Input */}
                <div className={`col-12 mb-3 d-flex flex-column`} >
                    <label htmlFor="image" className={`mb-1 form-label text-capitalize`} >Image</label>
                    <Controller name="image" control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState }) => (
                            <FileUpload
                                mode="basic"
                                name="image"
                                accept="image/*"
                                maxFileSize={1000000}
                                customUpload
                                onSelect={(e) => setImage(e.files[0])}
                                ref={fileUploadRef}
                                onClick={onCancelUpload}
                                chooseOptions={chooseOptions}
                            />
                        )} />

                </div>
            </div>
            <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <button type="reset" className="btn btn-label-secondary me-3" disabled={disableBtn ? true : false}
                    data-bs-dismiss="modal" aria-label="Close" onClick={() => closeDialogHandler()}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary  me-1">
                    {
                        disableBtn ?
                            <Spinner variant="secondary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                            :
                            isCreation ? "Create" : "Update"
                    }
                </button>
            </div>
        </form>)
}

export default FeedForm