import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // the state from the original file
  selectedDevice: {
    id: "",
    serial_name: "",
    online: false,
    name: "",
  },
  showAssignForm: false,
  companyCampaigns: {},
  onlineDevices: {},
  deviceFilters: {
    status: "",
    name: "",
    isOnline: null
  },
  rerender: false,
  loading: true,
  editRotation: false,
  device: {
    name: null,
    serial_name: "",
  },
  selectedCampaign: "",
};

//the actions
const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setSelectedDevice(state, action) {   
      state.selectedDevice = { ...state.selectedDevice, ...action.payload };
    },
    setShowAssignForm(state, action) {
      state.showAssignForm = action.payload;
    },
    setCompanyCampaigns(state, action) {
      state.companyCampaigns = action.payload;
    },
    setOnlineDevices(state, action) {
      state.onlineDevices = { ...state.onlineDevices, ...action.payload };
     },
    setDeviceFilters(state, action) {
      state.deviceFilters = { ...state.deviceFilters, ...action.payload };
    },
    setRerender(state, action) {
      state.rerender = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setEditRotation(state, action) {
      state.editRotation = action.payload;
    },
    setDevice(state, action) {
      state.device = action.payload;
    },
    setSelectedCampaign(state, action) {
      state.selectedCampaign = action.payload;
    },
  },
});

export const {
  setSelectedDevice,
  setShowAssignForm,
  setCompanyCampaigns,
  setOnlineDevices,
  setDeviceFilters,
  setRerender,
  setLoading,
  setEditRotation,
  setDevice,
  setSelectedCampaign,
} = deviceSlice.actions;

export default deviceSlice.reducer;
