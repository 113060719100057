import React, { useState } from 'react';

import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { Media } from '../ShowMedia/Media';
import RssSettings from '../RSS/RssSettings';
import { useAppsContext } from '../../Contexts/AppsContext';
import { isEmpty } from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, setRerenderList } from '../../../redux/Slices/Global/globalSlice';

const _classes = {
  labels: "mb-1",
  separator: "p-2 fs-8"
}
const rssTypes = ["RSS", "RSS_CUSTOM"]

function MediaSettingPopup({ item, mediaSettingPopup, setMediaSettingPopup, removeMedia }) {
  const { layer, disableBtn } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const { rssSettings } = useAppsContext();
  const { showToast } = useCustomToast();

  const [editName, setEditName] = useState(false)
  const [name, setName] = useState(item.file_name)
  const [setting, setSetting] = useState({
    "show_video_screen": item.show_video_screen,
    "is_muted": item.is_muted,
    "is_showed": item.is_showed,
    "downloadable": item.downloadable,
    "show_layer_two": item.show_layer_two,
    "show_layer_one": item.show_layer_one,
    "show_layer_three": item.show_layer_three,
  });

  const updateSetting = () => {
    dispatch(setDisableBtn(true))

    const formData = new FormData();
    var data = {
      "show_video_screen": setting.show_video_screen ? 1 : 0,
      "downloadable": setting.downloadable ? 1 : 0,
      "is_showed": setting.is_showed ? 1 : 0,
      "is_muted": setting.is_muted ? 1 : 0,
      "show_layer_two": setting.show_layer_two ? 1 : 0,
      "show_layer_one": setting.show_layer_one ? 1 : 0,
      "show_layer_three": setting.show_layer_three ? 1 : 0,
    };

    for (var key in data) {
      formData.append(key, data[key]);
    }

    if (rssTypes?.includes(item.type))
      updateRssSettings(formData)


    if (window.location.pathname.includes("playlist")) {
      var url = `/playlist/media/setting`;
      formData.append("playlist_media_id", item.playlist_media_id);
    } else {
      var url = `/campaign/media/${item.campaign_playlist_media_id}/setting`;
    }

    axiosInstance.post(url, formData, { timeout: 1000, })
      .then((result) => {
        dispatch(setDisableBtn(false))
        showToast('success', 'Media Setting', result?.data?.data?.msg);
        dispatch(setRerenderList(true))
        setMediaSettingPopup(false)
      }).catch((error) => {
        dispatch(setDisableBtn(false));
        showToast('error', 'Media Setting', error?.response?.data?.message);
      });
  }

  const updateRssSettings = (formData) => {
    let rss_settings = {
      play_video_full_length: item?.rss_settings?.play_video_full_length ?? false,
      display_video_content: item?.rss_settings?.display_video_content ?? false,
      image_fullscreen: item?.rss_settings?.image_fullscreen ?? false,
      original_layout: item?.rss_settings?.original_layout ?? false,
      posts_per_page: item?.rss_settings?.posts_per_page ?? false,
      image_blending: item?.rss_settings?.image_blending ?? false,
      stretch_image: item?.rss_settings?.stretch_image ?? false,
      show_image: item?.rss_settings?.show_image ?? false,
      mute_video: item?.rss_settings?.mute_video ?? false,
      text_color: rssSettings?.textColor,
      direction: rssSettings?.direction == "left" ? "ltr" : "rtl",
      duration: item?.rss_settings?.duration ?? 20,
      position: rssSettings?.position,
      bg_color: rssSettings?.bgColor,
      design: item?.rss_settings?.design ?? "original",
      speed: rssSettings?.speed,
    };

    if (!isEmpty(rssSettings?.newSeparator))
      formData.append("separator", rssSettings?.newSeparator);

    for (var key in rss_settings) {
      formData.append(`rss_settings[${key}]`, rss_settings[key]);
    }

  }

  const updateName = () => {
    if (item.file_name != name) {
      axiosInstance.post(`/media/${item.media_id}/update`, { "name": name, })
        .then((result) => {
          dispatch(setRerenderList(true))
          showToast('success', 'Update Media Name', "Media name updated successfully!");
        }).catch((error) => {
          if (error?.response?.data?.errors)
            Object.values(error?.response?.data?.errors).forEach(error => {
              showToast('error', 'Update Media Name', error[0]);
            });
        });
    }
  }

  //Dialog Header 
  const header = (name) => {
    return (
      <>
        {
          editName ? (
            <div className='d-flex align-items-center ' >
              <InputText defaultValue={name} onChange={(e) => setName(e.target.value)} />
              <i className="fs-6 fas fa-times-circle text-light mx-2" onClick={() => {
                updateName();
                setEditName(false);
              }}></i>
            </div>
          )
            : (
              <h4 className='m-0 fw-bold align-items-center '>
                {name}
                <i className="pi pi-pencil-alt fs-6 text-light mx-2" onClick={() => { setEditName(true) }}></i>
              </h4>
            )
        }
      </>
    )
  }

  return (
    <>
      <Dialog visible={mediaSettingPopup}
        onHide={() => { setMediaSettingPopup(false) }}
        breakpoints={{ '960px': '95vw' }}
        style={{ width: rssTypes?.includes(item.type) ? "55vw" : '50vw', minHeight: '300px' }}
        header={header(item.file_name)} >

        <div className="d-flex">
          <div className="col-6 col-lg-8 border-end d-flex  flex-column justify-content-center align-content-center flex-wrap p-3 hight">
            <div className={`col-12 d-flex flex-column   ${item?.rss_settings?.position == "BOTTOM" ? "justify-content-end border-2 border-dark" : ""}`} style={{ minHeight: "300px" }}>
              <Media data={item} />
            </div>
          </div>
          <div className="col-6 col-lg-4  px-0 d-flex flex-column justify-content-between">
            <div className="px-2 mt-3">
              <h6 className='text-center text-black fw-bold'>Media Settings</h6>
              <div className="d-flex justify-content-between mb-2">
                <span className='fs-8'> Show video screen</span>
                <InputSwitch checked={setting.show_video_screen} onChange={(e) => setSetting({ ...setting, "show_video_screen": e.value })} />
              </div>
              {
                item.section != "LINKS" &&
                <div className="d-flex justify-content-between mb-2">
                  <span className='fs-8'>Downloadable</span>
                  <InputSwitch checked={setting.downloadable} onChange={(e) => setSetting({ ...setting, "downloadable": e.value })} />
                </div>
              }
              <div className="d-flex justify-content-between mb-2">
                <span className='fs-8'>Showed</span>
                <InputSwitch checked={setting.is_showed} onChange={(e) => setSetting({ ...setting, "is_showed": e.value })} />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span className='fs-8'>Mute</span>
                <InputSwitch checked={setting.is_muted} onChange={(e) => setSetting({ ...setting, "is_muted": e.value })} />
              </div>
            </div>
            {
              rssTypes?.includes(item.type) ?
                <div className="px-2 mt-3 border-top pt-3">
                  <h6 className='text-center text-black fw-bold mb-2'>RSS Settings</h6>
                  < RssSettings classes={_classes} item={item} />
                </div>
                :
                <></>
            }

            {
              layer == "MAIN" ?
                <div className="px-2 border-top py-3" >
                  <h6 className='text-center text-black fw-bold'>Play Settings</h6>

                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Show playground layer </span>
                    <InputSwitch checked={setting.show_layer_one} onChange={(e) => setSetting({ ...setting, "show_layer_one": e.value })} />
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Show corner layer</span>
                    <InputSwitch checked={setting.show_layer_two} onChange={(e) => setSetting({ ...setting, "show_layer_two": e.value })} />
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span className='fs-8'>Show banner layer</span>
                    <InputSwitch inputId=' `${ item.id }-layer-three` }' checked={setting.show_layer_three} onChange={(e) => setSetting({ ...setting, "show_layer_three": e.value })} />
                  </div>
                </div>
                :
                ""
            }
            <div className='border-top px-2 py-3 d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-center align-items-center'>
                {
                  item.downloadable == 1 ?
                    <a href={item.path} >
                      <i className="fas fa-download me-2 cursor-pointer text-light fs-5" ></i>
                    </a>
                    :
                    ""
                }
                <i className="bx bx-trash text-light cursor-pointer fs-5" onClick={removeMedia} ></i>
              </div>
              <Button label={disableBtn ? <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" /> : "Update"}
                className='btn btn-primary fs-8 py-1 px-2'
                onClick={updateSetting} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default MediaSettingPopup