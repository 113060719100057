import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';

const _tableKeys = [
    "3d",
    "rss_feed",
    "Add users",
    "ai_access",
    "media_type",
    "prayer_app",
    "multiple_layer",
    "content_preview",
    "music_list_access",
    "playlist_capacity",
    "interactive_action",
    "full_player_offline",
    "live_meeting_access",
    "published_as_grouping",
    "media_storage_capacity",
    "advance_content_schedule",
    "upload_once_content_capacity"
]

const Pricing = () => {
    const packages = useSelector((state) => state.global.packages);

    const [selectedPeriod, setSelectedPeriod] = useState("one_month");
    const [tableKeys, setTableKeys] = useState(_tableKeys);
    const navigate = useNavigate();

    const onPeriodChange = (period) => {
        setSelectedPeriod(period)
    }

    const periodOptions = [
        { label: "Monthly ", value: "one_month" },
        { label: "Every Six Months", value: "six_months" },
        { label: "Yearly", value: "one_year" },
    ]

    function explodeArray(arr) {
        if (!Array.isArray(arr)) {
            return arr;
        }

        if (arr.length === 0) {
            return "";
        }

        const explodedString = arr.slice(0, -1).join(", ");
        return explodedString + (arr.length > 1 ? `, ${arr[arr.length - 1]}` : "");
    }

    return (
        <>
            <div className="bg-light sec9-margin" id="pricing">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12 sec9 text-center mt-3">
                            <span className="d-block mt-4 fs-2">Pricing</span>
                            <h2 className="mt-4">Choose The Right Plan For Your Business!</h2>
                        </div>
                    </div>
                    <section className='content'>

                        <div className="container">
                            <div className="row-fluid clearfix">
                                <div className="pricing-wrapper comparison-table clearfix style-3">
                                    <div className="col-md-3 pricing-col list-feature">
                                        <div className="pricing-card">
                                            <div className="pricing-header d-flex justify-content-center align-items-center w-100">
                                                <Dropdown
                                                    value={selectedPeriod}
                                                    options={periodOptions}
                                                    onChange={(e) => onPeriodChange(e.value)}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className='w-75'
                                                />
                                            </div>
                                            <div className="pricing-feature">

                                                {
                                                    tableKeys?.map(item => {
                                                        return (
                                                            <li className={`${item == "media_type" ? "media_type_li" : ""}`}>
                                                                <p>{item.replace(/_/g, " ")}</p>
                                                            </li>
                                                        )
                                                    })

                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex col-9 flex-nowrap pricing_scroll h_scroll_div"  >
                                        {
                                            packages?.map((item, index) => {
                                                let foundItem = item.prices.find(item => item.period === selectedPeriod.toUpperCase())
                                                return <>
                                                    <div className="col-md-4 pricing-col person" key={index}>
                                                        <div className="pricing-card">
                                                            <div className="bg_main_color pricing-header ">
                                                                <div className="price-box">
                                                                    <h5 className='bg_main_color text-capitalize text-white'>{item.title}</h5>
                                                                    <div className="price text-white"> {foundItem.price}
                                                                        <div className="currency">$</div>
                                                                        <div className="plan text-bolder text-white text-nowrap"> / {selectedPeriod == "one_month" ? "Month" : selectedPeriod == "six_months" ? "6 Months" : "Year"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="pricing-feature">
                                                                {
                                                                    Object?.entries(item.features).map((feature, index) => {
                                                                        return <>
                                                                            <li key={index}>
                                                                                <p className=' fw-normal'>
                                                                                    {
                                                                                        Array.isArray(feature[1]) ?
                                                                                            explodeArray(feature[1])
                                                                                            : feature[1] === true ? <i className="fa-regular fa-circle-check fs-6 text-success" ></i>
                                                                                                : feature[1] === false ? <i className="fa-regular fa-circle-xmark fs-6 text-danger"></i>
                                                                                                    : feature[1] >= 10000 ? "Unlimited"
                                                                                                        : feature[0].includes("capacity") ? feature[1] + " GB"
                                                                                                            : feature[1]
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        </>
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="pricing-footer">
                                                                <button onClick={() => navigate("/signup")} className="btn btn-act rounded btn-line mt-4">
                                                                    <span>Start Today</span>
                                                                    <i className="fa fa-arrow-right"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>


                    </section>
                </div>
            </div>
        </>)
}

export default Pricing