import React, { useEffect, useRef, useState } from 'react'

import { IoNewspaperOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import "../css/apps.css"


import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatDuration } from '../../../config/global';
import { useDataTableContext } from '../../Contexts/DataTableContext';
import { useAppsContext } from '../../Contexts/AppsContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import CreateCustomRssDialog from './CreateCustomRssDialog';
import Layout from '../../Layout/Layout';
import AddFeedDialog from './AddFeedDialog';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function CustomRssIndex() {
  useTitle("Custom RSS")

  const openDialog = useSelector((state) => state.global.openDialog);
  const dispatch = useDispatch()

  const { setIsCreation, setSelectedRss, isLoading } = useAppsContext();
  const { showToast } = useCustomToast();
  const {
    totalRecords, setReload,
    lazyParams, setLazyParams,
    onPage, onSort, onFilter,
    loading,
    data,
  } = useDataTableContext();


  useEffect(() => {
    setLazyParams(prev => ({
      ...prev,
      modelName: "media",
      filters: {
        'file_name': { value: '', matchMode: 'contains' },
        'type': { value: 'RSS_CUSTOM', matchMode: 'equals' },
      }
    }))
  }, [])

  // Data Table Body Template
  const actionBodyTemplate = (rowData) => {
    return (
      <div className=" d-flex justify-content-center">
        {/* Add Feeds RSS */}
        <Tooltip target={`.add-feed-button-${rowData.id}`} showDelay={100} className="fs-8" content="Add feeds" position='bottom' />
        <button className={`btn btn-sm btn-icon add-feed-button-${rowData.id} mx-2`} onClick={() => addFeedHandler(rowData)} >
          <i className="fas fa-photo-video fs-6 fw-bolder"></i>
        </button>

        {/* Update RSS */}
        <Tooltip target={`.update-button-${rowData.id}`} showDelay={100} className="fs-8" content="Update" position='bottom' />
        <button className={`btn btn-sm btn-icon update-button-${rowData.id}`} onClick={() => editRss(rowData)}>
          <i className="pi pi-pencil"></i>
        </button>

        {/* Delete RSS */}
        <Tooltip target={`.delete-button-${rowData.id}`} showDelay={100} content="Delete" position='bottom' className="fs-8" />
        <button className={`btn btn-sm btn-icon delete-button-${rowData.id}`} onClick={() => confirmDeleteUser(rowData)} >
          <i className="bx bx-trash"></i>
        </button>

      </div>
    );
  }

  const durationBodyTemplate = (rowData) => {
    return <>
      {formatDuration(rowData.duration)}
    </>
  }

  const editRss = (data) => {
    setSelectedRss(data)
    setIsCreation(false)
    dispatch(toggleDialog("customRssCreation"))
  }

  const confirmDeleteUser = (data) => {
    axiosInstance.delete(`/media/${data.id}`)
      .then((result) => {
        setReload(true)
        showToast('success', 'Delete Media', result?.data?.data?.msg);
      })
      .catch((error) => {
        showToast('error', 'Delete Media', error?.response?.data?.msg);
      });
  }

  const addFeedHandler = (rowData) => {
    setSelectedRss(rowData)
    dispatch(toggleDialog("addCustomFeed"))
  }

  return (
    <Layout>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title m-0 me-2 d-flex align-items-center">
            <span className="avatar-initial rounded bg-label-primary p-2 me-2">
              <IoNewspaperOutline className='fs-2' />
            </span>
            <span className='ms-2'>Custom RSS List</span>
          </h5>
          <div className=' d-flex align-items-center  mb-4'>
            <button className="btn btn-primary mx-1" disabled={isLoading ? true : false} onClick={() => window.history.go(-1)} > Back</button>
            <button className="btn btn-primary mx-1" disabled={isLoading ? true : false} onClick={() => dispatch(toggleDialog("customRssCreation"))} > Create</button>
          </div>
        </div>

        <div className="col-12 d-flex flex-column  ">
          {
            isLoading ?
              <div className='d-flex justify-content-center align-items-center my-5'>
                <Spinner variant="primary" as="span" animation="border" role="status" className='mx-3' aria-hidden="true" />
              </div>
              :
              <>
                <div className='table-responsive text-nowrap'>
                  <DataTable value={data.media} lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                    paginator first={lazyParams.first} rows={lazyParams.rows} rowsPerPageOptions={[15, 25, 50, 100]}
                    totalRecords={totalRecords} onPage={onPage}
                    onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                    onFilter={onFilter} filters={lazyParams.filters} loading={loading} className="table"
                  >
                    <Column field="file_name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                    <Column field="duration" className='text-center' header="Total Duration" body={durationBodyTemplate} />
                    <Column field="created_at" header="Created Date" className='text-center' sortable />
                    <Column body={actionBodyTemplate} exportable={false} ></Column>
                  </DataTable>
                </div>
              </>
          }
        </div>
      </div>

      {openDialog.customRssCreation ? <CreateCustomRssDialog /> : <></>}
      {openDialog.addCustomFeed ? <AddFeedDialog /> : <></>}

    </Layout >
  )

}

export default CustomRssIndex