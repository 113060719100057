import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Tooltip } from 'primereact/tooltip'
import { Spinner } from 'react-bootstrap'

import { useAppsContext } from '../../../Contexts/AppsContext'
import { useCustomToast } from '../../../Contexts/ToastContext'
import axiosInstance from '../../../../config/Axios'

import { useSelector, useDispatch } from 'react-redux';
import {setIsPrayerCampaign } from '../../../../redux/Slices/Global/globalSlice';

function CampaignCard ({ item }) {
    const { showToast } = useCustomToast();

    const { setIsLoading, toast } = useAppsContext();
    const { isPrayerCampaign, layer } = useSelector((state) => state.global);
    const dispatch = useDispatch()

    const [deletePrecessing, setDeletePrecessing] = useState(false);
    const navigate = useNavigate();

    const deleteCampaign = () => {
        setDeletePrecessing(true);
        deleteHandle();
    }

    const deleteHandle = () => {
        axiosInstance.delete(`/campaign/${ item.id }`)
            .then((result) => {
                setDeletePrecessing(false);
                setIsLoading(true)
                showToast('success', 'Delete Campaign', result?.data?.data?.msg);
            })
            .catch((error) => {
                showToast('error', 'Delete Campaign', error?.response?.data?.msg);
            });
    }

    const goToSetup = () => {
        dispatch(setIsPrayerCampaign(true))
        sessionStorage.setItem('is_default_prayer', item.name == "default" ? JSON.stringify(true) : JSON.stringify(false));
        navigate(`/campaigns/${ item.id }/setup`)
    }

    return (
        <div className="card my-2">
            <div className="card-body d-flex align-items-center">
                <div className="col-1 text-center">
                    <div className=' me-3 p-3 rounded-2 bg-secondary d-flex align-items-center justify-content-center'>
                        <i className="fas fa-bullhorn fs-3 text-white mx-auto"></i>
                    </div>
                </div>
                <div className="col-8 text-capitalize fs-5">{ item.name }</div>
                <div className="col-3 text-end fs-5">

                    {/* Delete Button  */ }
                    {
                        !(item.name == "default") &&
                        <>
                            <Tooltip target={ `.delete-button-${ item.id }` } content="Delete" position='bottom' autoHide={ true }  className="fs-8" />
                            <button
                                className={ `btn bg-transparent p-0 mx-1 delete-button-${ item.id }` }
                                onClick={ () => deleteCampaign() }
                                disabled={ deletePrecessing ? true : false }
                            >
                                {
                                    deletePrecessing ?
                                        <Spinner variant="primary" as="span" animation="border" role="status" className='mx-3' aria-hidden="true" />
                                        :
                                        <i className="bx bx-trash text-light"></i>
                                }
                            </button>
                        </>
                    }

                    {/* Setup Button  */ }
                    <Tooltip target={ `.setup-button-${ item.id }` } content="Setup" position='bottom' autoHide={ true }  className="fs-8" />
                    <button className={ `btn bg-transparent p-0 mx-1 setup-button-${ item.id }` } onClick={ () => goToSetup() } >
                        <i className="bx bx-slideshow text-light"></i>
                    </button>

                </div>
            </div>
        </div>
    )
}

export default CampaignCard