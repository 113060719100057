import React, { useEffect } from 'react'
import { isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedApp } from '../../../../redux/Slices/Global/globalSlice';
import { use } from 'react';

function BreadCrumb() {
    const dispatch = useDispatch();
    const selectedApp = useSelector((state) => state.global.selectedApp);

    useEffect(()=>{
        console.log(selectedApp);
        
    },[])
    return (
        <div className='col-12 p-3 d-flex border-bottom'>
            <span className={`d-flex align-items-center ${isEmpty(selectedApp) ? "fw-bold" : "text-light "}  cursor-pointer`}
                onClick={() => dispatch(setSelectedApp({}))} >
                Apps
            </span>
            {
                !isEmpty(selectedApp) ?
                    <span className='d-flex align-items-center ms-2 fw-bold ' >
                        <i className="fas fa-angle-right me-2"></i>
                        {selectedApp?.title}
                    </span>
                    :
                    <></>
            }
        </div>
    )
}

export default BreadCrumb