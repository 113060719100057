import React from 'react';
import { useForm, Controller } from "react-hook-form";

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Tooltip } from 'primereact/tooltip';

import { getFormErrorMessage } from '../../../config/global';
import axiosInstance from '../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setRerender, setDeviceFilters } from '../../../redux/Slices/deviceSlice';
import _ from 'lodash';

function Filter() {
    const dispatch = useDispatch();

    const { formState: { errors }, handleSubmit, control, reset } = useForm();
    const deviceFilters = useSelector((state) => state.device.deviceFilters);
    const disableBtn = useSelector((state) => state.global.disableBtn);

    const options = [
        { name: 'On-line', code: 1 },
        { name: 'Off-line', code: 0 },
    ];

    const onSubmit = (data) => {
        dispatch(setDeviceFilters({
            status: data.status,
            name: data.name,
            isOnline: data.is_online
        }))
        dispatch(setRerender(true))
    };

    const clearFilter = () => {
        reset()
        dispatch(setDeviceFilters({ isOnline: null }))
        dispatch(setRerender(true))

    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex align-items-center justify-content-start">

                {/* <div className="col-5 d-flex flex-column align-items-start me-1">
                    <InputText
                        { ...register("name", { required: false }) }
                        className={ `w-100   ${ errors.name ? 'form-control is-invalid' : 'form-control' }` }
                        aria-invalid={ errors.name ? "true" : "false" }
                        placeholder="Device name"
                    />
                    { errors.name && <p className='fv-plugins-message-container invalid-feedback text-start'>{ errors.name?.message }</p> }
                </div> */}


                {/* is_online input */}
                <div className="col-5 me-1 d-flex flex-column align-items-start ">
                    <Controller name="is_online" control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState }) =>
                        (
                            <Dropdown id={field.name} {...field}
                                onChange={(e) => field.onChange(e.value)}
                                options={options}
                                optionLabel="name"
                                optionValue="code"
                                inputRef={field.ref}
                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                placeholder="On-Line / Off-Line"
                            />
                        )
                        } />
                    {getFormErrorMessage('is_online', errors)}
                </div>

                {/* Status input */}
                {/* <div className="col-5 me-1 d-flex flex-column align-items-start ">
                    <Controller name="status" control={ control }
                        rules={ { required: false } }
                        render={ ({ field, fieldState }) =>
                        (
                            <Dropdown id={ field.name } { ...field }
                                onChange={ (e) => field.onChange(e.value) }
                                options={ status }
                                optionLabel="name"
                                optionValue="code"
                                inputRef={ field.ref }
                                className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                placeholder="Status"
                            />
                        )
                        } />
                    { getFormErrorMessage('status', errors) }
                </div> */}

                <div className="col-2 d-flex">
                    <button type="submit" className="btn btn-primary me-1 py-2 d-flex align-items-center" onClick={() => onSubmit} disabled={disableBtn ? true : false}>
                        {
                            disableBtn ? <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" /> : "Filter"
                        }
                    </button>
                    {
                        !_.isNull(deviceFilters.isOnline) ?
                            <>
                                <Tooltip target=".clear-button" className="fs-8" />

                                <button className="btn btn-label-secondary py-2 me-1 clear-button"
                                    data-pr-position="bottom"
                                    data-pr-tooltip="Clear Filter"
                                    onClick={() => clearFilter()} disabled={disableBtn ? true : false}
                                >
                                    <i className="fas fa-times"></i>
                                </button>
                            </>
                            : <></>

                    }
                </div>
            </form>
        </>
    )
}

export default Filter