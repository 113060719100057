import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Spinner } from "react-bootstrap";
import { Dialog } from "primereact/dialog";

import { getFormErrorMessage } from "../../../config/global";
import { useCustomToast } from "../../Contexts/ToastContext";
import axiosInstance from "../../../config/Axios";

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, setOpenDialog, setRerenderList } from '../../../redux/Slices/Global/globalSlice';

function EditInteractiveMediaDialog() {
    const { formState: { errors }, control, handleSubmit, reset, } = useForm();
    const dispatch = useDispatch();
    const { openDialog, disableBtn, selectedMediaInfo } = useSelector((state) => state.global);

    const { showToast } = useCustomToast();

    useEffect(() => {
        reset({
            title: selectedMediaInfo.title,
            thumb: selectedMediaInfo.thumb,
        })
    }, [reset])

    const onSubmit = (data) => {
        dispatch(setDisableBtn(true));
        axiosInstance.put(`/media/interactive/${selectedMediaInfo.media_id}/update`, data)
            .then((result) => {
                closeDialog()
                dispatch(setRerenderList(true));
                showToast('success', 'Media Editing', "Media updated successfully!");
            })
            .catch((error) => {
            });
        dispatch(setDisableBtn(false));

    };

    const closeDialog = () => {
        dispatch(setOpenDialog(prev => ({ ...prev, ["editInteractiveMedia"]: false, })))
    }

    return (
        <>
            <Dialog visible={openDialog.editInteractiveMedia}
                onHide={() => { closeDialog() }}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '50vw', minHeight: '300px' }}
                header="Edit Interactive Media"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className=" px-5 d-flex flex-column align-items-center justify-content-center my-5">
                        <div className="field mb-4 w-100">
                            <label className="form-label"> title </label>
                            <span className="p-float-label">
                                <Controller name="title" control={control}
                                    rules={{
                                        required: false,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">
                                                <i className="fas fa-align-justify"></i>
                                            </span>
                                            <InputText
                                                placeholder="title"
                                                id={field.name}
                                                {...field}
                                                className={`w-100  ${classNames({ "p-invalid": fieldState.invalid, })}`} />
                                        </div>
                                    )}
                                />
                            </span>
                            {getFormErrorMessage("title", errors)}
                        </div>

                        <div className="field w-100">
                            <label className="form-label"> Thumb </label>
                            <span className="p-float-label">
                                <Controller name="thumb" control={control}
                                    rules={{
                                        required: false,
                                        pattern: {
                                            value:
                                                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i,
                                            message: "Invalid Url!",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"> <i className="fas fa-link"></i> </span>
                                            <InputText
                                                placeholder="thumb"
                                                id={field.name}
                                                {...field}
                                                className={`w-100  ${classNames({ "p-invalid": fieldState.invalid, })}`}
                                            />
                                        </div>
                                    )}
                                />
                            </span>
                            {getFormErrorMessage("thumb", errors)}
                        </div>
                    </div>

                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close" disabled={disableBtn ? true : false} onClick={closeDialog} >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary me-3" disabled={disableBtn ? true : false} >
                            {disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className="mx-3" aria-hidden="true" />
                                :
                                "Update"
                            }
                        </button>
                    </div>
                </form>
            </Dialog>
        </>
    );
}

export default EditInteractiveMediaDialog