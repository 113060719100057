import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // the state from the original file
  selectedLayout: {
    id: "",
    title: "",
    screens_setting: [],
  },
  defaultTemplate: [],
  userTemplate: [],
  selectedTemplate: {
    id: "",
    title: "",
    screens_setting: [],
  },

  screenDetails: { total_duration: 0 },
  screens_setting: [],
  reloadTrigger: false,
  screenMedia: "",
  isPublished: "",
};

//the actions
const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSelectedLayout(state, action) {
      state.selectedLayout = action.payload;
    },
    updateSelectedLayoutTitle(state, action) {
      state.selectedLayout.title = action.payload;
    },
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action.payload;
    },
    setDefaultTemplate(state, action) {
      state.defaultTemplate = action.payload;
    },
    setUserTemplate(state, action) {
      state.userTemplate = action.payload;
    },
    addToUserTemplate(state, action) {
      const newTemplate = {
        id: action.payload.id,
        title: action.payload.title,
        screens_setting: action.payload.screens_setting,
      };
      state.userTemplate.push(newTemplate);
    },

    setScreenDetails(state, action) {
      state.screenDetails = action.payload;
    },
    setScreenMedia(state, action) {
      state.screenMedia = action.payload;
    },
    setScreens_setting(state, action) {
      state.screens_setting = action.payload;
    },
    setReloadTrigger(state, action) {
      state.reloadTrigger = action.payload;
    },
    setIsPublished(state, action) {
      state.isPublished = action.payload;
    },
  },
});

export const {
  setSelectedLayout,
  updateSelectedLayoutTitle,
  setScreenMedia,
  setSelectedTemplate,
  setDefaultTemplate,
  setUserTemplate,
  addToUserTemplate,
  setScreenDetails,
  setScreens_setting,
  setReloadTrigger,
  setIsPublished,
} = layoutSlice.actions;

export default layoutSlice.reducer;
