import React, { useState, useEffect, useRef } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import DevicesList from './assignPopup/devices/DevicesList';
import GroupsList from './assignPopup/groups/GroupsList';

import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function AssignPopup({ campaignId }) {
    const dispatch = useDispatch()
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [lastElement, setLastElement] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    const [loading, setLoading] = useState(true);
    const [listLoading, setListLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);

    const [ids, setIds] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("groups");

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                setPageNum((no) => no + 1);
            }
        })
    );

    //Get data 
    const callMedia = async () => {
        if (pageNum == 1) {
            setLoading(true);
        } else {
            setListLoading(true)
            setShowLoading(true)
        }

        let response;
        if (selectedCategory == "groups") {
            response = await axiosInstance.get(`/groups?page=${pageNum}&per_page=7`);
        } else {
            response = await axiosInstance.get(`/company/devices?page=${pageNum}&per_page=7`);
        }

        if (pageNum == 1) {
            setData([...response.data.data]);
        } else {
            let all = new Set([...data, ...response?.data?.data]);
            setData([...all]);
            setListLoading(false)
        }
        setLastPage(response.data.pagination.total_pages);
        setLoading(false);
    };

    // fetch data if not last page (pagination)
    useEffect(() => {
        if (pageNum <= lastPage || lastPage == null) {
            callMedia();
        }
    }, [pageNum, selectedCategory]);


    useEffect(() => {
        setData([]);
        setPageNum(1);
    }, [selectedCategory]);

    useEffect(() => {
        if (isUploaded) {
            setPageNum(1, () => callMedia());
            setIsUploaded(false);
        }
    }, [isUploaded]);

    //Scroll observer
    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    // Render Dialog Footer
    const renderFooter = () => {
        return (
            <div>
                <Button
                    label={disableBtn ?
                        <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        : "Assign"
                    }
                    className='btn fs-6 py-2 px-3'
                    disabled={disableBtn || ids.length == 0 ? true : false}
                    onClick={assignToDevice} />
            </div>
        );
    }

    // Assign handler
    const assignToDevice = () => {
        dispatch(setDisableBtn(true));
        let data = { "campaign_id": campaignId };

        if (selectedCategory == "devices") {
            data = { ...data, devices_ids: ids, type: "devices" }
        } else {
            data = { ...data, groups_ids: ids, type: "groups" }
        }

        axiosInstance.post("/campaign/assign/devices", data)
            .then((result) => {
                let rejected_satellite = result?.data?.data?.rejected?.satellite;
                let rejected_HDMI = result?.data?.data?.rejected?.hdmi;
                var errorsArr = [];

                if (rejected_HDMI.length == 0 && rejected_satellite.length == 0) {
                    showToast('success', 'Assign Campaign to Devices', result?.data?.data?.msg);
                    dispatch(toggleDialog("assignDeviceGroup"));
                }

                if (rejected_HDMI.length != 0) {
                    errorsArr.push(generateMessage(rejected_HDMI, "HDMI"));
                }

                if (rejected_satellite.length != 0) {
                    errorsArr.push(generateMessage(rejected_satellite, "satellite"));
                }

                errorsArr.map((error) => { showToast(error.severity, error.summary, error.detail) })
                setIds([]);
            })
        dispatch(setDisableBtn(false))
    }

    // Generate error message
    const generateMessage = (arr, type) => {
        let msg = `${arr.map((name) => '"' + name + '" ')} ${arr.length > 1 ? "don't" : "doesn't"}  support ${type}!`;
        return { severity: 'error', summary: 'Assign Campaign to Devices', detail: msg, life: 3000 }

    }

    return (
        <>
            <Dialog header="Assign To Device" visible={openDialog.assignDeviceGroup}
                onHide={() => dispatch(toggleDialog("assignDeviceGroup"))}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '40vw' }} footer={renderFooter}>

                <div className="d-flex device_assign_container border-top border-bottom">
                    <div className="col-2 d-flex flex-col text-capitalize border-end ">
                        <span className={`px-3 py-2 cursor-pointer ${selectedCategory == "groups" ? "active_category" : ""}`} onClick={() => setSelectedCategory("groups")}>groups</span>
                        <span className={`px-3 py-2 cursor-pointer ${selectedCategory == "devices" ? "active_category" : ""}`} onClick={() => setSelectedCategory("devices")} >devices</span>
                    </div>
                    <div className="col-10 d-flex flex-column my-3 ">
                        <div className="d-flex flex-wrap justify-content-center scroll_container scroll_div px-2">
                            {loading ?
                                <>
                                    <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='m-3' aria-hidden="true" />
                                </>
                                :
                                <>
                                    {
                                        selectedCategory == "devices" ?
                                            <DevicesList
                                                loading={loading}
                                                data={data}
                                                ids={ids}
                                                setIds={setIds}
                                                setLastElement={setLastElement}
                                                pageNum={pageNum}
                                                lastPage={lastPage}
                                                listLoading={listLoading}
                                            />
                                            :
                                            <GroupsList
                                                loading={loading}
                                                data={data}
                                                ids={ids}
                                                setIds={setIds}
                                                setLastElement={setLastElement}
                                                pageNum={pageNum}
                                                lastPage={lastPage} />
                                    }
                                </>
                            }

                        </div>
                    </div>

                    {showLoading && <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />}
                </div>
            </Dialog>
        </>
    );
}

export default AssignPopup