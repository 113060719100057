import React from 'react';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function CreateFolderPopup({ setRerender, setCurrentFolder, setBreadCrumb, breadCrumb }) {
    const dispatch = useDispatch();
    const { formState: { errors }, handleSubmit, register, reset } = useForm({ name: "" });
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    const { showToast } = useCustomToast();
    const { id } = useParams();
    const navigate = useNavigate();


    const onSubmit = (data) => {
        dispatch(setDisableBtn(true));
        data.parent_folder_id = id;

        axiosInstance.post(`/folder/create`, data)
            .then((result) => {
                reset();
                dispatch(setDisableBtn(false));
                setRerender(true);
                dispatch(toggleDialog("createFolder"));
                showToast('success', 'Folder Creation', 'Folder Created Successfully');

                const folderData = result.data.data;

                navigate(`/media/gallery/${folderData.id}`);

                setCurrentFolder({
                    id: folderData.id,
                    name: folderData.folder_name,
                });

                setBreadCrumb((current) => [
                    ...current,
                    { label: folderData.folder_name, id: folderData.id }
                ]);

                localStorage.setItem("bread_crumb", JSON.stringify([
                    ...breadCrumb,
                    { label: folderData.folder_name, id: folderData.id }
                ]));
                localStorage.setItem("folder_id", folderData.id);
            })
            .catch((error) => {
                dispatch(setDisableBtn(false));
                setRerender(false);
                showToast('error', 'Folder Creation', error?.response?.data?.message || 'Failed to create folder');
            });
    };
    
    return (
        <>
            <Button icon={<i className="fas fa-folder-plus text-secondary fs-3 p-1"></i>} tooltip="New folder"
                tooltipOptions={{ position: 'bottom', autoHide: false }} onClick={() => dispatch(toggleDialog("createFolder"))}
                className=" bg-transparent border-0 p-1 mx-1 text-primary" />

            <Dialog header="Folder Creation"
                visible={openDialog.createFolder}
                onHide={() => { dispatch(toggleDialog("createFolder")) }}
                breakpoints={{ '960px': '95vw' }}
                style={{ width: '30vw' }}>

                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="my-5 px-5">
                        <input
                            {...register("name", {
                                required: "Folder name is required!",
                                minLength: {
                                    value: 3, message: 'Length must be 3 or more',
                                },
                                pattern: {
                                    value: /^[^.].*$/,
                                    message: "Please Enter a valid name",
                                },
                            })}
                            aria-invalid={errors.name ? "true" : "false"}
                            className={errors.name ? 'form-control is-invalid' : 'form-control'}
                            placeholder="Folder Name" id='folder_name'
                        />
                        {errors.name && <p className='fv-plugins-message-container invalid-feedback'>{errors.name?.message}</p>}
                    </div>
                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={disableBtn ? true : false} onClick={() => dispatch(toggleDialog("createFolder"))}>Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={disableBtn ? true : false} >
                            {
                                disableBtn ?
                                    <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                    :
                                    "Create"
                            }
                        </button>
                    </div>
                </form>

            </Dialog>
        </>
    )
}

export default CreateFolderPopup