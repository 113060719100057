

import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";

import { useDataTableContext } from '../../Contexts/DataTableContext';
import { getFormErrorMessage } from '../../../config/global';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useAppsContext } from '../../Contexts/AppsContext';
import { BottomOption } from '../../SharedComponents/RSS/Positions/Bar';

import { ColorsInputs, PositionInput } from '../../SharedComponents/RSS/Inputs';
import { RssName } from '../../SharedComponents/RSS/RssName';
import RssPreview from '../../SharedComponents/RSS/RssPreview';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function AddRssDialog() {
    const {
        selectedRss, resetStates, isCreation,
        rssSettings, setRssSettings, rssSettingsHandler,
        setRssName, rssName, formDataHandler }
        = useAppsContext();
    const { openDialog, disableBtn } = useSelector((state) => state.global);
    const dispatch = useDispatch()

    const { showToast } = useCustomToast();
    const { setReload } = useDataTableContext();
    const {
        formState: { errors },
        handleSubmit,
        clearErrors,
        getValues,
        setError,
        control,
        watch,
        reset,
        setValue }
        = useForm({ shouldUnregister: true, mode: 'onChange' });

    const urlInput = watch('url');

    const [loading, setLoading] = useState(false);
    const [feeds, setFeeds] = useState([]);
    const [isBar, setIsBar] = useState(true);

    useEffect(() => {
        let _position = getValues()?.position;
        setIsBar(() => (_position == "BOTTOM" || _position == "TOP") ? true : false)
    }, [getValues()?.position])

    useEffect(() => {

        if (!isCreation) {
            setRssName(selectedRss?.file_name)
        }

        reset({
            play_video_full_length: isCreation ? false : selectedRss?.rss_settings?.play_video_full_length,
            display_video_content: isCreation ? false : selectedRss?.rss_settings?.display_video_content,
            image_fullscreen: isCreation ? false : selectedRss?.rss_settings?.image_fullscreen,
            original_layout: isCreation ? false : selectedRss?.rss_settings?.original_layout,
            posts_per_page: isCreation ? 1 : selectedRss?.rss_settings?.posts_per_page,
            image_blending: isCreation ? false : selectedRss?.rss_settings?.image_blending,
            stretch_image: isCreation ? false : selectedRss?.rss_settings?.stretch_image,
            show_image: isCreation ? false : selectedRss?.rss_settings?.show_image,
            mute_video: isCreation ? false : selectedRss?.rss_settings?.mute_video,
            direction: isCreation ? "left" : (selectedRss?.rss_settings?.direction === "rtl" ? "right" : "left"),
            duration: isCreation ? 20 : selectedRss?.rss_settings?.duration,
            position: isCreation ? "BOTTOM" : selectedRss?.rss_settings?.position,
            design: isCreation ? "original" : selectedRss?.rss_settings?.design,
            speed: isCreation ? 1 : selectedRss?.rss_settings?.speed,
            url: isCreation ? "" : selectedRss?.path,
        })

        return () => {
            resetStates()
        }
    }, [])


    const validateUrl = (url) => {
        const urlPattern = /^(www|http:|https:)+[^\s]+[\w]/;
        return urlPattern.test(url);
    };

    // useEffect to trigger an action when the url input changes
    useEffect(() => {
        const _url = selectedRss?.path ?? urlInput;

        if (validateUrl(_url)) {
            setFeeds([])
            setLoading(true)
            axiosInstance.post("/rss/read", { url: _url })
                .then((result) => {
                    if (result?.status === 204) {
                        showToast("error", "RSS Addition", result?.data?.data?.msg)
                        setLoading(false)
                    }
                    else {
                        setFeeds(result?.data?.posts)
                        setLoading(false)
                    }
                }).catch((error) => {
                    showToast("error", "RSS Addition", error?.response?.data?.msg)
                    setLoading(false)
                })
        }
    }, [urlInput, setValue]);

    const formHandler = (data) => {
        dispatch(setDisableBtn(true));
        let url = isCreation ? `/rss/add` : `/rss/${selectedRss?.id}/update`;

        const formData = new FormData();
        formData.append("url", data.url);
        formData.append("name", rssName);

        formDataHandler(formData, data);

        axiosInstance.post(url, formData)
            .then((result) => {
                dispatch(toggleDialog("rssFeedAddition"))
                showToast('success', "RSS Feed Handling", result?.data?.data?.msg);
                setReload(true)
                dispatch(setDisableBtn(false));
            }).catch((error) => {
                dispatch(setDisableBtn(false));
            })
    }

    return (
        <Dialog visible={openDialog.rssFeedAddition}
            style={{ width: '70%', overFlow: "hidden" }}
            breakpoints={{ '960px': '95vw' }}
            header={<RssName setError={setError} clearErrors={clearErrors} control={control} errors={errors} />}
            maximizable={true}
            blockScroll={true}
            modal className="p-fluid"
            onHide={() => dispatch(toggleDialog("rssFeedAddition"))}
        >
            <form onSubmit={handleSubmit(formHandler)} className="d-flex flex-column justify-content-center" encType="multipart/form-data" >
                <div className="col-12 d-flex ">
                    <div className="col-5 ">
                        <div className='d-flex flex-wrap px-4 justify-content-center py-3 border-end border-gray scroll_div rss_wrapper'>

                            {/* URL */}
                            <div className='mb-3 col-12'>
                                <div className="field ">
                                    <label className="form-label text-capitalize"> RSS Feed URL</label>
                                    <span className="p-float-label">
                                        <Controller name="url" control={control}
                                            rules={{
                                                required: 'RSS Feed URL is required.',
                                                validate: (value) => validateUrl(value) || 'Invalid URL format',
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    id={field.name}
                                                    {...field}
                                                    inputRef={field.ref}
                                                    className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                    placeholder="https://"
                                                />
                                            )} />
                                    </span>
                                    {getFormErrorMessage('url', errors)}
                                </div>
                            </div>

                            <PositionInput control={control} errors={errors} />

                            {
                                !isBar ?
                                    <>
                                        {/* Duration */}
                                        <div className='mb-3 mb-3 col-12'>
                                            <div className="field ">
                                                <label className="form-label text-capitalize"> Item Duration </label>
                                                <span className="p-float-label">
                                                    <Controller name="duration" control={control}
                                                        rules={{
                                                            required: 'Item Duration is required.',
                                                            min: {
                                                                value: 3,
                                                                message: "The minimum allowed duration is 3 seconds!"
                                                            }
                                                        }}
                                                        render={({ field, fieldState }) => (
                                                            <InputNumber
                                                                id={field.name}
                                                                {...field}
                                                                value={rssSettings?.duration}
                                                                onChange={(e) => { rssSettingsHandler("duration", e.value) }}
                                                                inputRef={field.ref}
                                                                className={` ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                                                mode="decimal"
                                                                min={1}
                                                                ref={field.ref}
                                                                showButtons
                                                                decrementButtonClassName="btn-primary"
                                                                incrementButtonClassName="btn-primary"
                                                            />
                                                        )} />
                                                </span>
                                                {getFormErrorMessage('duration', errors)}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                            <ColorsInputs />

                            {
                                isBar ?
                                    <BottomOption errors={errors} control={control} />
                                    :
                                    <>
                                        {/* <LayoutInputs control={ control } watch={ watch } />
                                        <VideoInputs control={ control } watch={ watch } /> */}
                                    </>
                            }
                        </div>
                    </div>

                    <div className={`col-7 auto-column my-5 px-3 `} >
                        <RssPreview feeds={feeds} loading={loading} />
                    </div>
                </div>

                <div className="col-12 text-center d-flex align-items-end justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" disabled={disableBtn}
                        data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(toggleDialog("rssFeedAddition"))}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary  me-1" disabled={disableBtn || rssSettings?.duration < 3} >
                        {
                            disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Save"
                        }
                    </button>
                </div>
            </form>

        </Dialog >
    )
}

export default AddRssDialog