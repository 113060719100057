import React, { useRef, useState } from 'react';
import { useForm } from "react-hook-form";

import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload';
import { isEmpty } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function UpdateMedia({ item, setRerender }) {
    const dispatch = useDispatch();
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    const { formState: { errors }, handleSubmit, register } = useForm({ item });
    const { showToast } = useCustomToast();
    const fileUploadRef = useRef();

    const [mediaName, setMediaName] = useState(item.file_name)
    const [thumb, setThumb] = useState({})

    const onSubmit = (data) => {
        dispatch(setDisableBtn(true))

        const formData = new FormData();
        formData.append("name", data?.name);

        if (!isEmpty(thumb))
            formData.append("thumb", thumb);

        axiosInstance.post(`/media/${item.id}/update`, formData)
            .then((result) => {
                dispatch(setDisableBtn(false))
                setRerender(true);
                dispatch(toggleDialog("updateMedia"));
                showToast('success', 'Media Updating ', 'Media Updated Successfully');
            }).catch((error) => {
                dispatch(setDisableBtn(false));
                showToast('error', 'Media Updating', error?.response?.data?.message);
            });
    };

    const chooseOptions = {
        className: 'upload_separator',
        label: 'click to upload image...',
        style: {
            background: 'transparent',
            color: 'gray',
            width: "100%",
            border: "2px dashed #D9DEE3",
            fontWeight: "normal",
            fontSize: "13px",
            padding: "10px",

        }
    };

    const onFileSelect = (event) => {
        const file = event.files && event.files[0];

        if (file) {
            setThumb(file)
        }
    };

    const onCancelUpload = () => {
        setThumb({})
        fileUploadRef.current.clear();
    };

    return (
        <Dialog header="Update Media"
            visible={openDialog.updateMedia}
            onHide={() => { dispatch(toggleDialog("updateMedia")) }}
            breakpoints={{ '960px': '95vw' }}
            style={{ width: '25vw' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-5 px-4">
                    <label htmlFor="" className='fs-6 mb-1'>Media name</label>
                    <input
                        {...register("name", { required: "Media name is required!" })}
                        aria-invalid={errors.name ? "true" : "false"}
                        className={errors.name ? 'form-control is-invalid fs-5' : 'form-control fs-5'}
                        placeholder="Media Name" value={mediaName} onChange={(e) => setMediaName(e.value)}
                    />
                    {errors.name && <p className='fv-plugins-message-container invalid-feedback'>{errors.name?.message}</p>}
                </div>

                {
                    item?.type == "3D" ?
                        <div className="my-5 px-4">
                            <label htmlFor="thumbnail" className={`mb-2 form-label text-capitalize `} >Thumbnail</label>
                            <FileUpload
                                mode="basic"
                                name="thumbnail"
                                accept="image/*"
                                maxFileSize={1000000}
                                customUpload
                                onSelect={onFileSelect}
                                ref={fileUploadRef}
                                onClick={onCancelUpload}
                                chooseOptions={chooseOptions}
                            />
                        </div>
                        :
                        <></>
                }

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3"
                        disabled={disableBtn ? true : false}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => dispatch(toggleDialog("updateMedia"))}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary me-3" disabled={disableBtn ? true : false} >Update</button>
                </div>
            </form>
        </Dialog>
    )
}

export default UpdateMedia