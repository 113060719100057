import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const AreaControls = ({
    areas,
    setAreas,
    setOverlappingAreas,
    isSaveDisabled,
    setIsSaveDisabled,
}) => {
    const { control, setValue, setError, clearErrors, formState: { errors } } = useForm();
    const [isDuplicatedNames, setIsDuplicatedNames] = useState(false);

    useEffect(() => {
        // Sync React Hook values with the area state
        areas.forEach((area, index) => {
            setValue(`areas[${index}].start_margin`, area.start_margin);
            setValue(`areas[${index}].top_margin`, area.top_margin);
            setValue(`areas[${index}].width`, area.width);
            setValue(`areas[${index}].height`, area.height);
            if (area.name) {
                setValue(`areas[${index}].name`, area.name || `Screen ${index + 1}`);
            }
        });
    }, [areas, setValue]);

    const handleSmartInputChange = (id, field, value) => {
        setAreas((prevAreas) =>
            prevAreas.map((area) => {
                if (area.id === id) {
                    let updatedValue = parseFloat(value) || 0;
                    updatedValue = Math.min(100, Math.max(0, updatedValue));

                    if (field === "start_margin") {
                        const newWidth = Math.max(0, area.start_margin + area.width - updatedValue);
                        return {
                            ...area,
                            start_margin: updatedValue,
                            width: Math.min(100, newWidth)
                        };
                    } else if (field === "width") {
                        const newStartMargin = Math.min(100 - updatedValue, area.start_margin);
                        return {
                            ...area,
                            width: updatedValue,
                            start_margin: newStartMargin
                        };
                    } else if (field === "top_margin") {
                        const newHeight = Math.max(0, area.top_margin + area.height - updatedValue);
                        return {
                            ...area,
                            top_margin: updatedValue,
                            height: Math.min(100, newHeight)
                        };
                    } else if (field === "height") {
                        const newTopMargin = Math.min(100 - updatedValue, area.top_margin);
                        return {
                            ...area,
                            height: updatedValue,
                            top_margin: newTopMargin
                        };
                    }
                }
                return area;
            })
        );
    };

    const handleDeleteArea = (id) => {
        const updatedAreas = areas.filter((area) => area.id !== id);
        const resetIdsAreas = updatedAreas.map((area, index) => ({
            ...area,
            id: typeof area.id === 'string' ? area.id : index + 1,
        }));
        setAreas(resetIdsAreas);
    };

    const checkDuplicateNames = () => {
        const names = areas.map((area) => area.name);
        const duplicates = names.filter((name, index) => names.indexOf(name) !== index);
        if (duplicates.length > 0) {
            setError("duplicateNames", {
                type: "manual",
                message: `Duplicate names found: ${[...new Set(duplicates)].join(", ")}`,

            });
            return true

        } else {
            clearErrors("duplicateNames");
            return false
        }
    };

    const checkOverlap = () => {
        const overlappingAreas = [];
        for (let i = 0; i < areas.length; i++) {
            for (let j = i + 1; j < areas.length; j++) {
                const a = areas[i];
                const b = areas[j];
                const isOverlapping =
                    a.start_margin < b.start_margin + b.width &&
                    a.start_margin + a.width > b.start_margin &&
                    a.top_margin < b.top_margin + b.height &&
                    a.top_margin + a.height > b.top_margin;

                if (isOverlapping) {
                    overlappingAreas.push(i, j)
                }
            }
        }
        return Array.from(new Set(overlappingAreas));
    };

    useEffect(() => {
        const overlaps = checkOverlap();
        const duplicate = checkDuplicateNames();

        setIsDuplicatedNames(duplicate);
        setOverlappingAreas(overlaps);

        if (overlaps.length > 0) {
            // setIsSaveDisabled(overlaps.length > 0);

        } else if (areas.length < 1) {
            // setIsSaveDisabled(areas.length < 1);

        } else if (duplicate) {
            setIsSaveDisabled(true);
        } else {
            setIsSaveDisabled(false);
        }
    }, [areas]);

    return (
        <div className="overflow-auto max-h-[330px]">
            {errors.duplicateNames && (
                <div className="text-red-500 text-sm mt-2">{errors.duplicateNames.message}</div>
            )}
            {areas.map((area, index) => (
                <div
                    key={area.id}
                    className="items-center gap-3 mb-3 bg-gray-50 p-3 rounded-lg border border-gray-300"
                >
                    <div className="flex items-center gap-3">
                        <div className="flex items-center text-sm font-medium mb-2 gap-1 ">
                            {index + 1}
                        </div>
                        {/* Editable Screen Name */}
                        <div className="flex items-center  gap-1 w-32">
                            <fieldset className="border border-gray-300 rounded flex flex-col items-center  relative">
                                <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                    Name
                                </legend>
                                <Controller
                                    name={`areas[${index}].name`}
                                    control={control}
                                    rules={{
                                        required: "Required",
                                        minLength: { value: 3, message: "Too short" },
                                    }}
                                    defaultValue={area.name || ""}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <InputText
                                                {...field}
                                                value={field.value}

                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    field.onChange(value);
                                                    setValue(`areas[${index}].name`, value);
                                                    setAreas((prevAreas) =>
                                                        prevAreas.map((a, idx) =>
                                                            idx === index ? { ...a, name: value } : a
                                                        )
                                                    );
                                                    checkDuplicateNames();
                                                }}
                                                placeholder={`Screen ${index + 1}`}
                                            />
                                            {fieldState.error && (
                                                <span className="text-red-500 text-sm">{fieldState.error.message}</span>
                                            )}

                                        </>
                                    )}
                                />
                            </fieldset>

                        </div>

                        {/* Start Margin (X) */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-20 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                X
                            </legend>
                            <Controller
                                name={`areas[${index}].start_margin`}
                                control={control}
                                defaultValue={area.start_margin}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={1}
                                        maxFractionDigits={1}

                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].start_margin`, value);
                                            handleSmartInputChange(area.id, "start_margin", value);
                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Top Margin (Y) */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-20 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                Y
                            </legend>
                            <Controller
                                name={`areas[${index}].top_margin`}
                                control={control}
                                defaultValue={area.top_margin}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={1}
                                        maxFractionDigits={1}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].top_margin`, value);
                                            handleSmartInputChange(area.id, "top_margin", value);
                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Width */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-20 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                Width
                            </legend>
                            <Controller
                                name={`areas[${index}].width`}
                                control={control}
                                defaultValue={area.width}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={1}
                                        maxFractionDigits={1}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].width`, value);
                                            handleSmartInputChange(area.id, "width", value);

                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Height */}
                        <fieldset className="border border-gray-300 rounded flex flex-col items-center w-20 relative">
                            <legend className="text-xs px-1 absolute -top-2 left-2 bg-gray-50 w-auto">
                                Height
                            </legend>
                            <Controller
                                name={`areas[${index}].height`}
                                control={control}
                                defaultValue={area.height}
                                render={({ field }) => (
                                    <InputNumber
                                        {...field}
                                        minFractionDigits={1}
                                        maxFractionDigits={1}
                                        value={field.value}
                                        onChange={(e) => {
                                            const value = e.value;
                                            setValue(`areas[${index}].height`, value);
                                            handleSmartInputChange(area.id, "height", value);

                                        }}
                                    />
                                )}
                            />
                        </fieldset>

                        {/* Delete Button */}
                        <Button
                            label=""
                            icon="pi pi-trash"
                            className=" bg-transparent text-red-500	 border-transparent"
                            onClick={() => handleDeleteArea(area.id)}
                        />
                    </div>
                </div>
            ))}

        </div>
    );
};

export default AreaControls;
