import React from "react";

import { Dialog } from "primereact/dialog";

import { formatSize, formatDuration } from "../../../config/global";
import { useGalleryContext } from "../../Contexts/GalleryContext";
import { Media } from "./Media";

import { useDispatch, useSelector } from 'react-redux';
import { toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function PreviewMedia({ data }) {
  const { setSelectedItem } = useGalleryContext();
  const dispatch = useDispatch();
  const openDialog = useSelector((state) => state.global.openDialog);

  const header = () => {
    return (
      <>
        <h1 className="m-0 fw-bold align-items-center">{data.file_name.replace(/_/g, ' ')}</h1>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex  mt-2">
            <span className="px-2 py-1 text-white bg-secondary me-1 fs-8 fw-light media_type">
              {data.type.toLowerCase()}
            </span>
            {
              data.section === "LINKS" ?
                ""
                :
                data.type === "IMAGE" || data.type === "CORNER" ? "" :
                  <span className="px-2 py-1 text-white bg-secondary me-1 fs-8 fw-light">
                    {formatDuration(data.duration)}
                  </span>
            }
            {
              formatSize(data.size) == "0 bytes" ? "" :
                <span className="px-2 py-1 text-white bg-secondary me-1 fs-8 fw-light">
                  {formatSize(data.size)}
                </span>
            }
            <span className="px-2 py-1 text-white bg-secondary me-1 fs-8 fw-light">
              created at  {data.created_at}
            </span>
          </div>
        </div>

      </>
    );
  };

  const closeDialog = () => {
    setSelectedItem("")
    dispatch(toggleDialog("previewMedia"))
  }

  return (
    <>
      <Dialog
        visible={openDialog.previewMedia}
        onHide={() => closeDialog()}
        breakpoints={{ "960px": "95vw" }}
        maximizable={true}
        style={{
          width: data.type == "WEBVIEW" ? "80vw" : ((data.type == "3D" || data.type == "MULTI_3D") ? "80vw" : "60vw"),
          minHeight: data.type == "MULTI_3D" ? "100vh" : "500px",
        }}
        header={header(data.file_name)}
      >
        <div className="d-flex flex-column flex-md-row ">
          <div className="col-12 col-md-12 col-lg-12 p-3 position-relative">
            <Media data={data} />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default PreviewMedia;
