import React, { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { useForm } from "react-hook-form";

import AssignScheduleCampaign from './AssignScheduleCampaign';
import axiosInstance from '../../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setRerender, setLoading } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

const AssignCampaignToGroup = ({ selectedGroup, setSelectedGroup }) => {
  const dispatch = useDispatch();
  const selectedCampaign = useSelector((state) => state.device.selectedCampaign);
  const openDialog = useSelector((state) => state.global.openDialog);

  const { reset } = useForm();

  const [campaignOptions, setCampaignOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    axiosInstance.get(`/company/campaigns`)
      .then((result) => {
        result.data.data.map(campaign => {
          let option = {
            name: campaign.name,
            code: campaign.id
          }
          setCampaignOptions(current => [...current, option])
        })
      })

    axiosInstance.get(`/groups/company`)
      .then((response) => {
        Object.values(response?.data?.data).forEach(group => {
          let option = {
            name: group.name,
            code: group.id
          }
          setGroupOptions(current => [...current, option])
        })
        dispatch(setRerender(false))
        dispatch(setLoading(false))
      })

    reset();
  }, [reset]);

  return (
    <Dialog visible={openDialog.assignDialog}
      style={{ width: '550px' }}
      breakpoints={{ '960px': '95vw' }}
      header="Assign campaign to Group"
      modal className="p-fluid"
      onHide={() => dispatch(toggleDialog("assignDialog"))
      }>

      <AssignScheduleCampaign
        options={campaignOptions}
        selectedCampaign={selectedCampaign}
        groupOptions={groupOptions}
        setGroupOptions={setGroupOptions}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
      />

    </Dialog>
  )
}

export default AssignCampaignToGroup