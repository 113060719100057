import React, { useState } from 'react'

import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';

const MediaFilter = ({ filters, setFilters, onApplyFilters }) => {

  // Sorting Options
  const sortOptions = [
    { label: "Newest First", value: "desc" },
    { label: "Oldest First", value: "asc" },
  ];

  const MEDIA_TYPE_OPTIONS = [
    { label: "All", value: "" },
    { label: "Image", value: "IMAGE" },
    { label: "Audio", value: "AUDIO" },
    { label: "Video", value: "VIDEO" },
    { label: "3D File", value: "3D" },
    { label: "Other", value: "OTHER" },
  ];

  const LINKS_TYPE_OPTIONS = [
    { label: "All", value: "" },
    { label: "External Link", value: "EXTERNAL_LINK" },
    { label: "YouTube", value: "YOUTUBE" },
    { label: "Drive", value: "DRIVE" },
    { label: "Webview", value: "WEBVIEW" },
  ];

  const DEFAULT_MEDIA_FILTERS = {
    section: "MEDIA",
    searchInput: "",
    typeFilter: "",
    minDuration: "",
    maxDuration: "",
    sortOrder: "desc",
    minSize: "",
    maxSize: "",
    folder_id: "",
    resetTriggered: true,
  };

  const DEFAULT_LINKS_FILTERS = {
    section: "LINKS",
    searchInput: "",
    typeFilter: "",
    minDuration: "",
    maxDuration: "",
    sortOrder: "desc",
    minSize: "",
    maxSize: "",
    folder_id: "",
    resetTriggered: true,
  };


  const onResetFilters = () => {
    if (filters.section === "MEDIA") {
      setFilters(DEFAULT_MEDIA_FILTERS);
    } else if (filters.section === "LINKS") {
      setFilters(DEFAULT_LINKS_FILTERS);
    }
  };

  return (
    <>
      <div className="col-12 d-flex justify-content-start align-items-center align-items-start my-sm-3 flex-nowrap fs-9">
        {/* Search Input */}
        <div className="col-2 d-flex flex-column field pe-3 pl-5 fs-9">
          <label htmlFor="searchInput" className="fst-italic">Search by Name</label>
          <span className="p-input-icon-right">
            {/* <i className="pi pi-search" /> */}
            <InputText id="searchInput" 
              value={filters.searchInput}
              placeholder="Search by Name"
              onChange={(e) => setFilters({ ...filters, searchInput: e.target.value })}
              className="p-inputtext-sm w-100 pe-5 "
            />
          </span>
        </div>

        {/* Created time sorting */}
        <div className="col-2 d-flex flex-column field pe-3">
          <label htmlFor="sortOrder" className="fst-italic">Sort by Time</label>
          <Dropdown id="sortOrder" className="p-inputtext-sm w-100"
            value={filters.sortOrder}
            options={sortOptions}
            onChange={(e) => setFilters({ ...filters, sortOrder: e.target.value })}
            placeholder="Sort by Created Time"
          />
        </div>

        {/* type filtering sorting */}
        <div className="col-2 d-flex flex-column field pe-3">
          <label htmlFor="typeFilter" className="fst-italic">Filter by Type</label>
          <Dropdown id="typeFilter" className="p-inputtext-sm w-100"
            value={filters.typeFilter}
            options={filters.section === "MEDIA" ? MEDIA_TYPE_OPTIONS : LINKS_TYPE_OPTIONS}
            onChange={(e) => setFilters({ ...filters, typeFilter: e.target.value })}
            placeholder="Filter by Type"
          />
        </div>

        {/* Size Filter Range */}
        {
          filters.section === "MEDIA"
            ?
            <>
              <div className="col-1 d-flex flex-column field pe-3">
                <label htmlFor="minSize" className="fst-italic">Min Size (KB)</label>
                <InputText id="minSize" className='p-inputtext-sm w-100'
                  type="number"
                  placeholder="in KB "
                  value={filters.minSize}
                  onChange={(e) => setFilters({ ...filters, minSize: e.target.value })} />
              </div>

              <div className="col-1 d-flex flex-column field pe-3">
                <label htmlFor="minSize" className="fst-italic">Max Size (KB)</label>
                <InputText id="maxSize" className='p-inputtext-sm w-100'
                  type="number"
                  placeholder="in KB"
                  value={filters.maxSize}
                  onChange={(e) => setFilters({ ...filters, maxSize: e.target.value })}
                />
              </div>
            </>
            :
            <></>
        }

        {/* Filter by Duration */}
        <div className={`${filters.section === "MEDIA" ? "col-1" : "col-2"} d-flex flex-column field pe-3`} >
          <label htmlFor="minDuration" className="fst-italic">Min Duration</label>
          <InputText id="minDuration" className='p-inputtext-sm w-100'
            type="number"
            placeholder="in sec"
            value={filters.minDuration}
            onChange={(e) => setFilters({ ...filters, minDuration: e.target.value })}
          />
        </div>
        <div className={`${filters.section === "MEDIA" ? "col-1" : "col-2"} d-flex flex-column field pe-3`} >
          <label htmlFor="maxDuration" className="fst-italic">Max Duration</label>
          <InputText id="maxDuration" className='p-inputtext-sm w-100'
            type="number"
            placeholder="in sec"
            value={filters.maxDuration}
            onChange={(e) => setFilters({ ...filters, maxDuration: e.target.value })}
          />
        </div>
        <div className="col-2 d-flex   field pe-5">
          <button className='p-button-sm col-6 btn btn-primary text-center fs-6 p-2 me-2  mt-4' onClick={onApplyFilters}><i className="fa-solid fa-filter" style={{ color: "#ffffff" }}></i></button>
          <button className='p-button-sm  col-6 btn btn-primary text-center fs-6 p-2  me-3   mt-4' onClick={onResetFilters}><i className="fa-solid fa-filter-circle-xmark" style={{ color: "#ffffff" }}></i></button>
        </div>
      </div>

    </>


  )
}

export default MediaFilter;