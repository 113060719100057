import React, { useState } from 'react';
import { useForm } from "react-hook-form";

import { useCustomToast } from "../../../Contexts/ToastContext"
import axiosInstance from '../../../../config/Axios';

import { Dialog } from 'primereact/dialog'
import {  useDispatch , useSelector} from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function UpdateFolder ({ item, setRerender, currentFolder }) {
    const { formState: { errors }, handleSubmit, register } = useForm({ item });
     const dispatch = useDispatch();
   
    const   openDialog  = useSelector((state) => state.global.openDialog);

    const { showToast } = useCustomToast();


    const [folderName, setFolderName] = useState(item.folder_name)
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        setLoading(true)
        axiosInstance.put(`/folder/${ currentFolder.id }`, data)
            .then((result) => {
                setRerender(true);
                dispatch(toggleDialog("updateFolder"));
                showToast('success', 'Folder Updating', 'Folder Updating Successfully');
            }).catch((error) => {
                showToast('error', 'Folder Updating', error?.response?.data?.message);
            });
        setLoading(false)
    };

    return (
        <>
            <Dialog header="Folder Updating"
                visible={ openDialog.updateFolder }
                onHide={ () => { dispatch(toggleDialog("updateFolder")) } }
                breakpoints={ { '960px': '95vw' } }
                style={ { width: '25vw' } }
            >
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="my-5 px-4">
                        {/* <label htmlFor="">Folder Name</label> */ }
                        <input
                            { ...register("name", { required: "Folder name is required!" }) }
                            aria-invalid={ errors.name ? "true" : "false" }
                            className={ errors.name ? 'form-control is-invalid fs-4' : 'form-control fs-5' }
                            placeholder="Folder Name" value={ folderName } onChange={ (e) => setFolderName(e.value) }
                        />
                        { errors.name && <p className='fv-plugins-message-container invalid-feedback'>{ errors.name?.message }</p> }
                    </div>
                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" onClick={ () => dispatch(toggleDialog("updateFolder")) }>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary me-3" disabled={ loading ? true : false }>Update</button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default UpdateFolder