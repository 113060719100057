import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
 
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';


function Rotate ({ degree, setDegree, setDeviceInfo }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showToast } = useCustomToast();

    const options = [
        { name: '0 Degree', code: '0' },
        { name: '90 Degree', code: '90' },
        { name: '180 Degree', code: '180' },
        { name: '270 Degree', code: '270' },
    ];

    //Degree handler update on change 
    const degreeHandler = (e) => {
        const newDegree = e.value;
        setDegree(newDegree); 
        axiosInstance.post(`/device/${ id }/rotate`,
            {
                "rotation": e.value
            } 
        ).then((result) => {
            setDeviceInfo(e.value);
            showToast('success', 'Update Rotation', 'Rotation Updated Successfully');
        }).catch((error) => {
            showToast('error', 'Update Rotation', error?.response?.data?.message);
        });
    }

    return (
        <>
            <div className="card-body pt-2 pb-3">
                <div className="card-header p-0 mb-2 ">
                    <div className=' d-flex align-items-center justify-content-between mb-2'>
                        <h5 className="m-0 me-2 ">Rotate</h5>
                    </div>
                    <Dropdown className='w-100' value={ degree }
                        options={ options } onChange={ degreeHandler }
                        optionLabel="name" optionValue="code"
                        placeholder="Select a Rotate Degree"
                    />
                </div>
            </div>
        </>
    )
}

export default Rotate