
import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Spinner } from 'react-bootstrap';
import { Column } from 'primereact/column';

import { useDataTableContext } from '../../../Contexts/DataTableContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';
import StreamPreview from './StreamPreview';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function StreamsDataTable() {
    const { totalRecords, loading, setReload, lazyParams, setLazyParams, data, onPage, onSort, onFilter, } = useDataTableContext();
    const openDialog = useSelector((state) => state.global.openDialog);
    const dispatch = useDispatch()

    const { showToast } = useCustomToast();

    const [selectedItem, setSelectedItem] = useState({});
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        setLazyParams(prev => ({
            ...prev,
            rows: 8,
            modelName: "streams",
            filters: {
                'name': { value: '', matchMode: 'contains' },
                'status': { value: '', matchMode: 'contains' },
                'type': { value: '', matchMode: 'contains' },
            }
        }))
    }, [])

    const statusBodyTemplate = (rowData) => {
        if (rowData.status == "PUBLISHED")
            var color = "warning"
        else if (rowData.status == "PROCESSING")
            var color = "success"
        else
            var color = "danger"

        return (
            <div className='text-center'>
                <span className={`text-center badge bg-label-${color} `}>
                    {rowData.status.toLowerCase()}
                </span>
            </div>
        );
    }

    const typeBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <span className="text-center badge  bg-label-secondary text-capitalize">
                    {
                        rowData.type == "ONE_WAY"
                            ?
                            <i className="fas fa-long-arrow-alt-right me-2"></i>
                            :
                            <i className="fas fa-exchange-alt me-2"></i>
                    }
                    {rowData.type.replace(/_/g, ' ').toLowerCase()}
                </span>
            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className='d-flex align-items-center justify-content-end text-light '>
                {
                    rowData.status != "FINISHED" ?
                        (
                            (showLoading && selectedItem.id == rowData.id)
                                ?
                                <Spinner variant="primary" size='sm' as="span" animation="border" role="status" aria-hidden="true" />
                                :
                                <>
                                    <Tooltip target={`.stop-button-${rowData.id}`} showDelay={100} className="fs-8" />
                                    <button
                                        className={`btn btn-sm btn-icon stop-button-${rowData.id}`}
                                        data-pr-position="bottom"
                                        data-pr-tooltip="Stop Stream"
                                        onClick={() => stopStream(rowData)} >
                                        <i className="fas fa-stop-circle  text-light fs-6"></i>
                                    </button>
                                </>)
                        :
                        ""
                }

                <Tooltip target={`.preview-button-${rowData.id}`} showDelay={100} className="fs-8" />
                <button
                    className={`btn btn-sm btn-icon preview-button-${rowData.id}`}
                    data-pr-position="bottom"
                    data-pr-tooltip="Stream Preview"
                    onClick={() => previewHandler(rowData)} >
                    <i className="fa-solid fa-eye  text-light fs-6"></i>
                </button>
            </div>
        )
    }

    const previewHandler = (data) => {
        setSelectedItem(data)
        dispatch(toggleDialog("streamPreview"))
    }

    const stopStream = (data) => {
        setShowLoading(true)
        setSelectedItem(data)
        axiosInstance.get(`/streams/${data.id}/stop`)
            .then(result => {
                setReload(true);
                showToast("success", "Stream", result?.data?.data?.msg)
                setSelectedItem({})
                setShowLoading(false)
            })
    }

    return (
        <div className='table-responsive text-nowrap '>
            <DataTable value={data.streams} lazy responsiveLayout="scroll" dataKey="id"
                paginator first={lazyParams.first} rows={lazyParams.rows}
                totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                onFilter={onFilter} loading={loading} className="table"
            >
                <Column field="name" header="Name" className='text-center' sortable />
                <Column field="type" className='text-center' header="Type" body={typeBodyTemplate} />
                <Column field="status" header="Status" className='text-center' sortable body={statusBodyTemplate} />
                <Column field="created_at" header="Created Date" className='text-center' sortable />
                <Column field="" header="" className='text-center ' body={actionBodyTemplate} />
            </DataTable>

            {
                openDialog.streamPreview ? <StreamPreview streamInfo={selectedItem} /> : <></>
            }
        </div>

    )
}

export default StreamsDataTable