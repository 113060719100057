import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from 'react-bootstrap';

import { useTitle } from "../../../../config/global";
import axiosInstance from '../../../../config/Axios';

import Layout from '../../../Layout/Layout';
import LayoutInfo from "./Setup/LayoutInfo";
import ScreenInfo from "./Setup/ScreenInfo";
import EditLayoutPopUp from './EditLayoutPopUp';

import { useSelector, useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../redux/Slices/Global/globalSlice';
import { setScreens_setting, setSelectedLayout, setScreen, setScreenDetails, setReloadTrigger, setIsPublished } from '../../../../redux/Slices/layoutSlice';

const LayoutSetup = () => {
    useTitle("Layout Setup")
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const subLoaders = useSelector((state) => state.global.subLoaders || { Loading: false });
    const openDialog = useSelector((state) => state.global.openDialog);

    const reloadTrigger = useSelector((state) => state.layout.reloadTrigger);

    useEffect(() => {
        dispatch(toggleLoader("Loading"))
        getLayoutData()
        getScreensData()
    }, [id])

    useEffect(() => {
        if (reloadTrigger) {
            dispatch(toggleLoader("Loading"))
            dispatch(setReloadTrigger(false))
            getLayoutData()
            getScreensData()
        }
    }, [reloadTrigger])

    function getLayoutData() {

        axiosInstance.get(`/layout/${id}`)
            .then((result) => {
                let data = result.data.data;
                const screens_Options = data.screens_setting.map(screen => ({
                    ...screen,
                }));
                dispatch(setSelectedLayout(data));
                // dispatch(setScreens_setting(screens_Options))
            })
            .catch((error) => {
                dispatch(toggleLoader("Loading"))
                console.error("Error in getLayoutData:", error);

            })
    }

    function getScreensData() {
        axiosInstance.get(`/layout/${id}/campaigns`)
            .then((result) => {
                let data = result.data.data;
                const screensData = data.map(screen => ({
                    ...screen,
                }));
                if (screensData.length > 0) {
                    dispatch(setScreenDetails(screensData[0]));
                    dispatch(setScreens_setting(screensData));

                    const allPublished = data.every(screen => screen.status === "PUBLISHED");

                    if (allPublished) {
                        dispatch(setIsPublished(true));
                    } else {
                        dispatch(setIsPublished(false));
                    }

                }
                dispatch(toggleLoader("Loading"))

            })
            .catch((error) => {
                dispatch(toggleLoader("Loading"))

                console.error("Error in getScreensData:", error);
            });
    }

    return (
        <Layout>
            {
                subLoaders.Loading ?
                    <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                        <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                    </div>
                    :
                    <div className="row flex-wrap">
                        <div className="col-md-6 col-lg-8 order-md-1 order-lg-1  mb-4">

                            <ScreenInfo />

                        </div>
                        <div className="col-md-6 col-lg-4 order-md-2 order-lg-2">
                            <div className="col-md-12  mb-4">
                                <div className="card d-flex   flex-column" style={{ height: "auto" }}>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <LayoutInfo />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                openDialog.EditLayoutPopUp && <EditLayoutPopUp />
            }
        </Layout>

    );
};

export default LayoutSetup;
