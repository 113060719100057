import React from 'react';
import { useForm, Controller } from "react-hook-form";

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setRerender, setLoading } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog, setDisableBtn } from '../../../../redux/Slices/Global/globalSlice';


const CreateGroup = () => {
    const dispatch = useDispatch();
    const { disableBtn, openDialog } = useSelector((state) => state.global);

    const { control, formState: { errors }, handleSubmit, reset } = useForm();
    const { showToast } = useCustomToast();

    const onSubmit = (data) => {
        setDisableBtn(true)
        axiosInstance.post("/groups/create", { "name": data.name }
        ).then((result) => {
            dispatch(setDisableBtn(false))
            showToast('success', 'Campaign Creation', result?.data?.data?.msg);
            dispatch(toggleDialog("createGroupDialog"))
            dispatch(setRerender(true))
            dispatch(setLoading(true))
        }).catch((error) => {
            dispatch(setDisableBtn(false));
            if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Campaign Creation', error[0]);
                });
        });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    return (

        <Dialog visible={openDialog.createGroupDialog}
            breakpoints={{ '960px': '95vw' }}
            style={{ width: '600px' }}
            header="Create Group"
            modal className="p-fluid" onHide={() => dispatch(toggleDialog("createGroupDialog"))}>

            <form onSubmit={handleSubmit(onSubmit)} className=" d-flex flex-column align-items-center">
                <div className={`col-12 px-3 my-5  w-75`}>
                    <div className="field">
                        <label className="form-label">
                            Name
                        </label>
                        <span className="p-float-label">
                            <Controller name="name" control={control}
                                rules={{ required: 'Name is required.' }}
                                render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus
                                        className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                        </span>
                        {getFormErrorMessage('name')}
                    </div>
                </div>

                <div className="col-12 mt-3 d-flex justify-content-center py-3 border-top ">
                    <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" aria-label="Close" disabled={disableBtn ? true : false} onClick={() => dispatch(toggleDialog("createGroupDialog"))}>Cancel</button>
                    <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={disableBtn ? true : false}>
                        {disableBtn ?
                            <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                            :
                            "Create"
                        }
                    </button>
                </div>
            </form>
        </Dialog>
    );
};
export default CreateGroup;
