import { configureStore } from "@reduxjs/toolkit";

import deviceReducer from "./Slices/deviceSlice";
import layoutReducer from "./Slices/layoutSlice";
import globalReducer from "./Slices/Global/globalSlice";

export const store = configureStore({
  reducer: {
    //  reducers will be here
    device: deviceReducer,
    global: globalReducer,
    layout: layoutReducer,

  },
  devTools: true,
});
