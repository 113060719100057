import React, { useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";

import { getFormErrorMessage } from '../../../config/global';
import { useCustomToast } from '../../Contexts/ToastContext';
import { useAppsContext } from '../../Contexts/AppsContext';
import axiosInstance from '../../../config/Axios';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useDataTableContext } from '../../Contexts/DataTableContext';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../redux/Slices/Global/globalSlice';

function CreateEditCollection({ selectedCollection }) {
    const { openDialog, subLoaders, disableBtn } = useSelector((state) => state.global);
    const dispatch = useDispatch()

    const { formState: { errors }, handleSubmit, control, reset } = useForm();
    const { isCreation, setIsCreation } = useAppsContext();
    const { setReload } = useDataTableContext();
    const { showToast } = useCustomToast();

    useEffect(() => {
        if (!isCreation) {
            reset({
                name: selectedCollection?.file_name,
            })
        }

        return () => {
            setIsCreation(true);
        }
    }, [])

    const submitHandler = (data) => {
        setDisableBtn(true);

        if (isCreation) {
            axiosInstance.post(`/media/multi-3d/create`, {
                "name": data?.name
            })
                .then((result) => {
                    setReload(true)
                    showToast('success', "Multi-media Collection", result?.data?.data?.msg);
                    dispatch(toggleDialog("createEditCollection"))
                    setDisableBtn(false);
                })
                .catch((error) => {
                    setDisableBtn(false);
                })
        } else {
            axiosInstance.post(`/media/${selectedCollection?.id}/update`, {
                "name": data?.name
            })
                .then((result) => {
                    setReload(true)
                    showToast('success', "Multi-media Collection", result?.data?.data?.msg);
                    dispatch(toggleDialog("createEditCollection"))
                    setDisableBtn(false);
                })
                .catch((error) => {
                    setDisableBtn(false);
                })
        }



    }


    return (
        <Dialog visible={openDialog.createEditCollection}
            style={{ width: '30%' }}
            breakpoints={{ '960px': '95vw' }}
            header={`Collection ${isCreation ? "Creation" : "Editing"}`}
            modal className="p-fluid"
            onHide={() => dispatch(toggleDialog("createEditCollection"))}
        >
            <form onSubmit={handleSubmit(submitHandler)} className="d-flex flex-wrap">
                <div className="col-12 d-flex flex-wrap p-4 ">
                    <div className='mb-2 col-12 d-flex flex-column'>
                        <label htmlFor="" className={`form-label text-capitalize mb-2 `} >Collection Name</label>
                        <Controller name="name" control={control}
                            rules={{ required: 'Collection name is required.' }}
                            render={({ field, fieldState }) => (
                                <InputText
                                    type="text"
                                    value={field.value}
                                    id={field.name}
                                    {...field}
                                    inputRef={field.ref}
                                    className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                />
                            )} />

                        {getFormErrorMessage("name", errors)}
                    </div>
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" disabled={disableBtn ? true : false}
                        data-bs-dismiss="modal" aria-label="Close" onClick={() => dispatch(toggleDialog("createEditCollection"))}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary  me-1" disabled={disableBtn || subLoaders.createLoading} >
                        {
                            disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                isCreation ? "Create" : "Update"
                        }
                    </button>
                </div>
            </form>

        </Dialog >
    )
}

export default CreateEditCollection