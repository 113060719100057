import React, { useEffect } from 'react';

import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { getFormErrorMessage } from '../../../../../config/global';
import { useCustomToast } from "../../../../Contexts/ToastContext";
import axiosInstance from '../../../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';

function Update({ layout, setReload }) {
    const dispatch = useDispatch();
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { showToast } = useCustomToast();

    const status = [
        { name: 'Active', code: 'ACTIVE' },
        { name: 'Inactive', code: 'INACTIVE' },
    ];

    useEffect(() => {
        reset(layout)
    }, [layout])

    const hideDialog = () => {
        dispatch(toggleDialog("updateLayout"));
    }

    const onSubmit = (data) => {
        dispatch(setDisableBtn(true))
        let formData = {
            "title": data.title,
            "status": data.status,
        }

        axiosInstance.post(`/layout/${layout.id}/update`, formData)
            .then((result) => {
                console.log("result updatae ", result)
                dispatch(setDisableBtn(false))
                showToast('success', 'Update Layout', "Layout Updated Successfully!");
                hideDialog()
                setReload(true)
            }).catch((error) => {
                dispatch(setDisableBtn(false));
                if (error?.response?.data?.errors)
                    Object.values(error?.response?.data?.errors).forEach(error => {
                        showToast('error', 'Update Layout', error[0]);
                    });
            });

    };

    return (
        <>
            <Dialog visible={openDialog.updateLayout}
                style={{ width: '500px' }}
                header="Update Layout" modal
                className="p-fluid" onHide={hideDialog}
                breakpoints={{ '960px': '95vw' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='px-5 my-5'>
                        <div className={` col-12 mb-5`}>
                            <div className="field">
                                <label className="form-label"> Title  </label>
                                <span className="p-float-label">
                                    <Controller name="title" control={control}
                                        rules={{ required: 'Name is required.' }}
                                        render={({ field, fieldState }) => (
                                            <InputText id={field.title} {...field} autoFocus
                                                className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage('title', errors)}
                            </div>
                        </div>

                        {/* Status input */}
                        <div className={` col-12`}>
                            <div className="field">
                                <label className="form-label" htmlFor="basic-default-company">  Status </label>
                                <span className="p-float-label">
                                    <Controller name="status" control={control}
                                        rules={{ required: 'Status is required.' }}
                                        render={({ field, fieldState }) =>
                                        (<Dropdown id={field.name} {...field}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={status}
                                            optionLabel="name"
                                            optionValue="code"
                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        />)
                                        } />
                                    {getFormErrorMessage('status', errors)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button type="reset" className="btn btn-label-secondary me-3 "
                            data-bs-dismiss="modal" aria-label="Close"
                            disabled={disableBtn ? true : false}
                            onClick={hideDialog}>Cancel</button>
                        <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={disableBtn ? true : false} >
                            {disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Update"
                            }
                        </button>
                    </div>

                </form>
            </Dialog>
        </>
    )
}

export default Update