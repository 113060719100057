import React from "react";
import { Button } from "primereact/button";
import { Spinner } from 'react-bootstrap';

import { useSelector } from 'react-redux';

const ConflictWarningContent = ({ responseData, formData, assignToRestGroup, replaceOldScheduleCampaign }) => {
    const disableBtn = useSelector((state) => state.global.disableBtn);
    return (
        <div className="flex flex-column" style={{ flex: "1" }}>
            <div className="text-center">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: "1.5rem" }}></i>
                <h4 className="py-3 lh-base">
                    There are assigned campaign to &nbsp;
                    <span className="fw-bold">
                        {responseData?.devices?.devices?.map((item, index) => (
                            <span key={index}>
                                {item["name"] + (index < responseData.devices.devices.length - 1 ? ", " : "")}
                            </span>
                        ))}
                    </span>
                    &nbsp; devices in the same time
                </h4>
            </div>
            <div className="d-flex flex-nowrap justify-content-center mt-2">
                {/* Assign to devices without conflict */}
                <Button
                    type="button"
                    label={
                        disableBtn ? (
                            <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className="mx-3" aria-hidden="true" />
                        ) : (
                            <span className="d-flex align-items-center">
                                Assign to Rest
                            </span>
                        )
                    }
                    onClick={() => assignToRestGroup(formData)}
                    className="p-button-secondary py-2 px-4 fs-8 w-auto me-3"
                    disabled={disableBtn}
                />

                {/* Replace schedule campaign */}
                <Button
                    type="button"
                    label={
                        disableBtn ? (
                            <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className="mx-3" aria-hidden="true" />
                        ) : (
                            <span className="d-flex align-items-center">
                                Replace Campaign
                            </span>
                        )
                    }
                    onClick={() => replaceOldScheduleCampaign(formData)}
                    className="p-button-success py-2 px-4 fs-8 w-auto"
                    disabled={disableBtn}
                />
            </div>
        </div>
    );
};

export default ConflictWarningContent;
