import React, { useState, useEffect } from "react";
import { Spinner } from 'react-bootstrap';

import { useCustomToast } from '../../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';

const DynamicContainerPreview = ({ areas, colors, currentAspectRatio }) => {
    const { showToast } = useCustomToast();
    const subLoaders = useSelector((state) => state.global.subLoaders);
    const dispatch = useDispatch()
    const selectedLayout = useSelector((state) => state.layout.selectedLayout);

    const [aspectWidth, setAspectWidth] = useState();
    const [aspectHeight, setAspectHeight] = useState();

    const aspectRatio =
        aspectWidth > aspectHeight
            ? "Landscape"
            : aspectWidth < aspectHeight
                ? "Portrait"
                : "Square";



    useEffect(() => {
        if (currentAspectRatio) {
            setAspectWidth(currentAspectRatio.width)
            setAspectHeight(currentAspectRatio.height)
        }
    }, [currentAspectRatio]);

    // the aspect on each area
    const calculateAspectRatio = (width, height) => {
        const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
        const divisor = gcd(width, height);
        return `${width / divisor}:${height / divisor}`;
    };

    // 4 the scaled dimensions
    const calculateScaledDimensions = () => {
        if (aspectWidth === 0 || aspectHeight === 0) return { width: 0, height: 0 };

        const widthRatio = 262 / aspectWidth;
        const heightRatio = 144 / aspectHeight;

        const scaleFactor = Math.min(widthRatio, heightRatio);

        const scaledWidth = aspectWidth * scaleFactor;
        const scaledHeight = aspectHeight * scaleFactor;

        return { width: scaledWidth, height: scaledHeight };
    };

    const { width: scaledWidth, height: scaledHeight } = calculateScaledDimensions();


    return (
        <>{
            subLoaders.createLoading ?
                <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                    <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                </div>
                :

                <div className={`w-[35%] mb-4  mt-3 h-[144px]`}>
                    <div className="text-sm font-medium mb-2">Aspect Ratio: {currentAspectRatio.width} x {currentAspectRatio.height} </div>
                    <div className={`flex relative justify-center w-[262px] h-100`}>
                        <div
                            className={`p-4 relative border border-gray-300 rounded-md bg-white  `}
                            style={{
                                width: scaledWidth,
                                height: scaledHeight,
                            }}
                        >
                            {areas.map((area, index) => {
                                const aspectRatioText = calculateAspectRatio(area.width, area.height);
                                return (

                                    <div
                                        key={area.id}
                                        className=" flex justify-center text-[black] items-center absolute border border-black text-black text-center text-sm"
                                        style={{
                                            left: `${area.start_margin}%`,
                                            top: `${area.top_margin}%`,
                                            width: `${area.width}%`,
                                            height: `${area.height}%`,
                                            backgroundColor: `${colors[index]}`,
                                        }}
                                    >
                                        {index + 1}

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div >
        }</>
    );
};

export default DynamicContainerPreview;
