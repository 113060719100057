import React, { useEffect, useState } from 'react'

import { Spinner } from 'react-bootstrap';

import { useTitle } from '../../config/global';
import "./css/device.css";

import AssignCampaignToGroup from './Component/CampaignAssignment/AssignCampaignToGroup';
import GroupsOrderingDialog from './Component/Grouping/GroupsOrderingDialog';
import CreateGroup from './Component/Grouping/CreateGroup';
import Confirm from './Component/Confirm';
import Filter from './Component/Filter';
import Layout from '../Layout/Layout'
import Group from './Component/Grouping/Group';

import axiosInstance from '../../config/Axios';

import { setDisableBtn, toggleDialog, setCountries } from '../../redux/Slices/Global/globalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setRerender } from '../../redux/Slices/deviceSlice';
import _ from 'lodash';

function Index() {
    useTitle("Devices")

    const { rerender, loading, deviceFilters } = useSelector((state) => state.device);
    const { openDialog, disableBtn } = useSelector((state) => state.global);

    const dispatch = useDispatch();

    const [groups, setGroups] = useState({});
    const [selectedGroup, setSelectedGroup] = useState("default");

    const fetchData = () => {
        dispatch(setLoading(true));

        setGroups({});
        axiosInstance.get(`/groups/company${!_.isNull(deviceFilters.isOnline) ? `?is_online=${deviceFilters.isOnline}` : ""}`)
            .then((response) => {
                Object.values(response?.data?.data).forEach(group => {
                    setGroups((prev) => ({ ...prev, [group.id]: { group_id: group.id, name: group.name, items: group.devices, } }))
                })
                dispatch(setRerender(false))

                dispatch(setLoading(false));
                setDisableBtn(false)
            })
    }

    useEffect(() => {
        axiosInstance.get(`/countries/all`)
            .then(response => {
                const _countries = response?.data.geonames.map(country => {
                    return {
                        label: country.countryName,
                        value: country.countryCode,
                    }
                });
                setCountries(_countries)
            }).catch(error => console.error('Error:', error));


        fetchData();
    }, [])

    useEffect(() => {
        if (rerender) {
            fetchData();
        }
    }, [rerender])

    return (
        <Layout>
            <div className="d-flex flex-column align-items-start ">
                <div className="col-12 d-flex mb-4 align-items-start justify-content-end">
                    <div className="col-7">
                        <Filter setGroups={setGroups} />
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("orderDialog"))} disabled={disableBtn ? true : false}>
                            <span className="tf-icons bx bx-device"></span> Reorder Groups
                        </button>
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("assignDialog"))} disabled={disableBtn ? true : false}>
                            <span className="tf-icons bx bx-device" ></span> Assign Campaign
                        </button>
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("createGroupDialog"))} disabled={disableBtn ? true : false}>
                            <span className="tf-icons bx bx-device"></span> New Group
                        </button>
                        <button type="button" className="btn btn-primary me-sm-2 me-1" onClick={() => dispatch(toggleDialog("confirmDialog"))} disabled={disableBtn ? true : false}>
                            <span className="tf-icons bx bx-device"></span> New Device
                        </button>
                    </div>
                </div>
                {
                    loading ?
                        <div className='my-5 h-100 w-100 d-flex align-items-center justify-content-center'>
                            <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='mx-3' aria-hidden="true" />
                        </div>
                        :
                        <div className='col-12'>
                            <Group
                                setSelectedGroup={setSelectedGroup}
                                devicesGroup={groups}
                            />
                        </div>
                }
                {
                    Object.keys(groups).length === 0 && !loading ?
                        <div className='card d-flex justify-content-center text-center w-100 my-5 p-5'>
                            No results found!
                        </div>
                        : <></>
                }
            </div>

            {openDialog.confirmDialog ? <Confirm /> : <></>}

            {openDialog.createGroupDialog ? <CreateGroup /> : <></>}

            {
                openDialog.assignDialog ?
                    <AssignCampaignToGroup selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
                    :
                    <></>
            }

            {openDialog.orderDialog ? <GroupsOrderingDialog groups={groups} /> : <></>}
        </Layout>
    )
}

export default Index