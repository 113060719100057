import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import tzLookup from 'tz-lookup';

import { getFormErrorMessage } from '../../../config/global';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import Select from 'react-select';

import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import { setRerender, setLoading } from '../../../redux/Slices/deviceSlice';
import { toggleDialog, setDisableBtn } from '../../../redux/Slices/Global/globalSlice';


const status = [
    { name: 'Active', code: 'ACTIVE' },
    { name: 'Inactive', code: 'INACTIVE' },
];

function Update() {
    const { formState: { errors }, control, handleSubmit, reset, setError } = useForm();
    const { showToast } = useCustomToast();
    const dispatch = useDispatch();
    const device = useSelector((state) => state.device.device);
    const { disableBtn, openDialog, countries } = useSelector((state) => state.global);

    const [selectLocManually, setSelectLocManually] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [cities, setCites] = useState([]);

    useEffect(() => {
        setSelectedCity(null)
        axiosInstance.get(`/countries/${selectedCountry.value}/cities/all`)
            .then(response => {
                const _cities = response?.data.geonames.filter(city => city.adminName1 != "")
                    .map(city => {
                        return {
                            label: city.toponymName,
                            value: city.toponymName,
                            lat: city.lat,
                            lng: city.lng
                        }
                    });
                setCites(_cities)
            })
            .catch(error => console.error('Error:', error));
    }, [selectedCountry])

    useEffect(() => {
        reset({
            name: device.name,
            status: device.status
        })
    }, [device])

    const onSubmit = (formData) => {
        if (!selectedCity && selectLocManually)
            setError('city', { type: 'manual', message: 'City  is required!' });
        else {
            dispatch(setDisableBtn(true))
            axiosInstance.put(`/device/${device.id}/update`, {
                name: formData.name,
                status: formData.status,
                location: {
                    "country_name": selectLocManually ? selectedCountry.label : "",
                    "timezone": selectLocManually ? tzLookup(selectedCity.lat, selectedCity.lng) : "",
                    "city": selectLocManually ? selectedCity.value : ""
                }
            }).then((result) => {
                dispatch(setDisableBtn(false))
                dispatch(toggleDialog("updateDevice"));
                dispatch(setRerender(true))
                dispatch(setLoading(true))
                showToast('success', 'Device Updating ', "Device Updated Successfully!");
            }).catch((error) => {
                dispatch(setDisableBtn(false));
                if (error?.response?.data?.errors)
                    Object.values(error?.response?.data?.errors).forEach(error => {
                        showToast('error', 'Device Updating ', error[0]);
                    });
            });
        }
    };

    return (
        <>
            <Dialog visible={openDialog.updateDevice}
                style={{ width: '500px' }}
                header="Update Device Status"
                modal className="p-fluid"
                breakpoints={{ '960px': '95vw' }}
                onHide={() => dispatch(toggleDialog("updateDevice"))}>

                {/* {
                    process.env.REACT_APP_APP_ENV != "Production" &&
                    <>
                        <div className={ `col-12 my-2   d-flex` }>
                            <button className={ `btn btn-sm bg-primary text-white  mx-auto` } data-pr-position="bottom"
                                onClick={ () => { prayer(true) } }>
                                prayer
                            </button>
                        </div>
                    </>
                } */}

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={`col-12 mb-3 mt-5 px-4`}>
                        <div className="field ">
                            <label className="form-label"> Name  </label>
                            <span className="p-float-label">
                                <Controller name="name" control={control}
                                    rules={{ required: 'Name is required.' }}
                                    render={({ field, fieldState }) => (
                                        <InputText id={field.name} {...field} autoFocus inputRef={field.ref}
                                            className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        />
                                    )} />
                            </span>
                            {getFormErrorMessage('name', errors)}
                        </div>
                    </div>

                    {/* Status input */}
                    <div className={`col-12 my-3 px-4`}>
                        <div className="field">
                            <label className="form-label" htmlFor="basic-default-company">  Status </label>
                            <span className="p-float-label">
                                <Controller name="status" control={control}
                                    rules={{ required: 'Status is required.' }}
                                    render={({ field, fieldState }) =>
                                    (<Dropdown id={field.name} {...field}
                                        onChange={(e) => field.onChange(e.value)}
                                        options={status}
                                        optionLabel="name"
                                        optionValue="code"
                                        inputRef={field.ref}
                                        className={`w-100  ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                    />)
                                    } />
                                {getFormErrorMessage('status', errors)}
                            </span>
                        </div>
                    </div>

                    {
                        selectLocManually ?
                            <>
                                {/* Country Select */}
                                <div className={`col-12 my-3 px-4`}>
                                    <div className="field">
                                        <label htmlFor="" className='mb-2 form-label'>Country</label>
                                        <Controller name="country_name" control={control}
                                            rules={{ required: selectLocManually && "Country  is required!" }}
                                            render={({ field, fieldState }) => (
                                                <Select
                                                    {...field}
                                                    options={countries}
                                                    value={selectedCountry}

                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions);
                                                        setSelectedCountry(selectedOptions)
                                                    }}
                                                    placeholder="Select..."
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage('country_name', errors)}
                                    </div>
                                </div>

                                {/* City Select */}
                                <div className={`col-12 my-3 px-4`}>
                                    <div className="field">
                                        <label htmlFor="" className='mb-2 form-label'>City</label>
                                        <Controller name="city" control={control}
                                            rules={{ required: selectLocManually && "City is required!" }}
                                            render={({ field, fieldState }) => (
                                                <Select
                                                    {...field}
                                                    value={selectedCity}
                                                    options={cities}
                                                    onChange={(selectedOptions) => {
                                                        field.onChange(selectedOptions);
                                                        setSelectedCity(selectedOptions)
                                                    }}
                                                    placeholder="Select..."
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage('city', errors)}
                                    </div>
                                </div>
                            </>
                            : <></>
                    }

                    {/* Select Location Manually Checkbox */}
                    <div className={`col-12 mt-4 mb-3 px-4`}>
                        <div className="field">
                            <div className="field-checkbox d-flex align-items-center">
                                <Checkbox inputId="select_location" name="select_location" value="select_location" onChange={(e) => setSelectLocManually(prev => !prev)} checked={selectLocManually} />
                                <label htmlFor="select_location" className='ms-2'>Select the device location manually.</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 text-center mt-5 d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button type="reset" className="btn btn-label-secondary me-3 " data-bs-dismiss="modal" aria-label="Close" disabled={disableBtn ? true : false} onClick={() => dispatch(toggleDialog("updateDevice"))}>Cancel</button>
                        <button type="submit" className="btn btn-primary me-2" disabled={disableBtn ? true : false}>
                            {
                                disableBtn ?
                                    <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                    :
                                    "Update"
                            }
                        </button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default Update