import React, { useEffect, useState } from 'react'

import RotationIcon from "../../../../assets/img/rotation_icon.png"

import { rotationOptions } from '../../../../config/constants'
import { useDeviceContext } from '../../../Contexts/DeviceContext'
import { useCustomToast } from '../../../Contexts/ToastContext'
import axiosInstance from '../../../../config/Axios'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Dropdown } from 'primereact/dropdown'
import { Tooltip } from 'primereact/tooltip'
 import { Spinner } from 'react-bootstrap'
import _ from 'lodash'

import { useSelector, useDispatch } from 'react-redux';
import {setDevice, setRerender, setEditRotation  } from '../../../../redux/Slices/deviceSlice';

function Rotation ({ rowData, realtimeData }) {
    const dispatch = useDispatch();
    
    const {device, editRotation} = useSelector((state) => state.device);

    const { showToast } = useCustomToast()
 
    const [rotation, setRotation] = useState(rotationOptions[0])
    const [loading, setLoading] = useState(false)

    const handleRotate = () => {
        setLoading(true)

        axiosInstance.post(`/device/${ rowData?.id }/rotate`, {
            rotation: rotation
        })
            .then((result) => {
                showToast('success', 'Rotate Device', result?.data?.data?.msg);
                setLoading(false)
                dispatch(setRerender(true))
                dispatch(setEditRotation(false));
            }).catch((error) => {
                setLoading(false)
                showToast('error', 'Rotate Device', error.response?.data?.message);
            });
    }

    useEffect(() => {
         setRotation(rowData?.rotation)
    }, [device])

    return (
        <>
            {
                (editRotation && device?.id === rowData?.id) ?
                    <>
                        <Dropdown
                            value={ rotation }
                            options={ rotationOptions }
                            onChange={ (e) => { setRotation(e.value); } }
                            optionLabel="label"
                            optionValue="value"
                            placeholder="select..."
                            className={ ` ` }
                        />
                        {
                            loading ?
                                <Spinner variant="primary" as="span" size="sm" animation="border" role="status" className="mx-2" aria-hidden="true" />
                                :
                                <i className="far fa-save fs-4 ms-2 cursor-pointer" onClick={ () => handleRotate(rowData.id) }></i>
                        }
                    </>
                    :
                    <>
                        <Tooltip target={ `.rotate-${ rowData.id }` } showDelay={ 100 } className="fs-8" />

                        <span className={ `text-center cursor-pointer rotate-${ rowData.id } d-flex align-items-center bg-label-secondary p-2 rounded` }
                            rowData-pr-position="bottom"
                            rowData-pr-tooltip='Rotate'
                            onClick={ () => { dispatch(setEditRotation(true)); dispatch(setDevice(rowData)) } }

                        >
                            <LazyLoadImage src={ RotationIcon } className=" my-1 cursor-pointer"
                                style={ {
                                    width: "30px",
                                    transform: `rotate(${ realtimeData.screen_angle || rowData?.rotation}deg)`
                                } } />
                            <span className='mx-2'>{ realtimeData.screen_angle || rowData?.rotation } ْ</span>
                        </span>
                    </>
            }
        </>
    )
}

export default Rotation