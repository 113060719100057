import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RotationIcon from "../../../../assets/img/rotation_icon.png"

import { Card, Image } from 'react-bootstrap'
import { Tooltip } from 'primereact/tooltip';
import { SplitButton } from 'primereact/splitbutton';

import DeviceLastLoginListener from '../../../Services/DeviceLastLoginListener';
import FirebaseDeviceListener from '../../../Services/FirebaseDeviceListener';
import { useGlobalContext } from '../../../Contexts/GlobalContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';
import Rotation from './Rotation';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDevice, setOnlineDevices, setDevice, setSelectedCampaign, setRerender } from '../../../../redux/Slices/deviceSlice';
import { setStepsBack, setIsPrayerCampaign, toggleDeleteDialog, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

function DeviceCard({ data }) {

    const { companyDetails, layer } = useSelector((state) => state.global);
    const onlineDevices = useSelector((state) => state.device.onlineDevices);

    const dispatch = useDispatch();
    const { showToast } = useCustomToast();

    const navigate = useNavigate();

    const [counter, setCounter] = useState(10);
    const [realtimeData, setRealtimeData] = useState({
        "id": "",
        "name": "",
        "is_playing": "",
        "screen_angle": "",
        "is_muted": "",
    })

    useEffect(() => {
        const updatedDevices = {
            [data.serial_name]: data.online,
        };
        dispatch(setOnlineDevices(updatedDevices));
    }, [data])

    useEffect(() => {
        if (counter === 0) {
            setTimeout(() => {
                const updatedDevices = {
                    [data.serial_name]: 0,
                };

                dispatch(setOnlineDevices(updatedDevices));
            }, 1000);
        }


        if (!counter) return;

        // Decrement the counter every second
        const intervalId = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        // Clear interval when the component re-renders or unmounts
        return () => clearInterval(intervalId);
    }, [counter]);


    useEffect(() => {
        if (onlineDevices[data.serial_name]) {
            setCounter(13);
        }
    }, [onlineDevices, data.serial_name]);


    // Delete Handler
    const confirmDeleteDevice = () => {
        dispatch(setDevice(data));
        dispatch(toggleDeleteDialog("device"));
    }

    const openEditDialog = () => {
        dispatch(setDevice(data));
        dispatch(toggleDialog("updateDevice"));
    }

    const openAssignDialog = (data) => {
        dispatch(setDevice(data));
        dispatch(setSelectedDevice(data));
        // Get previous selected campaign for the device
        axiosInstance.get(`/device/${data.id}/info`).then((res) => {
            const prevCampaign = res?.data?.data?.campaigns[0]?.id;
            dispatch(setSelectedCampaign(prevCampaign))
        });
        dispatch(toggleDialog("assignCampaignsDropDown"));
    }

    const openAssignScheduleCampaignDialog = (data) => {
        dispatch(setDevice(data));
        dispatch(setSelectedDevice(data));
        dispatch(toggleDialog("assignScheduleCampaigns"));
    }

    const playPause = (deviceID, action) => {
        axiosInstance.post(
            `/device/${deviceID}/play/pause`,
            {
                "action": action
            }
        ).then((result) => {
            showToast('success', 'Pause Action', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('error', 'Pause Action', error.response?.data?.message);
        });
    }

    const muteUnMute = (deviceID, action) => {
        axiosInstance.post(
            `/device/${deviceID}/mute`,
            {
                "action": action
            }
        ).then((result) => {
            showToast('success', 'Mute Action', result?.data?.data?.msg);
        }).catch((error) => {
            showToast('error', 'Mute Action', error.response?.data?.message);
        });
    }

    const goToCampaign = () => {
        if (realtimeData.id != "") {
            dispatch(setStepsBack(-2))
            axiosInstance.get(`/campaign/${realtimeData.id}`)
                .then((res) => {
                    dispatch(setIsPrayerCampaign(false))
                    sessionStorage.setItem('is_default_prayer', JSON.stringify(false));

                    if (res.data.data.is_interactive)
                        navigate(`/interactive/campaign/${realtimeData.id}/setup`)
                    else
                        navigate(`/campaigns/${realtimeData.id}/setup`)
                })
        }
    }

    const channelHandler = (deviceID) => {
        if (companyDetails?.package?.features?.media_type?.includes("satellite")) {
            let selected_device = {
                id: deviceID,
                serial_name: data.serial_name,
                online: onlineDevices[data.serial_name],
                name: data.name
            }
            dispatch(setSelectedDevice(selected_device));
            dispatch(toggleDialog("createCampaign"));
            navigate("/campaigns/list");
        }
    }

    const flushHandler = () => {
        axiosInstance.get(`/device/${data.id}/screen`)
            .then((res) => {
                showToast('success', 'Device', res?.data?.data?.msg);
            }).catch(error => {
                showToast('error', 'Device', error?.response?.data?.message);
            });
    }

    const restartHandler = () => {
        axiosInstance.get(`/device/${data?.id}/restart`)
            .then((res) => {
                showToast('success', 'Device', res?.data?.data?.msg);

            })
    }

    const goToInfo = (deviceID) => {
        dispatch(setSelectedDevice(data));
        navigate(`/devices/${deviceID}/show`)
    }

    const items = [
        {
            label: <span className="fs-6 ">Assign campaign</span>,
            icon: 'bx bx-up-arrow-alt me-2 text-light',
            command: () => {
                openAssignDialog(data)
            }
        },
        {
            label: <span className="fs-6 "> Assign schedule </span>,
            icon: 'bx bx-time-five me-2 text-light',
            command: () => {
                openAssignScheduleCampaignDialog(data)
            },
            visible: companyDetails?.package?.features?.advance_content_schedule
        },
        {
            label: 'Assign channel',
            icon: 'bx bx-tv me-2 text-light',
            command: () => {
                channelHandler(data.id)
            },
            visible: data?.device_info?.satellite_is_available != 0 && companyDetails?.package?.features?.media_type?.includes("satellite")
        },
        {
            label: <span className="fs-6 ">Edit</span>,
            icon: 'pi pi-pencil text-light me-2',
            command: () => {
                openEditDialog()
            }
        },
        {
            label: <span className="fs-6 ">{realtimeData.is_playing ? 'Pause' : "Play"}</span>,
            icon: realtimeData.is_playing ? "bx bx-pause-circle me-2 text-light" : "bx bx-play-circle me-2 text-light",
            command: () => {
                playPause(data.id, realtimeData.is_playing ? "pause" : "play")
            }
        },
        {
            label: <span className="fs-6 ">{realtimeData.is_muted ? 'UnMute' : "Mute"}</span>,
            icon: realtimeData.is_playing ? "bx bx-volume-mute me-2 text-light" : "bx bx-volume-full me-2 text-light",
            command: () => {
                muteUnMute(data.id, realtimeData.is_muted ? "unmute" : "mute")
            }
        },
        {
            label: <span className="fs-6 ">Flush</span>,
            icon: 'bx bx-help-circle me-2 text-light',
            command: () => {
                flushHandler()
            }
        },
        {
            label: <span className="fs-6 ">Restart</span>,
            icon: 'fas fa-power-off me-2 text-light',
            command: () => {
                restartHandler()
            }
        },
        {
            label: 'Device Info',
            icon: 'bx bx-slideshow me-2 text-light',
            command: () => {
                goToInfo(data.id)
            }
        }
    ];

    return (
        <>
            <Card className='my-2 d-flex flex-row flex-nowrap align-items-center py-3 pe-3 ' style={{ backgroundColor: '#f5f5f9' }}>
                <div className="col-1 text-center">
                    <i className="menu-icon tf-icons bx bx-desktop  fs-1 text-secondary mx-auto "></i>
                </div>
                <div className='col-1 d-flex flex-column'>
                    <h6 className='my-1 fw-bold'>{data.name == "" ? "---" : data.name}</h6>
                </div>
                <div className={`col-3 d-flex ${realtimeData.name == "" ? "" : "cursor-pointer"}`} onClick={() => goToCampaign()}>
                    <div className={`my-1 fw-bold ${realtimeData.name == "" ? "" : "cursor-pointer playing_now_container"} py-1 mx-4 px-2 w-100`} onClick={() => goToCampaign()}>
                        <div className='fs-10 text-light text-uppercase'>now playing</div>
                        <div className='d-flex align-items-center '>
                            <i className="fas fa-bolt fs-8 me-1"></i>
                            <span> {realtimeData.name == "" ? "---" : realtimeData.name}</span>
                        </div>
                    </div>
                </div>

                <div className='col-1 d-flex'>
                    <span className={`text-center p-2 badge ${onlineDevices?.[data?.serial_name] ? "bg-label-success" : "bg-label-danger"}`}>
                        {onlineDevices?.[data?.serial_name] ? "On-line" : "Off-line"}
                    </span>
                </div>
                <div className='col-1 d-flex'>
                    <span className={`text-center p-2  badge ${data.status == "ACTIVE" ? "bg-label-success" : "bg-label-danger"} `}>{data.status}</span>
                </div>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                    <Rotation rowData={data} realtimeData={realtimeData} />

                </div>

                <div className='col-3 d-flex justify-content-end'>
                    {/* Delete */}
                    <Tooltip target={`.delete-button-${data.id}`} showDelay={100} className="fs-8" />
                    <button className={`btn btn-sm btn-icon delete-button-${data.id}`}
                        data-pr-position="bottom" data-pr-tooltip="Delete"
                        onClick={() => confirmDeleteDevice()} >
                        <i className="bx bx-trash text-light"></i>
                    </button>

                    <SplitButton
                        label={null}
                        model={items}
                        className="p-button-text p-button-primary p-button-sm mr-2 mb-2 p-button-secondary device_actions ms-3"
                        buttonClassName="d-none"
                        dropdownIcon="fas fa-ellipsis-v"

                    />

                    {/* Assign channel */}
                    {/* {
                        data?.device_info?.satellite_is_available == 0 ? "" :
                            <>
                                <Tooltip target={ `.assign-channel-${ data.id }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon assign-channel-${ data.id }` }
                                    data-pr-position="bottom"
                                    data-pr-tooltip={ `Assign channel` }
                                    onClick={ () => { channelHandler(data.id) } }
                                    disabled={ !companyDetails?.package?.features?.media_type?.includes("satellite") }
                                >
                                    <i className='bx bx-tv text-light' ></i>
                                </button>
                            </>
                    } */}


                </div>
            </Card>

            {/* Listen to campaign changes on device */}
            <FirebaseDeviceListener
                setRealtimeData={setRealtimeData}
                path={"Devices/" + data.serial_name + "/DeviceState"}
            />

            <DeviceLastLoginListener
                serialName={data.serial_name}
                path={"Devices/" + data.serial_name + "/lastLogin"} />

        </>
    )
}

export default DeviceCard