import React, { useState, useEffect } from 'react';

import '../../../../assets/vendor/css/pages/page-auth.css';
import 'react-phone-input-2/lib/style.css'

import { useProfileContext } from '../../../Contexts/ProfileContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';
import ProfileForm from './ProfileForm';

import { useSelector, useDispatch } from 'react-redux';
import { setProfileImage, toggleLoader } from '../../../../redux/Slices/Global/globalSlice';


function UserProfile() {
    const { newImage } = useProfileContext();
    const companyDetails = useSelector((state) => state.global.companyDetails);
    const dispatch = useDispatch()

    const { showToast } = useCustomToast();

    const userInfo = {
        "name": companyDetails?.user?.name,
        "phone": companyDetails?.user?.phone,
        "email": companyDetails?.user?.email
    };

    const submitHandler = (data) => {
        var formData = new FormData();

        formData.append("name", data.name);
        formData.append("profile_img", newImage.user);
        formData.append("phone", data.phone);

        axiosInstance.post(`/users/update`, formData)
            .then((result) => {


                dispatch(setProfileImage(result.data?.data?.image))
                localStorage.setItem("name", result.data?.data?.name);
                localStorage.setItem("image", result.data?.data?.image);
                localStorage.setItem("phone", result.data?.data?.phone);

                showToast('success', 'Update User Profile', 'Profile Updated Successfully!');
                dispatch(toggleLoader("companyDetails"))

            }).catch((error) => {
                showToast('error', 'Update User Profile', error?.response?.data?.message);
            });
    };

    return (
        <ProfileForm profileType={"user"} submitHandler={submitHandler} data={userInfo} />
    )
}

export default UserProfile