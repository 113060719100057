
import axios from 'axios';

//Get timezone 
const date = new Date();
const englishTimeZoneFormatter = new Intl.DateTimeFormat('en', {
    timeZoneName: 'long'
});
const englishTimeZone = englishTimeZoneFormatter.formatToParts(date)
    .find(part => part.type === 'timeZoneName')
    .value;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    if (token) {
        config.headers['Authorization'] = `${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['timezone'] = englishTimeZone;
    return config;
});

axiosInstance.defaults.baseURL = process.env.REACT_APP_NOT_SECRET_CODE;

export default axiosInstance;

axiosInstance.interceptors.response.use((response) => response,
    (error) => {

        if (error?.response?.status === 402) {
            window.location.href = '/profile';
        }

        if ((error.response.status == 401 || error.response.status == 403) &&
            !window.location.pathname.includes("login")) {
            localStorage.clear();
            window.location.href = '/login';
        }

        return Promise.reject(error);
    })
