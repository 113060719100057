import React, { useContext, createContext, useState, useEffect, useCallback, useMemo } from "react"
import moment from "moment";

import avatar from "../../assets/img/avatar.webp"

import { useSelector } from 'react-redux';

const Profile = createContext({});

export const ProfileProvider = (props) => {
    const { companyDetails, packages } = useSelector((state) => state.global);

    const [showUpgrade, setShowUpgrade] = useState();

    /*------------------------Account Controls---------------------------*/
    const defaultAvatar = localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image");

    const [previewImg, setPreviewImg] = useState({
        user: defaultAvatar,
        company: companyDetails?.company?.logo != null ? companyDetails?.company?.logo : avatar
    });
    const [newImage, setNewImage] = useState({
        user: defaultAvatar,
        company: companyDetails?.company?.logo
    });

    /*------------------------Subscription Info---------------------------*/
    const [mediaUsageInfo, setMediaUsageInfo] = useState({
        mediaUsage: 0,
        allowedTotal: 0,
    });

    /*----------------------------Payment Control-------------------*/
    // Billing data
    const [numberOfScreens, setNumberOfScreens] = useState(1);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [selectedPricing, setSelectedPricing] = useState({});
    const [action, setAction] = useState("");

    const [paymentData, setPaymentData] = useState({
        subtotal: 0,
        total: 0,
        tax: 0,
    });

    useEffect(() => {
        setNumberOfScreens(companyDetails?.company?.allowed_device_number)
        // Handle Media Usage
        let _packageMediaStorage = companyDetails?.package?.features["media_storage_capacity"];
        let _companyMediaStorage = companyDetails?.company?.allowed_media_usage / Math.pow(1024, 3);

        setMediaUsageInfo({
            mediaUsage: companyDetails?.media_usage,
            allowedTotal: _packageMediaStorage > 1000 ? "unlimited" : Math.max(_packageMediaStorage, _companyMediaStorage),
        });

        setSelectedPackage(companyDetails?.package)
    }, [companyDetails]);

    useEffect(() => {
        setSelectedPricing(prev => selectedPackage?.prices?.find(pricing => pricing.period == prev?.period))
    }, [selectedPackage]);


    useEffect(() => {
        var _singleScreenPrice = selectedPricing?.price;
        var _period = selectedPricing?.period
        var _subtotal = selectedPricing?.price * numberOfScreens

        if (action == "switchToYearly")
            setNumberOfScreens(companyDetails.company?.allowed_device_number)

        if (action == "addScreens") {
            let totalSubscriptionDays = moment(companyDetails?.subscription?.end_date).diff(moment(companyDetails?.subscription?.start_date), 'days');
            var remindingDays = (moment(companyDetails?.subscription?.end_date)).diff(moment(), 'days') + 1;

            _period = remindingDays + " day" + (remindingDays > 1 ? "s" : "")
            _subtotal = (selectedPricing?.price / totalSubscriptionDays) * remindingDays * numberOfScreens;
            _singleScreenPrice = (selectedPricing?.price / totalSubscriptionDays) * remindingDays
        }

        let _tax = _subtotal * (selectedPackage?.tax / 100);

        setPaymentData({
            singleScreenPrice: _singleScreenPrice?.toFixed(2),
            subtotal: _subtotal.toFixed(2),
            period: _period,
            total: (_subtotal + _tax).toFixed(2),
            tax: _tax.toFixed(2),
        })

    }, [selectedPricing, numberOfScreens, action]);

    const onScreenChange = useCallback(
        (action, value = 1) => {
            switch (action) {
                case 'increment':
                    setNumberOfScreens((prevCount) => prevCount + 1);
                    break;
                case 'decrement':
                    setNumberOfScreens((prevCount) => prevCount - 1);
                    break;
                case 'onChange':
                    setNumberOfScreens(value);
                    break;
                default:
                    setNumberOfScreens(1);
                    break;
            }
        },
        [setNumberOfScreens]
    );

    return (
        <Profile.Provider value={{
            // account
            previewImg, setPreviewImg,
            newImage, setNewImage,
            // subscription
            mediaUsageInfo, setMediaUsageInfo,

            // payment
            selectedPackage, setSelectedPackage,
            selectedPricing, setSelectedPricing,
            numberOfScreens, onScreenChange,
            action, setAction,
            paymentData,
            showUpgrade, setShowUpgrade

        }}>
            {props.children}

        </Profile.Provider>
    )

}

export const useProfileContext = () => {
    return useContext(Profile)
}
