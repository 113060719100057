import React, { useEffect, useState, useRef } from 'react'

import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';

import { useCustomToast } from '../../../Contexts/ToastContext';
import { formatDuration } from '../../../../config/global';
import axiosInstance from '../../../../config/Axios';

import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader, setRerenderList } from '../../../../redux/Slices/Global/globalSlice';
import _ from 'lodash';

function MediaDuration({ item }) {
    const dispatch = useDispatch();
    const subLoaders = useSelector((state) => state.global.subLoaders);
    const { showToast } = useCustomToast();

    const [editDuration, setEditDuration] = useState(false);
    const [duration, setDuration] = useState(item.total_duration);
    const [selectedMedia, setSelectedMedia] = useState("");
    const [send, setSend] = useState(false);

    const previousDuration = useRef(item.total_duration);

    const sendOnkeypress = (e) => {
        setEditDuration(false)
        setSend(true)
    }

    const retrieveDefaultValue = () => {
        dispatch(toggleLoader("durationLoader"))

        axiosInstance.put(`/campaign/media/${item.campaign_playlist_media_id}/duration`, {}
        ).then((result) => {
            setEditDuration(false)
            dispatch(toggleLoader("durationLoader"))
            dispatch(setRerenderList(true));
            showToast('success', 'Retrieve Media duration', result?.data?.data?.msg);
        }).catch((error) => {
            if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Retrieve Media duration', error[0]);
                });
        });
    }

    const editDurationHandle = () => {
        if (!JSON.parse(sessionStorage.getItem('is_default_prayer'))) {
            setSelectedMedia(item.id)
            setEditDuration(true)
        }
    }

    // Duration Handler
    useEffect(() => {
        if (send && previousDuration.current != duration) {
            dispatch(toggleLoader("durationLoader"))
            axiosInstance.put(
                `/campaign/playlist-media/${item.campaign_playlist_media_id}/update`,
                {
                    "total_duration": duration,
                    "layer": item.layer
                }
            ).then((result) => {
                item.total_duration = duration
                dispatch(setRerenderList(true));
                setSelectedMedia("")
                dispatch(toggleLoader("durationLoader"))
            }).catch((error) => {
                if (error?.response?.data?.errors)
                    Object.values(error?.response?.data?.errors).forEach(error => {
                        showToast('error', 'Update Playlist Content Details', error[0]);
                    });
            });
        }
        setSend(false)
    }, [send])


    return (
        <>
            {
                editDuration ?
                    <div className=' d-flex align-items-center justify-content-center'>
                        <InputNumber inputId="minmax" mode="decimal"
                            value={item.total_duration}
                            onValueChange={(e) => setDuration(e.value)}
                            onChange={(e) => setDuration(e.value)}
                            onKeyDown={(e) => e.key === 'Enter' ? sendOnkeypress(e) : ""}
                            min={0} className="duration_input" />

                        <div className="d-flex flex-column">
                            <Tooltip target={`.save_duration-${item.id}`} content="Save changes" position='bottom' className='fs-8' />
                            <i className={`col-1 mx-2 fas fa-check-circle mx-1 text-lighter mb-2 cursor-pointer save_duration-${item.id}`} onClick={() => {
                                setEditDuration(false)
                                setSend(true)
                            }}></i>

                            <Tooltip target={`.retrieve_duration-${item.id}`} content="Retrieve duration" position='bottom' className='fs-8' />
                            <i className={`col-1 mx-2 fas fa-redo mx-1 text-lighter cursor-pointer retrieve_duration-${item.id}`} onClick={retrieveDefaultValue}> </i>

                        </div>
                    </div>
                    :
                    (_.isNull(item.media_id)) ?
                        <div className='text-light text-center'>
                            {!_.isNull(item.playlist_id) ? formatDuration(item.playlist_duration) : formatDuration(item.layout_duration)}
                        </div>
                        :
                        subLoaders.durationLoader && selectedMedia == item.id ?
                            <div className="cursor-pointer  text-center" >
                                <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="2s" />
                            </div>
                            :
                            <>
                                <Tooltip target={`.edit_duration-${item.id}`} content="Edit duration" position='bottom' className="fs-8" />
                                <div className={`edit_duration-${item.id} cursor-pointer  text-center`} onClick={() => editDurationHandle()}> {formatDuration(item.total_duration)}</div>
                            </>
            }
        </>
    )
}

export default MediaDuration