import React, { useEffect, useState } from 'react'
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import axiosInstance from '../../../../../config/Axios';

import AreaControls from "./AreaControls";
import DynamicContainer from './DynamicContainer';

import { useCustomToast } from '../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../../../redux/Slices/Global/globalSlice';
import { addToUserTemplate, setReloadTrigger } from '../../../../../redux/Slices/layoutSlice';

const colors = [
    "#339ecccc",
    "#2f99c6cc",
    "#2a93c0cc",
    "#268ebacc",
    "#2288b4cc",
    "#1f83adcc",
    "#1b7da7cc",
    "#1877a0cc",
    "#157199cc",
    "#126b91cc",
    "#0f658acc",
    "#0d5f82cc",
    "#0b597acc",
    "#095272cc",
    "#074c69cc",
    "#054561cc",
    "#043f58cc",
    "#02384fcc",
    "#013146cc",
    "#012b3dcc",
];

function TemplateCustomizationPopUp() {
    const dispatch = useDispatch()
    const { showToast } = useCustomToast();

    const { openDialog, subLoaders, disableBtn } = useSelector((state) => state.global);
    const selectedTemplate = useSelector((state) => state.layout.selectedTemplate);

    const [layoutName, setLayoutName] = useState({ isEditing: false, name: "" });
    const { control, handleSubmit } = useForm();
    const [overlappingAreas, setOverlappingAreas] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [areas, setAreas] = useState([]);
    const [currentAspectRatio, setCurrentAspectRatio] = useState({});
    const [titleError, setTitleError] = useState("");

    useEffect(() => {
        const newAreas = selectedTemplate.screens_setting;
        setAreas(newAreas)
        setLayoutName({ isEditing: true, name: "" });
    }, [])

    const handleAddArea = () => {
        const newArea = {
            id: areas.length + 1,
            name: `Screen ${areas.length + 1}`,
            start_margin: 0,
            top_margin: 0,
            width: 100,
            height: 100,
        };
        setAreas([...areas, newArea]);
    };

    const onTitleSubmit = (data) => {
        setLayoutName({ isEditing: false, name: data.layoutName });
    };

    const handleCreateTemplate = () => {
        if (layoutName.isEditing || !layoutName.name.trim()) {
            setTitleError("Title is required , save it to proceed");
            return;
        }

        setTitleError("");
        const sanitizedAreas = areas.map(({ isEditing, ...rest }) => rest);
        axiosInstance.post(`/layout/create`,
            {
                "title": layoutName?.name,
                "screens_setting": sanitizedAreas,
                "type": "CUSTOM",
                "aspect_ratio_id": currentAspectRatio.id

            })
            .then((result) => {
                const theNewLayout = {
                    "title": layoutName?.name,
                    "screens_setting": sanitizedAreas,
                }

                // dispatch(addToUserTemplate(theNewLayout));
                dispatch(toggleDialog("TemplateCustomizationPopUp"));
                dispatch(setReloadTrigger(true))
                showToast('success', "Layout Creation", "Layout Template created successfully!");

            })
            .catch((error) => {
                dispatch(setDisableBtn(false));
            })
    };

    return (
        <Dialog visible={openDialog.TemplateCustomizationPopUp}
            breakpoints={{ '960px': '95vw' }}
            header="Customize Your Layout"
            modal
            className="p-fluid w-full md:w-[80%] lg:w-[65%]  h-[85%] overflow-hidden"
            onHide={() => dispatch(toggleDialog("TemplateCustomizationPopUp"))}
        >
            <div className="overflow-hidden h-full">

                <div className="flex flex-col items-center  mt-1 w-100">
                    <div className="w-100 mb-3 pl-5 pr-5 flex flex-col lg:flex-row gap-10">
                        {/* Preview Area */}
                        <DynamicContainer
                            areas={areas}
                            colors={colors}
                            currentAspectRatio={currentAspectRatio}
                            setCurrentAspectRatio={setCurrentAspectRatio}

                        />

                        {/* Controls */}

                        <div className="flex flex-col w-full lg:w-1/2  mr-3">
                            <div className="mt-5 flex flex-col w-100 lg:w-1/2">
                                <label className="text-sm font-medium mb-1">Title</label>
                                <div className="d-flex mb-3 ">
                                    {layoutName.isEditing ? (
                                        <>
                                            <form className="flex mt-1 w-100" onSubmit={handleSubmit(onTitleSubmit)}  >
                                                <div className="flex flex-col w-full">
                                                    <Controller name="layoutName" control={control} rules={{
                                                        required: "Title is required",
                                                        minLength: { value: 3, message: "Title must be at least 3 characters" },
                                                    }}
                                                        render={({ field, fieldState }) => (
                                                            <>
                                                                <InputText
                                                                    {...field}
                                                                    placeholder="My custom layout"
                                                                    className={`p-2 border rounded-lg  ${fieldState.error ? "border-red-500" : "border-gray-300"
                                                                        }`}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                    }}
                                                                />
                                                                {fieldState.error && (
                                                                    <span className="text-red-500 text-sm">{fieldState.error.message}</span>
                                                                )}
                                                                {titleError && <span className="text-red-500 text-sm">{titleError}</span>}

                                                            </>
                                                        )}
                                                    />
                                                </div>
                                                <Button label="" icon="fa-solid fa-check" className="p-0 me-2 bg-transparent text-black border-transparent " type="submit" />
                                            </form>
                                        </>
                                    ) : (
                                        <>
                                            <span className="">{layoutName.name}</span>
                                            <Button
                                                label=""
                                                icon="fa-solid fa-pen"
                                                className="p-0 me-2 bg-white text-black border-white"
                                                onClick={() =>
                                                    setLayoutName((prev) => ({ ...prev, isEditing: true }))
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col w-100 lg:w-1/2">
                                <AreaControls
                                    areas={areas}
                                    setAreas={setAreas}
                                    isSaveDisabled={isSaveDisabled}
                                    overlappingAreas={overlappingAreas}
                                    setOverlappingAreas={setOverlappingAreas}
                                    setIsSaveDisabled={setIsSaveDisabled}
                                />
                                {overlappingAreas.length > 0 && (
                                    <div className=" flex mt-3 text-white font-medium mb-3 alert bg-orange-300" role="alert">
                                        <i className="ml-3 mr-3 mt-4 fa-solid fa-triangle-exclamation fa-lg"></i>
                                        <div className="ml-3">
                                            These zones are being overlapped by some other zone:{" "}
                                            {overlappingAreas.join(", ")}.
                                            <br />
                                            Please adjust the zones to avoid overlapping.
                                        </div>
                                    </div>
                                )}
                                <Button label="Add Area" icon="fas fa-plus" className={"mt-3 pt-1 pb-1 me-2 text-[15px] bg-blue text-white"} onClick={() => handleAddArea()} />
                                {/* Action Buttons */}
                                <div className="mt-3 flex justify-end gap-3 mb-3">
                                    <Button label="Save" className={"pt-1 pb-1 me-2"} disabled={isSaveDisabled} onClick={() => handleCreateTemplate()} />
                                    <Button label="Cancel" className={"pt-1 pb-1 me-2 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => dispatch(toggleDialog("TemplateCustomizationPopUp"))} />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Dialog>
    )
}

export default TemplateCustomizationPopUp