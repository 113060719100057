import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useTitle, formatDuration, } from "../../../../config/global";
import "../../css/setup.css";

import AddContentPopup from '../../../SharedComponents/AddContentPopup/AddContentPopup';
import AssignPopup from '../AssignToDevicePopup';
import DragDrop from '../../../SharedComponents/DragDrop/DragDrop';
import Layout from '../../../Layout/Layout'

import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import { setHideTabs, setCampaignDetails, setIsPublished, setLayer, setType, setRerenderList, toggleDialog } from '../../../../redux/Slices/Global/globalSlice';


function CampaignSetup() {
  useTitle("Campaign Setup")

  const dispatch = useDispatch();
  const { showToast } = useCustomToast();
  const { isPrayerCampaign, campaignDetails, openDialog, layerOptions, layer, stepsBack, rerenderList } = useSelector((state) => state.global);

  const [orderLoading, setOrderLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { id } = useParams();

  //  Responsive dropdown actions menu
  const options = [
    {
      label: 'Add Content',
      icon: 'pi pi-plus',
      command: () => {
        dispatch(toggleDialog("addContent"))
      }
    }, {
      label: 'Assign',
      icon: 'bx bx-up-arrow-alt',
      command: () => {
        dispatch(toggleDialog("assignDeviceGroup"))
      }
    },
    {
      label: 'Publish',
      icon: '',
      command: () => {
        publish()
      }
    }

  ];

  // Get Campaign Details 
  useEffect(() => {
    setLayer("MAIN")
    axiosInstance.get(`/campaign/${id}`)
      .then((res) => {
        dispatch(setCampaignDetails(res.data.data));
      })

    dispatch(setHideTabs(false))

  }, [])

  // Fetch Layer data 
  function getDate() {
    dispatch(setRerenderList(false))
    axiosInstance.get(`/campaign/content/${id}/${layer}`)
    .then((res) => {
      setData(res.data.data);
      setLoading(false);
      setOrderLoading(false)
    })
    dispatch(setRerenderList(false))
  }

  // Get layer media on layer change
  useEffect(() => {
    setLoading(true)
    if (layer == "BANNER")
      dispatch(setType("rss"))
    else
      dispatch(setType("media"))

    if (layer || rerenderList) {
      getDate()
    }
  }, [layer]);

  // Get layer media after update it
  useEffect(() => {
    if (rerenderList) {
      updateStatus("DRAFT");
      dispatch(setIsPublished(false))
    }
  }, [rerenderList]);

  // Publish all campaign content from draft 
  const publish = () => {
    setLoading(true);
    dispatch(setIsPublished(true))
    axiosInstance.post(`/campaign/${id}/publish`, {})
      .then((res) => {
        getDate()
        showToast('success', 'Publish Campaign', res?.data?.data?.msg);
      })

    updateStatus("PUBLISHED");
  }

  // Update campaign status DRAFT/PUBLISHED
  const updateStatus = (status) => {
    axiosInstance.put(`/campaign/${id}/update`,
      {
        name: campaignDetails.name,
        status: status
      }
    ).then((res) => {
      getDate()
      dispatch(setCampaignDetails(res.data.data));
    })
  }

  const goBack = () => {
    window.history.go(stepsBack)
  }

  return (
    <>
      <Layout>
        <div className="d-flex justify-content-between mb-3">
          <div className="col-8 col-md-6 d-flex">

            <Tooltip target='.back-button' showDelay={100} className="fs-8" />
            <Button label="" icon="fas fa-arrow-left" className='py-2 me-2 back-button'
              data-pr-position="bottom" data-pr-tooltip="Go back"
              onClick={goBack} disabled={loading} />

            <Dropdown value={layer} options={layerOptions} optionLabel="name" optionValue="code" className='w-50 ' onChange={(e) => dispatch(setLayer(e.value))} />

            {
              // total duration for main layer
              layer == "MAIN" && !loading &&
              <div className='mx-3 d-none d-sm-block'>
                <p className="mb-0 ">Total Duration</p>
                <div className='fw-bold'> {formatDuration(campaignDetails.campaign_duration)}</div>
              </div>
            }

          </div>
          {
            !JSON.parse(sessionStorage.getItem('is_default_prayer')) &&
            <>
              <div className='d-none d-md-flex'>
                <Button label={orderLoading ? <ProgressSpinner className='btn-spinner py-0' style={{ width: '20px', height: '20px' }} strokeWidth="2" fill="transparent" animationDuration="2s" /> : "Publish"}
                  className={` fs_md_6 ${orderLoading ? "pt-2 pb-1 me-2" : "py-2 me-2 "}`}
                  disabled={loading ? true : (campaignDetails.status == "DRAFT" ? false : true)}
                  onClick={publish} />
                <Button label="Assign" icon="bx bx-up-arrow-alt" className={`py-2 me-2 ${isPrayerCampaign ? "d-none" : ""}`} onClick={() => dispatch(toggleDialog("assignDeviceGroup"))} disabled={loading ? true : false} />
                <Button label="Add Content" icon="pi pi-plus" className='py-2' onClick={() => dispatch(toggleDialog("addContent"))} disabled={loading ? true : false} />
              </div>

              <SplitButton label="Option" model={options} className="p-button-sm mr-2 mb-2 d-flex d-md-none" />
            </>
          }
        </div>
        {
          data.length == 0 && !loading ?
            <h5 className='w-100 card d-flex align-items-center text-center p-5 mt-5'>
              There is no media assigned yet !
            </h5>
            :
            ""
        }
        {loading ?
          <div className='w-100 h-100 d-flex align-items-center' >
            <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
          </div>
          :
          <DragDrop updateStatus={updateStatus} data={data} setOrderLoading={setOrderLoading} />
        }

        {
          openDialog.addContent && <AddContentPopup updateStatus={updateStatus} loading={loading} />
        }
        {
          openDialog.assignDeviceGroup && <AssignPopup updateStatus={updateStatus} campaignId={id} />
        }

      </Layout>
    </>
  )
}

export default CampaignSetup