import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";

import BreadCrumb from "../../Media/components/MediaLayout/BreadCrumb";
import MediaCard from "../../Media/components/MediaLayout/MediaCard";
import FolderCard from "../../Media/components/MediaLayout/FolderCard";

import { useCustomToast } from "../../Contexts/ToastContext";
import axiosInstance from "../../../config/Axios";

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog, setAssign, setRerenderList } from '../../../redux/Slices/Global/globalSlice';

function MediaList() {
  const dispatch = useDispatch();
  const { assign, layer, campaignDetails } = useSelector((state) => state.global);
  const screenDetails = useSelector((state) => state.layout.screenDetails);

  const { showToast } = useCustomToast();

  const [currentFolder, setCurrentFolder] = useState({});
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { id } = useParams();

  const getData = (folder_id = "", searchTerm = "") => {
    setLoading(true);

    let url = `/media?section=MEDIA&layer=${layer}&is_interactive=${campaignDetails.is_interactive ? 1 : 0}`;

    if (folder_id !== "") { url += `&folder_id=${folder_id}`; }

    if (searchTerm.trim() !== "") { url += `&search=${encodeURIComponent(searchTerm)}`; }

    axiosInstance.get(url)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
        setRerender(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (ids.length == 0) {
  //     dispatch(setDisableBtn(true))
  //   } else {
  //     dispatch(setDisableBtn(false))
  //   }
  // }, [ids])

  useEffect(() => {
    getData("", searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (assign) {
      dispatch(setDisableBtn(true))

      if (window.location.pathname.includes("playlist")) {
        assignHandler(
          "/playlist/media/assign",
          "Playlist",
          {
            "list_media_ids": ids,
            "playlist_id": id,
            "layer": layer,

          });
      } else {
        assignHandler(
          "/campaign/content/assign",
          "Campaign",
          {
            "ids_list": ids,
            "campaign_id": window.location.pathname.includes("layout") ? screenDetails.id : id,
            "layer": layer,
            "type": "media",
          });
      }
    }
  }, [assign]);

  const assignHandler = (url, type, data) => {
    axiosInstance.post(url, data)
      .then((result) => {
        dispatch(setDisableBtn(false));
        showToast('success', `Assign Media to ${type}`, result?.data?.data?.msg);
        dispatch(setRerenderList(true));
        dispatch(toggleDialog("addContent"))
      })
      .catch((error) => {
        dispatch(setDisableBtn(false));
        if (error?.response?.status) {
          showToast('error', `Assign Media to ${type}`, error?.response?.data?.message);
        }
        if (error?.response?.data?.errors)
          Object.values(error?.response?.data?.errors).forEach((error) => {
            showToast('error', `Assign Media to ${type}`, error[0]);
          });
      });
    dispatch(setAssign(false));
  };

  const onMediaChange = (e) => {
    let selectedMedia = [...ids];

    if (e.checked) selectedMedia.push(e.value);
    else selectedMedia.splice(selectedMedia.indexOf(e.value), 1);

    setIds(selectedMedia);
  };

  //fetch data  when mount component
  useEffect(() => {
    setCurrentFolder({
      id: "",
      name: "",
    });
    setSearchTerm("")
  }, []);

  return (
    <>
      <div className="d-flex justify-content-start my-3 ml-5">
        <input
          type="text"
          className="form-control w-100"
          placeholder="Search Media..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="btn btn-primary mx-2 me-3"
          onClick={() => getData("", searchTerm)} // Trigger the search
        >
          Search
        </button>
      </div>

      <div className="d-flex flex-column  scroll_container scroll_div px-3  w-100">
        {
          breadCrumb.length != 0 &&
          <div className=" my-3 d-flex justify-content-start w-75">
            <BreadCrumb
              setMedia={setData}
              getData={getData}
              breadCrumb={breadCrumb}
              setBreadCrumb={setBreadCrumb}
              setCurrentFolder={setCurrentFolder}
            />
          </div>
        }
        <div className="d-flex flex-wrap py-3 w-100">
          {
            loading ?
              <ProgressSpinner
                style={{ width: "60px", height: "60px" }}
                strokeWidth="3"
                fill="var(--surface-ground)"
                animationDuration="1s" />
              :
              data.length == 0 ?
                <h5 className="py-5 text-center w-100">No media assigned yet!</h5>
                :
                <>
                  {
                    data.map((item, index) => {
                      return (
                        <div key={item.id} className={`col-12 media_list ${index + 1 != data.length ? "border-bottom border-gray" : ""} mx-2 py-2 media position-relative`} >
                          {
                            item.hasOwnProperty("path") ?
                              <div className="col-12 d-flex align-items-center justify-content-between my-1 " key={`${item.type}-${index}`}>
                                <label htmlFor={item.id} className="col-11 d-flex align-items-center" >
                                  <MediaCard item={item} displayAction={false} />
                                </label>
                                <Checkbox inputId={item.id} name="media" className="me-3" value={item.id} onChange={onMediaChange} checked={ids.indexOf(`${item.id}`) !== -1} />
                              </div>
                              :
                              <>
                                <div className="col-12  d-flex align-items-center my-1 " key={`${item.type}-${index}`} >
                                  <FolderCard
                                    setCurrentFolder={setCurrentFolder}
                                    displayAction={false}
                                    getData={getData}
                                    item={item}
                                    setRerender={setRerender}
                                    layout="list"
                                    setBreadCrumb={setBreadCrumb}
                                    breadCrumb={breadCrumb}
                                  />
                                </div>
                              </>
                          }
                        </div>
                      )
                    })
                  }
                </>
          }
        </div>
      </div>
    </>
  );
}

export default MediaList;
