import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';

import axiosInstance from "../../../../../config/Axios";
import { useCustomToast } from '../../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../../redux/Slices/Global/globalSlice';

const DynamicContainer = ({ areas, colors, currentAspectRatio, setCurrentAspectRatio }) => {
    const { showToast } = useCustomToast();
    const subLoaders = useSelector((state) => state.global.subLoaders);
    const dispatch = useDispatch()
    const selectedTemplate = useSelector((state) => state.layout.selectedTemplate);
    const selectedLayout = useSelector((state) => state.layout.selectedLayout);


    const [aspectWidth, setAspectWidth] = useState();
    const [aspectHeight, setAspectHeight] = useState();
    const [aspectRatios, setAspectRatios] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            aspectName: "",
            width: "",
            height: ""
        }
    });
    const aspectRatio =
        aspectWidth > aspectHeight
            ? "Landscape"
            : aspectWidth < aspectHeight
                ? "Portrait"
                : "Square";

    useEffect(() => {
        dispatch(toggleLoader("createLoading"))
        axiosInstance.get(`/aspect-ratio`)

            .then((res) => {
                const data = res.data.data;
                setAspectRatios(data)
                if (selectedTemplate?.aspect_ratio_id) {
                    const selectedAspect = data.find(item => item.id === selectedTemplate.aspect_ratio_id);
                    setCurrentAspectRatio(selectedAspect);
                } else if (selectedLayout?.aspect_ratio_id) {
                    const selectedAspect = data.find(item => item.id === selectedLayout.aspect_ratio_id);
                    setCurrentAspectRatio(selectedAspect);
                } else {
                    setCurrentAspectRatio(data[1])
                }
                setAspectWidth(currentAspectRatio.width)
                setAspectHeight(currentAspectRatio.height)
                dispatch(toggleLoader("createLoading"))

            })

    }, []);

    useEffect(() => {
        if (currentAspectRatio) {
            setAspectWidth(currentAspectRatio.width)
            setAspectHeight(currentAspectRatio.height)
        }
    }, [currentAspectRatio]);

    const handleAspectChangeChange = (e) => {

        const selectedValue = e.target.value;
        const selectedAspect = aspectRatios.find(item => item.id === selectedValue);
        setCurrentAspectRatio(selectedAspect);
    }


    const handleCreateNewAspect = (data) => {
        const newAspect = {
            name: data.aspectName,
            width: parseInt(data.width, 10),
            height: parseInt(data.height, 10),
        };
        createAspectRation(newAspect);
    };

    const createAspectRation = (newAspect) => {
        axiosInstance.post(`/aspect-ratio/create`,
            {
                "name": newAspect.name,
                "width": newAspect.width,
                "height": newAspect.height,
            })
            .then((result) => {
                const newAspectRatio = result.data.data;
                setAspectRatios((prevAspectRatios) => [...prevAspectRatios, newAspectRatio]);
                setCurrentAspectRatio(result.data.data)
                showToast('success', "Aspect Ratio Creation", "Aspect Ratio created successfully!");
                setShowCreateModal(false)
                reset();

            })

    }

    // the aspect on each area
    const calculateAspectRatio = (width, height) => {
        const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
        const divisor = gcd(width, height);
        return `${width / divisor}:${height / divisor}`;
    };

    // 4 the scaled dimensions
    const calculateScaledDimensions = () => {
        if (aspectWidth === 0 || aspectHeight === 0) return { width: 0, height: 0 };

        const widthRatio = 542 / aspectWidth;
        const heightRatio = 540 / aspectHeight;

        const scaleFactor = Math.min(widthRatio, heightRatio);

        const scaledWidth = aspectWidth * scaleFactor;
        const scaledHeight = aspectHeight * scaleFactor;

        return { width: scaledWidth, height: scaledHeight };
    };

    const { width: scaledWidth, height: scaledHeight } = calculateScaledDimensions();


    return (
        <>{
            subLoaders.createLoading ?
                <div className="col-12 d-flex justify-content-center p-4 border-top border-gray">
                    <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                </div>
                :

                <div className={`overflow-auto w-100 mt-5 `}>
                    <div className="text-sm font-medium mb-2">Aspect Ratio: {currentAspectRatio.width} x {currentAspectRatio.height} </div>

                    <div className="flex w-100">
                        <Dropdown
                            value={currentAspectRatio?.id || ""}
                            options={aspectRatios}
                            optionLabel="name"
                            optionValue="id"
                            filter
                            className="mb-3 w-75"
                            onChange={handleAspectChangeChange}
                            placeholder="Select a screen"
                        />
                        <button
                            className="px-1 py-0 w-25 ml-2 h-[43px] text-[15px] bg-[#6366F1] text-white rounded-lg hover:bg-blue-500 hover:text-white focus:outline-none transition-colors duration-300 cursor-pointer"
                            onClick={() => setShowCreateModal(true)}
                        >
                            New Aspect Ratio
                        </button>
                    </div>
                    {showCreateModal && (
                        <div className="z-10 fixed top-0 left-0 w-full h-full   flex justify-center items-center"
                            style={{ backgroundColor: 'rgb(107 114 128 / 29%)' }}>
                            <div className="bg-white p-6 rounded-md w-[300px]">
                                <h2 className="text-lg font-bold mb-4">Create New Aspect Ratio</h2>
                                <form onSubmit={handleSubmit(handleCreateNewAspect)}>
                                    <div className="mb-3">
                                        <label className="block text-sm mb-2">Aspect Name:</label>
                                        <Controller
                                            name="aspectName"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Aspect name is required.",
                                                minLength: { value: 3, message: "Minimum 3 characters required." },
                                            }}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="border p-2 w-full"
                                                    placeholder="Enter aspect name"
                                                />
                                            )}
                                        />
                                        {errors.aspectName && (
                                            <p className="text-red-500 text-sm">{errors.aspectName.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="block text-sm mb-2">Width:</label>
                                        <Controller
                                            name="width"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Width is required.",
                                                min: { value: 1, message: "Width must be at least 1." },
                                                max: { value: 10000, message: "Width cannot exceed 10000." },
                                            }}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="number"
                                                    className="border p-2 w-full"
                                                    placeholder="Enter width"
                                                />
                                            )}
                                        />
                                        {errors.width && (
                                            <p className="text-red-500 text-sm">{errors.width.message}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="block text-sm mb-2">Height:</label>
                                        <Controller
                                            name="height"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "Height is required.",
                                                min: { value: 1, message: "Height must be at least 1." },
                                                max: { value: 10000, message: "Height cannot exceed 10000." },
                                            }}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="number"
                                                    className="border p-2 w-full"
                                                    placeholder="Enter height"
                                                />
                                            )}
                                        />
                                        {errors.height && (
                                            <p className="text-red-500 text-sm">{errors.height.message}</p>
                                        )}
                                    </div>
                                    <div className="flex justify-end space-x-2">
                                        <Button label="Create" className={"pt-1 pb-1 me-2"} type="submit" />
                                        <Button label="Cancel" className={"pt-1 pb-1 me-2 bg-gray-500 text-white rounded hover:bg-gray-600"} onClick={() => setShowCreateModal(false)} />


                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                    }
                    <div className={`overflow-auto flex relative pb-5 w-full`}>
                        <div
                            className={`p-4 relative border border-gray-300 rounded-md   `}
                            style={{
                                width: scaledWidth,
                                // height: scaledHeight,
                                // width: "100%",
                                height: scaledHeight,
                            }}
                        >
                            {areas.map((area, index) => {
                                const aspectRatioText = calculateAspectRatio(area.width, area.height);
                                return (

                                    <div
                                        key={area.id}
                                        className="flex justify-center text-[black] items-center absolute border border-black text-black text-center text-sm"
                                        style={{
                                            left: `${area.start_margin}%`,
                                            top: `${area.top_margin}%`,
                                            width: `${area.width}%`,
                                            height: `${area.height}%`,
                                            backgroundColor: `${colors[index]}`,
                                        }}
                                    >
                                        {index + 1}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                bottom: 3,
                                                left: 3,
                                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                borderRadius: '5px',
                                                padding: '1px  3px',
                                                fontSize: '0.8em',
                                            }}
                                        >
                                            {aspectRatioText}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div >
        }</>
    );
};

export default DynamicContainer;
