import React, { useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';

import primary3DIcon from '../../assets/img/3d_primary.png'
import "./css/apps.css"

import { IoNewspaperOutline } from "react-icons/io5";

import Layout from '../Layout/Layout'
import { Image } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { } from '../../redux/Slices/Global/globalSlice';

const _apps = [
  {
    featureKey: "prayer_app",
    title: "Prayer",
    icon: <i className="fas fa-mosque fs-4"></i>,
    url: "/apps/prayer",
  },
  {
    featureKey: "live_meeting_access",
    title: "Broadcast ",
    icon: <i className="fas fa-broadcast-tower fs-4"></i>,
    url: "/apps/broadcast",
  },
  {
    featureKey: "rss_feed",
    title: "RSS Feeds ",
    icon: <i className="fa-solid fa-rss fs-4"></i>,
    url: "/apps/rss",
  },
  {
    featureKey: "rss_feed",
    title: "Custom RSS",
    icon: <IoNewspaperOutline className='fs-4' />,
    url: "/apps/rss/custom",
  },
  {
    featureKey: "3d",
    title: "Multi-media 3D Viewer",
    icon: <Image src={primary3DIcon} width="20" />,
    url: "/apps/3d/viewer",
  },
  {
    featureKey: "layouts",
    title: "Layouts",
    icon: <i class="fa-solid fa-pen-nib"></i>,
    url: "/apps/layouts",
  }
]

function Apps() {
  const { companyDetails, subscriptionInfo } = useSelector((state) => state.global);

  var features = companyDetails?.package?.features;
  var layers = companyDetails?.package?.features?.multiple_layer;
  const navigate = useNavigate()

  useEffect(() => {
    if (subscriptionInfo?.isFinished)
      navigate("/profile")
  }, [])

  return (
    <Layout>

      <div className="row">
        {
          _apps?.map(app => {
            return (features?.[app.featureKey] || layers?.includes(app.featureKey)) ?
              <Link to={app.url} className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between flex-shrink-0 mb-0 ">
                      <div className='d-flex align-items-center '>
                        <span className="avatar-initial rounded bg-label-primary p-2 me-2">
                          {app.icon}
                        </span>
                        <div className='d-flex flex-column'>

                          <h5 className="fw-semibold my-0  ">{app.title}</h5>
                          <small className=' text-capitalize'>  </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              :
              <></>
          })}
      </div>

    </Layout>
  )
}

export default Apps