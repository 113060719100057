import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useScrollLoadingContext } from '../../Contexts/ScrollLoadingContext';
import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { useAppsContext } from '../../Contexts/AppsContext';
import { Image } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { setDisableBtn } from '../../../redux/Slices/Global/globalSlice';

function FeedsList() {
    const { data, pageNum, loading, lastPage, setLastElement, setData, setPageNum, setRerender } = useScrollLoadingContext();
    const { setSelectedFeed, setIsCreation, selectedRss } = useAppsContext();
    const dispatch = useDispatch()

    const { showToast } = useCustomToast();
    const [isBar, setIsBar] = useState(false);

    useEffect(() => {
        setIsBar([""].includes(selectedRss?.rss_settings?.position))
        return () => {
            setData([])
            setSelectedFeed({})
        }
    }, [])

    useEffect(() => {
        ["BOTTOM", "TOP"].includes(selectedRss?.rss_settings?.position) ? setIsBar(true) : setIsBar(false)

    }, [selectedRss])

    const editFeed = (rowData) => {
        setIsCreation(false)
        setSelectedFeed(rowData)
    }

    const deleteFeed = (feedId) => {
        dispatch(setDisableBtn(true));
        axiosInstance.delete(`/rss/feed/${feedId}/delete`)
            .then(result => {
                dispatch(setDisableBtn(false));
                setPageNum(1)
                setRerender(true)
                showToast("success", "Delete Feed", result?.data?.data?.msg)
            }).catch(error => {
                showToast("error", "Delete Feed", error?.response?.data?.msg)
            })
    }

    const CardContent = ({ item }) => {

        return <>
            <div className={`${isBar ? "d-none" : "col-3 p-2"}`} >
                <Image src={item?.image ?? "https://popup-push-settings.s3.eu-central-1.amazonaws.com/no-image.png"} className="w-100 border" />
            </div>
            <div className={`${isBar ? "col-10 ps-4" : "col-7 "} text-dark `} >
                <div>{item.title}</div>
                <div className={` fs-8 fw-light  ${isBar ? "text-break" : "text-truncate"}`} >{item.description ?? "---"} </div>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-end text-primary">
                {/* Update RSS */}
                <button className={`fs-8 mt-1 mx-1 update-button-${item.id}`} onClick={() => editFeed(item)} data-pr-position="bottom"
                    data-pr-tooltip="Delete">
                    <i className="pi pi-pencil"></i>
                </button>

                {/* Delete RSS */}
                <button className={`fs-8 mx-1 delete-button-${item.id}`} onClick={() => deleteFeed(item.id)} data-pr-position="bottom"
                    data-pr-tooltip="Delete" >
                    <i className="bx bx-trash"></i>
                </button>
            </div>
        </>
    }

    return <>
        {
            data?.length == 0 && loading ?
                <ProgressSpinner style={{ width: "60px", height: "60px" }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                :
                data?.length > 0 ?
                    <div className={`col-12 d-flex flex-column scroll_container scroll_div px-2 w-100 h-100 `}  >
                        <div className="popular-movies-api d-flex flex-wrap ">
                            {
                                data?.map((item, index) => {
                                    return index === data?.length - 1 && pageNum <= lastPage ?
                                        (
                                            <div className="col-12 d-flex align-items-center  p-2 mb-1 rounded bg-label-secondary border"
                                                ref={setLastElement}
                                                key={`${item.type}-${index}`}>
                                                <CardContent item={item} />
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="col-12 d-flex align-items-center  p-2 mb-1 rounded bg-label-secondary border"
                                                key={`${item.type}-${index}`}>
                                                <CardContent item={item} />
                                            </div>
                                        )
                                })
                            }
                        </div>

                        {
                            loading ?
                                <ProgressSpinner style={{ width: '60px', height: '60px', opacity: .1 }} strokeWidth="3" fill="var(--surface-ground)" animationDuration="1s" />
                                : <></>
                        }

                    </div>
                    :
                    <>
                        {/* <div className="d-flex h-100 justify-content-center align-items-center"> */}
                        <h5 className=' bg-label-secondary p-3 my-5  rounded text-center'>
                            There are no content added yet!
                        </h5>
                        {/* </div> */}
                    </>
        }   </>
}

export default FeedsList