import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useAppsContext } from '../../../Contexts/AppsContext';
import { useCustomToast } from '../../../Contexts/ToastContext';
import { prayersNames } from "../../../../config/global"
import axiosInstance from '../../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import {setDisableBtn,setIsPrayerCampaign } from '../../../../redux/Slices/Global/globalSlice';

const CreatePrayerCampaign = ({ campaignNames }) => {

    const { createDialog, setCreateDialog, setIsLoading } = useAppsContext();
    const { disableBtn, layer } = useSelector((state) => state.global);
    const dispatch = useDispatch()

    const { showToast } = useCustomToast();

    const [selectedPrayer, setSelectedPrayer] = useState("")
    const [filteredOptions, setFilteredOptions] = useState([])

    const navigate = useNavigate();

    const options = [
        { name: prayersNames[0], code: 0 },
        { name: prayersNames[1], code: 1 },
        { name: prayersNames[2], code: 2 },
        { name: prayersNames[3], code: 3 },
        { name: prayersNames[4], code: 4 },
    ]

    useEffect(() => {
        let filteredArray = options.filter(obj => !campaignNames.includes(obj.name));
        setFilteredOptions(filteredArray)
    }, [])


    const create = () => {
        setDisableBtn(true)

        axiosInstance.post(`/campaign/prayer/campaign/create`, { "prayer_index": selectedPrayer }
        ).then((result) => {
            dispatch(setDisableBtn(false))
            setCreateDialog(false)
            dispatch(setIsPrayerCampaign(true))
            showToast('success', 'Campaign Creation', "Campaign Created successfully!");
            sessionStorage.setItem('is_default_prayer', JSON.stringify(false));
            navigate(`/campaigns/${ result.data?.data?.id }/setup`)
        }).catch((error) => {
            dispatch(setDisableBtn(false));
            if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Campaign Creation', error[0]);
                });
        });

    }
    return (
        <Dialog visible={ createDialog }
            style={ { width: '500px' } }
            breakpoints={ { '960px': '95vw' } }
            header="Create Prayer Campaign"
            modal className="p-fluid"
            onHide={ () => setCreateDialog(false)
            }>

            <div className='my-5 px-4'>
                <label htmlFor="" className='mb-2'>Prayer Name</label>
                <Dropdown className='w-100'
                    options={ filteredOptions }
                    onChange={ (e) => { setSelectedPrayer(e.value) } }
                    value={ selectedPrayer }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Select campaign" />
            </div>

            <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <button type="reset" className="btn btn-label-secondary me-3" disabled={ disableBtn ? true : false } data-bs-dismiss="modal" aria-label="Close" onClick={ () => setCreateDialog(false) }>Cancel</button>
                <button type="submit" className="btn btn-primary me-3 " disabled={ disableBtn ? true : false } onClick={ create }>
                    { disableBtn ?
                        <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        :
                        "Create"
                    }
                </button>
            </div>
        </Dialog>
    )
}

export default CreatePrayerCampaign