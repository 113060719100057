import React, { useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { SplitButton } from 'primereact/splitbutton';

import { getFormErrorMessage } from '../../../../config/global';
import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { useDispatch } from 'react-redux';
import { setRerender, setLoading} from '../../../../redux/Slices/deviceSlice';

function GroupHeader ({ deviceCount, groupName, columnId, groupID, playPause, assignSchedule }) {
    const dispatch = useDispatch();
    
    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { showToast } = useCustomToast();

    let defaultName = {
        "name": groupName
    }
    const [editName, setEditName] = useState(false);
    const [name, setName] = useState(defaultName.name);
    // const [isEmpty, setIsEmpty] = useState(deviceCount == 0 ? true : false)
    const [isEmpty, setIsEmpty] = useState(true);  

    useEffect(() => {
        setIsEmpty(deviceCount === 0);  
    }, [deviceCount]);

    useEffect(() => {
        reset({ "name": name })
    }, [editName])

    const onSubmit = (data) => {
        setEditName(false)
        setName(data.name)

        if (groupName != data.name) {
            axiosInstance.put(`/groups/${ groupID }/update`, data
            ).then((result) => {
                showToast('success', 'Update Group', result?.data?.data?.msg);
            }).catch((error) => {
                showToast('error', 'Update Group', error?.response?.data?.message);
            });
        }
    }

    const deleteGroup = () => {
        dispatch(setLoading(true));
        axiosInstance.delete(
            `/groups/${ groupID }/delete`
        ).then((result) => {
            dispatch(setRerender(true));
            showToast('success', ' Group Deleted Succefully');
        }).catch((error) => {
            showToast('error', 'Delete Group', error?.response?.data?.message);
        });
    }

    const flushHandler = (groupID) => {
        axiosInstance.get(`/groups/${ groupID }/screen`)
            .then((res) => {
                showToast('success', 'Group', res?.data?.data?.msg);
            }).catch(error => {
                showToast('error', 'Group', error?.response?.data?.message);
            });
    }

    const restartHandler = (groupID) => {
        axiosInstance.get(`/groups/${ groupID }/restart`)
            .then((res) => {
                showToast('success', 'Group', res?.data?.data?.msg);
    
            })
    }

    const muteHandler = (groupID, action) => {
        axiosInstance.post(`/groups/${ groupID }/mute`,{"action": action,})
            .then((res) => {
                showToast('success', 'Group', res?.data?.data?.msg);
    
            })
    }

    const items = [

        {
            label: <sapn className="fs-6 "> Assign schedule </sapn>,
            icon: 'bx bx-time-five me-2 text-light',
            command: () => {
                assignSchedule(groupID)
            },
        },
        {
            label:  <sapn className="fs-6 ">Edit Group Name</sapn> ,
            icon:"fa fa-edit me-2 text-light",
            command: () => {
                { setEditName(true) } 
            }
        },
        {
            label:  <sapn className="fs-6 ">Delete</sapn> ,
            icon:"bx bx-trash me-2 text-light",
            command: () => {
                deleteGroup(groupID)
            }
        },
        {
            label:  <sapn className="fs-6 ">Mute</sapn> ,
            icon:"fa fa-volume-off me-2 text-light",
            command: () => {
                muteHandler(groupID, "mute")
            }
        },
        {
            label:  <sapn className="fs-6 ">UnMute</sapn> ,
            icon:"fa fa-volume-high me-2 text-light",
            command: () => {
                muteHandler(groupID,"unmute")
            }
        },
        {
            label: <sapn className="fs-6 ">Flush</sapn> ,
            icon: 'bx bx-help-circle me-2 text-light',
            command: () => {
                flushHandler(groupID)
            }
        },
        {
            label: <sapn className="fs-6 ">Restart</sapn> ,
            icon: 'fas fa-power-off me-2 text-light',
            command: () => {
                restartHandler(groupID)
            }
        }]

    return (
        <>
            <div className='bg-white px-3 pt-2 group_title w_20'>
                <div className='d-flex justify-content-between align-items-center'>
                    {
                        editName ?
                            <form onSubmit={ handleSubmit(onSubmit) } className="d-flex align-items-center">
                                <div className={ `field  ${ errors.name ? "mx-2 mt-2" : "m-2" }` }>
                                    <span className="p-float-label">
                                        <Controller name="name" control={ control }
                                            rules={ { required: 'Name is required!' } }
                                            render={ ({ field, fieldState }) => {
                                                return (
                                                    <InputText
                                                        id={ field.name } { ...field } autoFocus
                                                        className={ `w-100 p-1 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                )
                                            } } />
                                    </span>
                                </div>

                                {/* Save changes */ }
                                <Tooltip target={ `.save-button-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon save-button-${ columnId }` } data-pr-position="bottom" type='submit' data-pr-tooltip="Update" >
                                    <i className="far fa-check-circle  text-light fs-6" ></i>
                                </button>

                            </form>
                            :
                            <h2 className=" mb-0 me-3 fs-5 fw-bold">{ name }</h2>
                    }

                    <div className='d-flex align-items-center'>
                        {
                            !editName &&
                            <>
                                {/* Play */ }
                                <Tooltip target={ `.play-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon play-${ columnId }` } data-pr-position="bottom" data-pr-tooltip="play" onClick={ () => playPause(groupID, "play") }>
                                    <i className="bx bx-play-circle text-light"></i>
                                </button>
                                {/* Pause */ }

                                <Tooltip target={ `.pause-${ columnId }` } showDelay={ 100 } className="fs-8" />
                                <button className={ `btn btn-sm btn-icon pause-${ columnId }` } data-pr-position="bottom" data-pr-tooltip="pause" onClick={ () => playPause(groupID, "pause") }>
                                    <i className="bx bx-pause-circle text-light"></i>
                                </button>
                                
                            </>

                        }
                        

                    </div>

                    <SplitButton
                        label={ null }
                        model={ items }
                        className="p-button-text p-button-primary p-button-sm  mt-1 p-button-secondary device_actions ms-3"
                        buttonClassName="d-none"
                        dropdownIcon="fas fa-ellipsis-v"

                    />
                </div>
                <div className='mx-2'>{ getFormErrorMessage('name', errors) }</div>

            </div>
        </>
    )
}

export default GroupHeader