import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import moment from 'moment'

import { getFormErrorMessage } from '../../../../config/global';
import { useCustomToast } from '../../../Contexts/ToastContext';
import ConflictWarningContent from "./ConflictWarningContent";
import axiosInstance from '../../../../config/Axios';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCampaign, setShowAssignForm, setRerender } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog, setDisableBtn } from '../../../../redux/Slices/Global/globalSlice';
import { useDeviceContext } from '../../../Contexts/DeviceContext';


function AssignScheduleCampaign({ options, groupOptions, selectedGroup, }) {
    const dispatch = useDispatch();

    const { register, control, formState: { errors }, handleSubmit, reset } = useForm({ "type": "event" })
    const { companyCampaigns, selectedCampaign } = useSelector((state) => state.device);
    const { companyDetails, disableBtn } = useSelector((state) => state.global);

    const selectedDevice = useSelector((state) => state.device.selectedDevice);
    const deviceID = selectedDevice?.id;

    const { showToast } = useCustomToast();
    const { setRerender, setLoading, } = useDeviceContext();

    const [scheduleCampaign, setScheduleCampaign] = useState("once");
    const [scheduleOptions, setScheduleOptions] = useState([
        { name: 'Once', code: "once" },
        { name: 'Repeated', code: 'schedule' },
        { name: 'Publish Now', code: 'publish_now' },

    ]);
    const [selectedGroupID, setSelectedGroupID] = useState();
    const [selectedDays, setSelectedDays] = useState([]);
    const [replacedData, setReplacedData] = useState();

    let minDate = new Date();

    const days = [
        { name: 'Sun', key: 'Sunday' },
        { name: 'Sat', key: 'Saturday' },
        { name: 'Mon', key: 'Monday' },
        { name: 'Tue', key: 'Tuesday' },
        { name: 'Wed', key: 'Wednesday' },
        { name: 'Thu', key: 'Thursday' },
        { name: 'Fri', key: 'Friday' },
    ];

    // Set form group dropdown input value  
    useEffect(() => {
        reset({
            "group": selectedGroup ?? "default",
            "type": companyDetails?.package?.features?.advance_content_schedule ? "" : "event"
        })
    }, [reset])

    const onDayChange = (e) => {
        let _selectedDays = [...selectedDays];
        let exists = selectedDays.includes(e.target.value);

        if (e.target.checked) {
            if (!exists) {
                _selectedDays.push(e.target.value);
            }
        } else {
            let index = selectedDays.indexOf(e.target.value);
            if (_selectedDays.length != 1)
                _selectedDays.splice(index, 1);
        }

        setSelectedDays(_selectedDays);
    }
    const handleAddCampaign = () => {
        if (deviceID != "")
            dispatch(setShowAssignForm(true))
    }

    const assign = (data) => {
        dispatch(setDisableBtn(true))
        let times = {};
        let formData = {
            "campaign_id": selectedCampaign,
            "is_repeated": scheduleCampaign === "schedule" ? 1 : 0,
            "device_id": deviceID,
            "group_id": selectedGroupID ?? selectedGroup,
            "type": "SCHEDULE",
            "is_publish_now": scheduleCampaign === "publish_now" ? 1 : 0,
        }

        if (scheduleCampaign === "schedule") {
            formData.days = selectedDays
            times = getStartEndTine(data.start_time)

        } else {
            var date = moment(data.date).format('YYYY-MM-DD');
            var start_time = moment(data.start_time).format('HH:mm:ss');
            times = getStartEndTine(date + " " + start_time)
            formData.date = times.date
        }

        formData.start_time = times.start_time;
        formData.end_time = times.end_time;

        setReplacedData(formData);
        if (formData.group_id) {
            if (scheduleCampaign == "event") {
                assignEventCampaign(formData)
            } else {
                assignToGroup(formData)
            }
        } else {
            if (scheduleCampaign == "event") {
                assignCampaignToSingleDevice(formData)
            } else {
                assignToDevice(formData)
            }

        }
    }

    const getStartEndTine = (startDateTime) => {
        // datetime input in local time
        var date = new Date(startDateTime);

        date.setSeconds(0);
        date.setMilliseconds(0);
        // Get start time in UTC  
        var startTime = date.toUTCString().slice(17, 25)
        var utcDate = formatDate(date);

        // Add campaign duration to starttime to get end time 
        date.setSeconds(date.getSeconds() + companyCampaigns[selectedCampaign]);

        // Get end time in UTC  
        var endTime = date.toUTCString().slice(17, 25);

        return { "start_time": startTime, "end_time": endTime, "date": utcDate }

    }

    function formatDate(date) {
        const utcYear = date.getUTCFullYear();
        const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(date.getUTCDate()).padStart(2, '0');

        return `${utcYear}-${utcMonth}-${utcDay}`;
    }

    const assignCampaignToSingleDevice = (formData) => {
        axiosInstance.post(
            `/campaign/assign/devices`,
            {
                campaign_id: formData.campaign_id,
                devices_ids: [deviceID],
                type: "devices"
            }
        ).then((result) => {
            dispatch(setDisableBtn(false))
            let rejectedSatellite = result?.data?.data?.rejected?.satellite;
            let rejectedHDMI = result?.data?.data?.rejected?.hdmi;
            var errorsArr = [];

            if (rejectedHDMI.length == 0 && rejectedSatellite.length == 0) {
                showToast('success', 'Assign Campaign', result?.data?.data?.msg);
                dispatch(setRerender(true))
                if (deviceID != "")
                    dispatch(setShowAssignForm(false))
            }

            if (rejectedHDMI.length != 0) {
                errorsArr.push(generateMessage(rejectedSatellite, "HDMI"));
            }

            if (rejectedSatellite.length != 0) {
                errorsArr.push(generateMessage(rejectedSatellite, "satellite"));
            }

            errorsArr?.map((error) => { showToast(error.severity, error.summary, error.detail) })

        }).catch((errors) => {
            Object.values(errors?.response?.data?.errors).forEach(error => {
                showToast('error', 'Assign Campaign', error[0]);
            });
        });
        dispatch(setDisableBtn(false));
    }

    // Generate error message
    const generateMessage = (arr, type) => {
        let msg = `${arr.map((name) => '"' + name + '" ')} ${arr.length > 1 ? "don't" : "doesn't"}  support ${type}!`;
        return { severity: 'error', summary: 'Assign Campaign', detail: msg, life: 3000 }
    }

    const assignToDevice = (formData) => {
        axiosInstance.post(`/campaign/device/assign`, formData,)
            .then((result) => {
                dispatch(setDisableBtn(false))
                if (result?.data?.data?.status == 400) {
                    showToast('error', 'Assign Campaign', result?.data?.data?.msg);
                } else {
                    showToast('success', 'Assign Campaign', result?.data?.data?.msg);
                    dispatch(toggleDialog("assignScheduleCampaigns"))
                    dispatch(setShowAssignForm(false))
                    dispatch(setRerender(true))
                    if (deviceID != "")
                        dispatch(setShowAssignForm(false))
                }

            }).catch((errors) => {
                if (errors?.response?.data) {
                    dispatch(setDisableBtn(false));
                    showToast('error', 'Assign Campaign', errors?.response?.data?.msg);
                }
            });
    }

    const assignToGroup = (formData) => {
        axiosInstance
            .post(`/groups/assign/campaign/schedule`, formData)
            .then((result) => {
                dispatch(setDisableBtn(false));

                const responseData = result?.data?.data;

                if (responseData?.error === "conflict") {
                    let content = (
                        <ConflictWarningContent
                            responseData={responseData}
                            formData={formData}
                            assignToRestGroup={assignToRestGroup}
                            replaceOldScheduleCampaign={replaceOldScheduleCampaign}
                        />
                    )

                    showToast("warn", "Assign Campaign", responseData?.msg, content);
                }
                // Handle "HDMI" error
                else if (responseData?.error === "HDMI") {
                    showToast("error", "Assign Campaign", responseData?.msg);
                }
                // Success case
                else {
                    dispatch(toggleDialog("assignDialog"));
                    showToast("success", "Assign Campaign", responseData?.msg);
                }

                dispatch(setRerender(true));
            })
            .catch((errors) => {
                if (errors?.response?.data) {
                    showToast("error", "Assign Campaign", errors?.response?.data?.message);
                    dispatch(setDisableBtn(false));
                }
            });
    }

    const replaceOldScheduleCampaign = (formData) => {
        axiosInstance.post(`/groups/schedule/campaign/replace`, formData,)
            .then((result) => {
                dispatch(setDisableBtn(false))
                showToast('success', 'Assign Campaign', result?.data?.data?.msg);
                dispatch(toggleDialog("assignDialog"))
                dispatch(setRerender(true))
            }).catch((errors) => {
                showToast('error', 'Assign Campaign', errors?.response?.data?.message);
                dispatch(setDisableBtn(false));
            });
    }

    const assignToRestGroup = (formData) => {
        axiosInstance.post(`/groups/schedule/campaign/rest`, formData,)
            .then((result) => {
                dispatch(setDisableBtn(false))
                showToast('success', 'Assign Campaign', result?.data?.data?.msg);
                dispatch(toggleDialog("assignDialog"))
                dispatch(setRerender(true))
            }).catch((errors) => {
                Object.values(errors?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Assign Campaign', error[0]);
                });
                dispatch(setDisableBtn(false));
            });
    }

    const assignEventCampaign = (formData) => {
        formData.type = "EVENT";

        axiosInstance.post(`/groups/campaign/assign`, formData,)
            .then((result) => {
                dispatch(setDisableBtn(false))
                showToast('success', 'Assign Campaign', result?.data?.data?.msg);
                dispatch(toggleDialog("assignDialog"))
                dispatch(setRerender(true))

            }).catch((errors) => {
                dispatch(setDisableBtn(false));
                showToast('error', 'Assign Campaign', errors?.response?.data?.message);
            });
    }

    useEffect(() => {
        if (scheduleCampaign == "once") {
            setSelectedDays([])
        } else if (scheduleCampaign == "schedule") {
            setSelectedDays(["Sunday"])
        } else {
            setSelectedDays([])
        }
    }, [scheduleCampaign])

    return (
        <>
            <form onSubmit={handleSubmit(assign)} className=" d-flex flex-column justify-content-center">
                {
                    // Group field
                    deviceID == "" && (
                        <div className='mt-3 px-4'>
                            <label htmlFor="" className='mb-2 form-label'>Groups</label>
                            <Controller name="group" control={control}
                                rules={{ required: "group is required!" }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                                        id={field.name} {...field}
                                        value={field.value}
                                        options={groupOptions}
                                        onChange={(e) => { field.onChange(e.value); setSelectedGroupID(e.value) }}
                                        optionLabel="name"
                                        optionValue="code"
                                        inputRef={field.ref}
                                        placeholder="Select group"
                                    />
                                )} />
                            {getFormErrorMessage('group', errors)}
                        </div>
                    )}

                {/* Campaign field */}
                <div className='px-4 my-3'>
                    <label htmlFor="" className='mb-2 form-label'>Campaigns</label>
                    <Controller name="campaign" control={control}
                        rules={{ required: "campaign is required!" }}
                        render={({ field, fieldState }) => (
                            <Dropdown
                                id={field.name} {...field}
                                value={field.value}
                                options={options}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    dispatch(setSelectedCampaign(e.value));
                                }}
                                optionLabel="name"
                                optionValue="code"
                                inputRef={field.ref}
                                placeholder="Select campaign"
                                className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                            />
                        )
                        } />
                    {getFormErrorMessage('campaign', errors)}
                </div>

                {/* Type */}
                <div className='mb-3 px-4'>
                    <label htmlFor="" className='mb-2 form-label'>Schedule</label>
                    <Controller name="type" control={control}
                        rules={{ required: "type is required!" }}
                        render={({ field, fieldState }) => (
                            <Dropdown
                                id={field.name} {...field}
                                value={field.value}
                                options={scheduleOptions}
                                onChange={(e) => { field.onChange(e.value); setScheduleCampaign(e.value) }}
                                optionLabel="name"
                                optionValue="code"
                                inputRef={field.ref}
                                placeholder="Select type"
                                className={`w-100 ${classNames({ 'p-invalid': fieldState.invalid })}`}
                            />
                        )
                        } />
                    {getFormErrorMessage('type', errors)}
                </div>

                {
                    scheduleCampaign != "event" &&
                    <>
                        {/* Days input */}
                        {
                            scheduleCampaign === "schedule" &&
                            <div className="col-12  mb-3 px-4">
                                <div className="field days_list">
                                    <label htmlFor="" className='mb-2 form-label'>Days</label>
                                    <ul>
                                        {
                                            days.map((day) => {
                                                return (
                                                    <li key={`${day.key}`}>

                                                        <input {...register(`${day.name}`, {
                                                            required: {
                                                                value: days.length == 0 ? true : false
                                                            }
                                                        })} onChange={onDayChange} type="checkbox" id={`${day.name}`}
                                                            name="days" defaultValue={`${day.key}`}
                                                            checked={selectedDays.includes(day.key) ? true : false} />
                                                        <label htmlFor={`${day.name}`} >{day.name}</label>

                                                        {errors.Sun && <p className='fv-plugins-message-container invalid-feedback'> Days field is required! </p>}

                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }

                        {/* Date input */}
                        {
                            scheduleCampaign === "once" &&
                            <div className="col-12  mb-3 px-4">
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Date </label>
                                    <span className="p-float-label">
                                        <Controller name="date" control={control}
                                            rules={{ required: "Date is required!" }}
                                            render={({ field, fieldState }) => (
                                                <Calendar
                                                    id={field.name} {...field}
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    minDate={minDate}
                                                    dateFormat="dd/mm/yy"
                                                    mask="99/99/9999"
                                                    inputRef={field.ref}
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                />
                                            )
                                            } />
                                    </span>
                                    {getFormErrorMessage('date', errors)}
                                </div>
                            </div>
                        }
                        {
                            scheduleCampaign === "publish_now" &&
                            <div className="col-12 mb-3 px-4">
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Date </label>
                                    <input
                                        name="date"
                                        type="text"
                                        value={new Date().toLocaleDateString('en-GB')}
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                            </div>
                        }

                        {/* Time input */}
                        {scheduleCampaign != "publish_now" ?

                            <div className={`col-12 mb-3 px-4`}>
                                {/* start time */}
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Start time </label>
                                    <span className="p-float-label">
                                        <Controller name="start_time" control={control}
                                            rules={{ required: 'Start time is required.' }}
                                            render={({ field, fieldState }) => {
                                                return <Calendar
                                                    id={field.name} {...field}
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    timeOnly
                                                    inputRef={field.ref}
                                                    dateFormat="HH:MM"
                                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                                />
                                            }
                                            } />

                                    </span>
                                    {getFormErrorMessage('start_time', errors)}
                                </div>
                            </div>
                            :
                            <div className={`col-12 mb-3 px-4`}>
                                {/* start time */}
                                <div className="field">
                                    <label className="form-label" htmlFor="basic-default-email"> Start time </label>
                                    <input
                                        name="start_time"
                                        type="text"
                                        value={new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                            </div>
                        }
                    </>}

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    {
                        deviceID != "" ?
                            <button type="reset"
                                className="btn btn-label-secondary me-3"
                                disabled={disableBtn ? true : false}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => dispatch(setShowAssignForm(false))}>Back</button>
                            :
                            <button type="reset" className="btn btn-label-secondary me-3"
                                disabled={disableBtn ? true : false}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => dispatch(toggleDialog("assignDialog"))}>Cancel</button>
                    }

                    <button type="submit" className="btn btn-primary  me-1" disabled={disableBtn ? true : false} >
                        {
                            disableBtn ?
                                <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Save"
                        }
                    </button>
                </div>
            </form >
        </>
    )
}

export default AssignScheduleCampaign