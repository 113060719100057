import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';

import { Card } from 'primereact/card';
import AssignScheduleCampaign from '../CampaignAssignment/AssignScheduleCampaign';

import axiosInstance from '../../../../config/Axios';
import { useCustomToast } from '../../../Contexts/ToastContext';

import { useSelector, useDispatch } from 'react-redux';
import {setShowAssignForm ,setCompanyCampaigns } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog } from '../../../../redux/Slices/Global/globalSlice';

const CampaignComponents = () => {
    const dispatch = useDispatch();

    const { openDialog, disableBtn } = useSelector((state) => state.global);
    const { showAssignForm  ,device, selectedDevice} = useSelector((state) => state.device);    
    const deviceID= selectedDevice.id;
    const { showToast } = useCustomToast();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(true);
    const [options, setoptions] = useState([]);

    useEffect(() => {
        if (rerender) {
            setLoading(true)
            dispatch(setShowAssignForm(false))
            axiosInstance.get(`/campaign/device/${ deviceID }/show`)
                .then((res) => {
                    setData(res?.data?.data)
                    setLoading(false)
                    setRerender(false)
                }).catch((error) => {

                });
        }
    }, [rerender])

    const deleteScheduledCampaign = (id) => {
        axiosInstance.delete(`/campaign/device/${ id }/delete`)
            .then((res) => {
                setRerender(true)
                showToast('success', 'Delete Scheduled Campaign', res?.data?.data?.msg);
            }).catch((error) => {

            });
    }

    useEffect(() => {
        axiosInstance.get(`/company/campaigns`)
            .then((result) => {
                const campaignsData = result.data.data.reduce((acc, campaign) => {
                    acc[campaign.id] = campaign.campaign_duration;
                    return acc;
                }, {});
                
                const newOptions = result.data.data.map(campaign => ({
                    name: campaign.name,
                    code: campaign.id,
                    duration: campaign.campaign_duration,
                }));
                setoptions(newOptions);
                dispatch(setCompanyCampaigns(campaignsData));
            })
            .catch(error => {
                console.error("Error fetching campaigns:", error);
            });
    }, []);

    return (
        <div className="card">
            <h5 className='text-center my-3'>Scheduled Campaigns Assigned</h5>

        {
            loading ?
                <div className='d-flex m-5 justify-content-center align-items-center '>
                    <Spinner variant="primary" as="span" animation="border" size="lg" role="status" className='mx-3' aria-hidden="true" />
                </div>
                :
                data.length != 0 && !showAssignForm &&
                <>
                    <div className="d-flex flex-col my-4 scroll_div schedule_campaign_container">
                        {
                            data.map((campaign, index) => {
                                return <>
                                    <Card className='mb-3 mx-3 schedule_card position-relative' disabled key={ index }>
                                        <div className="w-100 d-flex align-items-center">
                                            <div className="col-1 p-3 d-flex justify-content-center"><i className="bx bx-time-five text-secondary fs-1 "></i></div>
                                            <div className="col-9 fs-5 d-flex flex-col ps-3">
                                                <span className='mb-2'>{ campaign.name }</span>
                                                <div className='d-flex'>
                                                    <span className="fs-10 text-capitalize text-center badge bg-label-secondary  d-flex flex-column me-2">
                                                        { campaign.is_repeated ? "Repeated" : "once" }
                                                    </span>
                                                    <span className="text-center badge bg-label-secondary fs-10 d-flex  me-2">
                                                        {
                                                            !campaign.is_repeated ? campaign.date
                                                                :
                                                                <>
                                                                    {
                                                                        campaign.days.length == 7 ?
                                                                            <div className='text-capitalize'>Daily</div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    campaign.days.map((day, index) => {
                                                                                        return <div className="me-2" key={ index }>
                                                                                            {
                                                                                                day.substring(0, 3).toUpperCase()
                                                                                            }
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                        }
                                                    </span>
                                                    <span className="text-center badge bg-label-secondary fs-10 d-flex flex-column">
                                                        { campaign.start_time + " - " + campaign.end_time }
                                                    </span>

                                                </div>
                                            </div>
                                            {

                                                !campaign.is_finished &&
                                                <div className="col-2 text-end text-light cursor-pointer">
                                                   
                                                    <i className="bx bx-trash cursor-pointer" onClick={ () => deleteScheduledCampaign(campaign.id) } ></i>
                                                </div>
                                            }
                                        </div>
                                        {
                                            campaign.is_finished &&
                                            <div className='card_overlay p-4 d-flex align-items-center position-absolute bg-gray-100/75 w-100 h-100 top-0 start-0'>
                                                <div className="col-10 text-end ">
                                                    <span className="text-center badge bg-label-danger ">finished</span>
                                                </div>
                                                <div className="col-2 text-end text-light cursor-pointer">
                                                    <i className="bx bx-trash" onClick={ () => deleteScheduledCampaign(campaign.id) } ></i>
                                                </div>
                                            </div>
                                        }

                                    </Card>

                                </>
                            })
                        }
                    </div>

                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">

                        <button type="submit" className="btn btn-primary  me-1" disabled={ disableBtn ? true : false } >
                            {
                                disableBtn ?
                                    <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                    :
                                    <span className='d-flex align-items-center' onClick={ () => dispatch(setShowAssignForm(true)) } > Assign Campaign
                                    </span>
                            }
                        </button>
                    </div>
                </>
        }

        {/* Empty Status Scenario */ }
        {
            (!showAssignForm && data.length == 0 && !loading) &&
            <>
                <div className="col-12 text-center d-flex  align-items-end py-5 justify-content-center">
                    <button type="submit" className="btn btn-primary  me-1" disabled={ disableBtn ? true : false } >
                        {
                            disableBtn ?
                                <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                <span className='d-flex align-items-center' onClick={ () => dispatch(setShowAssignForm(true)) } >
                                    <i className="fas fa-plus me-2" ></i>   Assign Campaign
                                </span>
                        }
                    </button>
                </div>
            </>
        }

        {/* Add New Scheduled Campaign */ }
        {
            showAssignForm &&
            <AssignScheduleCampaign
                setRerender={ setRerender }
                deviceID={ deviceID }
                options={ options }
                 />
        }

    </div>
    
    )

};

export default CampaignComponents;