import React, { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";

import FirebaseDashboardListener from "../Services/FirebaseDashboardListener";
import { formatSize, useTitle } from "../../config/global";
import axiosInstance from "../../config/Axios";
import Layout from "../Layout/Layout";

import { useSelector } from 'react-redux';
import { } from '../../redux/Slices/Global/globalSlice';

const Dashboard = () => {
  useTitle("Dashboard Page");
  const { companyDetails, subLoaders, isAuthenticated } = useSelector((state) => state.global);

  const [data, setData] = useState({});

  const getData = () => {
    axiosInstance.get(`/dashboard`)
      .then((response) => {
        setData(response.data.data);
      })
  }

  useEffect(() => {
    if (isAuthenticated)
      getData()
  }, [])

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="px-0 flex-grow-1 ">
          <div className="row flex-wrap">
            <div className="col-9 mb-1 order-0 mb-4">
              <div className="card">
                <div className="d-flex align-items-end row">
                  <div className="col-sm-6 mb-4">
                    <div className="card-body mt-2">
                      <h5 className="card-title text-primary text-capitalize">
                        Welcome {localStorage.getItem("name")}
                      </h5>
                      <p className="mb-4">
                        Start managing your screens and content from this dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 text-center text-sm-left">
                    <div className="card-body pb-0 px-0 px-md-1 ms-5">
                      <img
                        src={require("../../assets/img/illustrations/man-with-laptop-light.png")}
                        className="dashboard_img"
                        height={140}
                        alt="View Badge User"
                        data-app-light-img="illustrations/man-with-laptop-light.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                    <span className="avatar-initial rounded bg-label-warning">
                      <i className="fa-solid fa-database"></i>
                    </span>
                  </div>
                  <h5 className="fw-semibold mb-3 mt-4">storage</h5>
                  <h4 className="card-title d-flex justify-content-end align-items-end mb-3">
                    {
                      subLoaders.companyDetails ?
                        <Spinner as="span" variant="primary" size='lg' animation="border" role="status" className='mx-3 my-5 ' aria-hidden="true" />
                        :
                        <>
                          <span className=''>{formatSize(companyDetails?.company?.total_media_usage)}</span>
                          <span className='fs-8 text-light'> &nbsp; / {
                            Math.max(companyDetails?.package?.features["media_storage_capacity"],
                              companyDetails?.company?.allowed_media_usage / Math.pow(1024, 3)
                            )
                          } GB</span>
                        </>
                    }

                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-4 order-1">
              <div className="row">
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Total Devices</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        {data?.totalDevice}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-success">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Online Screen</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        {data?.totalOnlineDevice}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-dark">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Offline Screen</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        {data?.totalOfflineDevice}
                      </h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <FirebaseDashboardListener path={"/Dashboard/" + localStorage.getItem("company_id") + "/action"} getData={getData} />
    </Layout>
  );
};
export default Dashboard;
