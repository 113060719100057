import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";

import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';

import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useDispatch, useSelector } from 'react-redux';
import { setDisableBtn, toggleDialog } from '../../../redux/Slices/Global/globalSlice';

const emptyUser = {
    name: "",
    type: "",
    start_time: null,
    end_time: null,
    date: null,

};

const Create = () => {
    const dispatch = useDispatch();
    const { openDialog, disableBtn } = useSelector((state) => state.global);
    const { control, formState: { errors }, handleSubmit, reset, register } = useForm({ emptyUser });
    const { showToast } = useCustomToast();

    const navigate = useNavigate();

    const hideDialog = () => {
        dispatch(toggleDialog("createPlaylist"));
    }

    const onSubmit = (data) => {
        dispatch(setDisableBtn(true))
        axiosInstance.post("/playlist/create", data)
            .then((result) => {
                dispatch(setDisableBtn(false))
                hideDialog(false);
                showToast('success', 'PlayList Creation', 'PlayList Created Successfully');
                navigate('/playlist/' + result.data?.data?.id + '/show')
            }).catch((error) => {
                dispatch(setDisableBtn(false))
                showToast('error', 'PlayList Creation', error?.response?.data?.message);
            });
    };

    return (
        <Dialog visible={openDialog.createPlaylist} style={{ width: '450px' }}
            header="New PlayList" modal className="p-fluid"
            onHide={hideDialog}
            breakpoints={{ '960px': '95vw' }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="my-5 px-4">
                    <input
                        {...register("name", { required: "Name is required" })}
                        aria-invalid={errors.name ? "true" : "false"}
                        className={errors.name ? 'form-control is-invalid' : 'form-control'}
                        placeholder="Playlist Name"
                    />
                    {errors.name && <p className='fv-plugins-message-container invalid-feedback'>{errors.name?.message}</p>}
                </div>
                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3" data-bs-dismiss="modal" disabled={disableBtn ? true : false} aria-label="Close" onClick={hideDialog}>Cancel</button>
                    <button type="submit" className="btn btn-primary me-3" disabled={disableBtn ? true : false}>
                        {disableBtn ?
                            <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                            :
                            "Create"
                        }
                    </button>
                </div>
            </form>
        </Dialog>
    );
};
export default Create;
