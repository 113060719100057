import React from 'react';
import { useNavigate, Link } from 'react-router-dom'

import axiosInstance from '../../../config/Axios';
import { emptyLocalStorage } from '../../../config/global';

import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from '../../../redux/Slices/Global/globalSlice';

const Logout = ({ classes = "" }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setIsAuthenticated(false))
        axiosInstance.get(`/users/logout`)
            .then(result => {
                emptyLocalStorage()
                navigate("/login");
            })
    }

    return (
        <Link className={`dropdown-item ${classes != "" ? classes : ""}`} to="/login" onClick={handleClick}>
            <i className="bx bx-power-off me-2" />
            <span className="align-middle">Log Out</span>
        </Link>
    );
}

export default Logout;