import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import { useCustomToast } from '../../Contexts/ToastContext';
import axiosInstance from '../../../config/Axios';
import { emptyLocalStorage } from '../../../config/global';

import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from '../../../redux/Slices/Global/globalSlice';

const LogoutProcess = () => {
  const navigate = useNavigate();
  const { showToast } = useCustomToast();
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(setIsAuthenticated(false))
    axiosInstance.get(`/users/logout`)
      .then(response => {
        showToast('success', 'Logout', response?.data?.data?.msg);
        emptyLocalStorage()

        navigate("/login");
      })
  }, [])

  return (<></>)
}

export default LogoutProcess 