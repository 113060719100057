import React, { useEffect } from 'react'

import { onValue, ref } from "firebase/database";
import { db } from "../../config/firebase";

import { useDispatch } from 'react-redux';
import { setRerender} from '../../redux/Slices/deviceSlice';

function FirebaseDeviceListener ({ path, setRealtimeData }) {
    const dispatch = useDispatch();

    useEffect(() => {
        const query = ref(db, process.env.REACT_APP_APP_ENV + "/" + path);
        return onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (snapshot.exists()) {
                setRealtimeData({
                    "id": data.current_campaign_id,
                    "name": data.current_campaign,
                    "is_playing": data.isPlaying,
                    "screen_angle": data.screenAngle,
                    "is_muted": data.isMuted,

                });
                // dispatch(setRerender(true));
            }
        });

    }, []);

    return (
        <></>
    )
}

export default FirebaseDeviceListener


