import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";

import axiosInstance from '../../../../config/Axios';

import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import TemplatesLayouts from './Templates/TemplatesLayouts';
import CustomLayouts from './Custom/CustomLayouts';
import { Paginator } from 'primereact/paginator';

import { useSelector, useDispatch } from 'react-redux';
import { toggleDialog, toggleLoader } from '../../../../redux/Slices/Global/globalSlice';
import { setDefaultTemplate, setUserTemplate, setReloadTrigger } from '../../../../redux/Slices/layoutSlice';



function CreateLayoutPopUp() {

  const dispatch = useDispatch()

  const { openDialog, } = useSelector((state) => state.global);
  const reloadTrigger = useSelector((state) => state.layout.reloadTrigger);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const rowsPerPage = 19;

  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    fetchTemplate();
  }, [currentPage]);

  const items = [
    {
      label: 'Templates Layouts',
      command: () => setActiveIndex(0),
    },
    {
      label: 'Custom Layouts',
      command: () => setActiveIndex(1),
    },
  ];

  useEffect(() => {
    fetchTemplate()

  }, []);

  const fetchTemplate = () => {
    dispatch(toggleLoader("createLoading"))

    axiosInstance.get(`/layout/all?page=${currentPage + 1}`)

      .then((result) => {
        const defaultTemplate = result.data.data.default_layout;
        const userTemplate = result.data.data.custom_layout.data;

        const updatedDefaultTemplates = defaultTemplate.map(item => ({
          id: item.id,
          title: item.title,
          screens_setting: item.screens_setting,
          aspect_ratio_id: item.aspect_ratio_id,
          aspect_ratio: item.aspect_ratio,


        }));

        const updatedUserTemplates = userTemplate.map(item => ({
          id: item.id,
          title: item.title,
          screens_setting: item.screens_setting,
          aspect_ratio_id: item.aspect_ratio_id,
          aspect_ratio: item.aspect_ratio,


        }));

        const data = result.data.data;
        setTotalRecords(data.custom_layout.pagination.total);
        dispatch(setDefaultTemplate(updatedDefaultTemplates));
        dispatch(setUserTemplate(updatedUserTemplates));
        dispatch(toggleLoader("createLoading"));
      })
      .catch((error) => {
        console.error("Error fetching layouts:", error);
        dispatch(toggleLoader("createLoading"));
      });
  }

  useEffect(() => {
    if (reloadTrigger) {
      fetchTemplate()
      dispatch(setReloadTrigger(false))
    }
  }, [reloadTrigger]);

  const onPageChange = (event) => {
    setCurrentPage(event.page);
  };

  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return <TemplatesLayouts />;
      case 1:
        return <CustomLayouts />;
      default:
        return null;
    }
  };

  return (
    <Dialog visible={openDialog.CreateLayoutPopUp}
      breakpoints={{ '960px': '95vw' }}
      header="Choose a Template"
      modal
      style={{ overflow: 'hidden' }}
      blockScroll={true}
      className="p-fluid w-[75%] h-[85%]"
      onHide={() => dispatch(toggleDialog("CreateLayoutPopUp"))}
    >
      <div className="overflow-hidden h-full">
        <TabMenu className='pe-5 pl-5' model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        <div className="flex flex-col mt-3 overflow-y-auto h-[calc(100%-3rem)]" >
          {renderContent()}
        </div>
        {
          activeIndex === 1 ?
            <div className="sticky bottom-0 bg-white p-2">
              <Paginator
                first={currentPage * rowsPerPage}
                rows={rowsPerPage}
                totalRecords={totalRecords}
                onPageChange={onPageChange}
              />
            </div>
            : ""}
      </div>
    </Dialog>
  )
}

export default CreateLayoutPopUp