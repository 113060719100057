import { createSlice } from "@reduxjs/toolkit";
import initialState from "./globalInitialState";
import globalActions from "./globalActions";

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: globalActions,
});

export const {
  setDisableBtn,
  setIsLoading,
  setRerender,
  setPackages,
  setStepsBack,
  setCountries,
  setRerenderList,

  setRerenderSubscription,
  setCompanyDetails,
  setSubscriptionInfo,

  setUserInfo,
  setIsAuthenticated,

  setLayerOptions,
  setSelectedApp,
  setAssign,
  setLayer,
  setType,
  setIsPrayerCampaign,
  setHideTabs,
  setCampaignDetails,
  setIsPublished,
  setSelectedMediaInfo,
  setDeletedItemId,
  setProfileImage,

  setDeleteDialog,
  setOpenDialog,

  toggleDialog,
  toggleLoader,
  toggleDeleteDialog,
} = globalSlice.actions;

export default globalSlice.reducer;
