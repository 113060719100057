import React from 'react'

import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { useCustomToast } from '../../../Contexts/ToastContext';
import axiosInstance from '../../../../config/Axios';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCampaign } from '../../../../redux/Slices/deviceSlice';
import { toggleDialog, setDisableBtn } from '../../../../redux/Slices/Global/globalSlice';

function AssignCampaignsDropDown({ options, }) {
    const { showToast } = useCustomToast();

    const dispatch = useDispatch();
    const { disableBtn, openDialog } = useSelector((state) => state.global);
    const selectedCampaign = useSelector((state) => state.device.selectedCampaign);
    const selectedDevice = useSelector((state) => state.device.selectedDevice);
    const deviceID = selectedDevice?.id;

    const assign = () => {
        dispatch(setDisableBtn(true))
        axiosInstance.post(`/campaign/assign/devices`,
            {
                type: "devices",
                devices_ids: [deviceID],
                campaign_id: selectedCampaign
            }
        ).then((result) => {
            dispatch(setDisableBtn(false))
            if (result?.data?.data?.status == 200) {
                showToast('success', 'Assign Campaign', result?.data?.data?.msg);
                dispatch(toggleDialog("assignCampaignsDropDown"));
            } else {
                showToast('error', 'Assign Campaign', result?.data?.data?.msg);
            }
        }).catch((error) => {
            dispatch(setDisableBtn(false));
            showToast('error', 'Assign Campaign', error?.response?.data?.msg);
        });
    }

    return (
        <>
            <Dialog visible={openDialog.assignCampaignsDropDown}
                style={{ width: '500px' }}
                breakpoints={{ '960px': '95vw' }}
                header="Assign campaign to Device"
                modal className="p-fluid"
                onHide={() => dispatch(toggleDialog("assignCampaignsDropDown"))
                }>

                <div className='my-5 px-4'>
                    <label htmlFor="" className='mb-2'>Campaigns</label>
                    <Dropdown className='w-100' value={selectedCampaign} options={options}
                        onChange={(e) => { dispatch(setSelectedCampaign(e.value)) }}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select campaign" />
                </div>

                <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3"
                        disabled={disableBtn ? true : false}
                        data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => dispatch(toggleDialog("assignCampaignsDropDown"))}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary me-3 " disabled={disableBtn ? true : false} onClick={assign}>
                        {
                            disableBtn ?
                                <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Save"
                        }
                    </button>
                </div>
            </Dialog>
        </>
    )
}

export default AssignCampaignsDropDown